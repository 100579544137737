import { Fragment } from 'react';

import Subscribe from '../global/Subscribe';
import Info from '../global/Info';
import Banner from './Banner';

const Events = () => {
    return (
        <Fragment>
            <Banner />
            <Info />
            <Subscribe />
        </Fragment>
    );
};

export default Events;