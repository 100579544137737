import { useEffect, useState } from "react";
import { Container, makeStyles, Grid } from "@material-ui/core";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

// Redux
import { updateStore } from "redux/actions/Store";

// Values
import { Img_upee_mountains, colors, Ic_next_arrow, Ic_back_arrow } from "values";
import { formatMoney, checkPrice } from "utils";

// styles
const useStyles = makeStyles((theme) => ({
  card: {
    border: `1px solid ${colors.gray}`,
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    position: "relative",
    // height:450,
  },
  image: {
    width: "100%",
    maxHeight: 430,
    height: "100%",
    padding: 30,
    paddingBottom: 0,
    objectFit: "scale-down",
  },
  button: {
    background: "#f2f2f2",
    cursor: "pointer",
    borderRadius: 5,
    border: "none",
    fontSize: 18,
    height: 30,
    width: 30,
    "&:active": {
      transform: "scale(0.96)",
    },
  },
  button_add: {
    background: colors.primary,
    height: 45,
    cursor: "pointer",
    borderRadius: 12,
    border: "none",
    color: "#fff",
    textTransform: "uppercase",
    fontWeight: 600,
    fontSize: 17,
    paddingLeft: 10,
    paddingRight: 10,
    width: 220,
    boxShadow: "0px 5px 10px 0px rgba(0,0,0,0.2)",
    "&:active": {
      transform: "scale(0.98)",
    },
  },
  button_arrow: {
    cursor: "pointer",
    "&:active": {
      transform: "scale(0.97)",
    },
  },
}));

const IndividualProduct = ({ data, rol, distClass, details, store, updateStore }) => {
  const classes = useStyles();
  const history = useHistory();

  const [position, setPosition] = useState(0);
  const [total, setTotal] = useState(1);

  const next = () => {
    if (position < 2) {
      if (position === 0 && data.picture2) {
        setPosition(() => position + 1);
      }
      if (position === 1 && data.picture3) {
        setPosition(() => position + 1);
      }
    }
  };
  const back = () => {
    if (position > 0) {
      setPosition(() => position - 1);
    }
  };

  const decreaseTotal = () => {
    if (total - 1 !== 0) {
      setTotal(() => total - 1);
    }
  };

  const buyNow = () => {
    addProducts();
    setTimeout(() => history.push("/carrito"), 600);
  };

  const addProducts = () => {
    toast.success("Upee agregado al carrito.");
    let flag = false;
    store.forEach((i) => {
      if (i.id === data.id) {
        i.total += total;
        flag = true;
      }
    });

    if (!flag) {
      let storeAux = [];
      let auxData = {};
      auxData.id = data.id;
      auxData.name = data.name;
      auxData.sku = data.sku;
      auxData.color = data.color;
      auxData.total = total;
      auxData.publicPrice = data.publicPrice;
      auxData.distributorPrice = data.distributorPrice;
      auxData.distributorPriceB = data.distributorPriceB;

      storeAux.push(auxData);
      store.forEach((i) => {
        storeAux.push(i);
      });
      updateStore(storeAux);
    } else {
      updateStore([...store]);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 100);
  }, [data]);
  return (
    <div>
      <Container>
        <div style={{ marginBottom: 30 }}>
          <img alt="" onClick={details} src={Ic_back_arrow} className={classes.button_arrow} />
        </div>
        <Grid container>
          <Grid item sm={7}>
            <div className={classes.card} style={{ borderRadius: 40 }}>
              <img
                alt=""
                src={data.picture1}
                className={classes.image}
                style={{ display: position === 0 ? "block" : "none" }}
              />
              <img
                alt=""
                src={data.picture2}
                className={classes.image}
                style={{ display: position === 1 ? "block" : "none" }}
              />
              <img
                alt=""
                src={data.picture3}
                className={classes.image}
                style={{ display: position === 2 ? "block" : "none" }}
              />

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "90%",
                  marginTop: -20,
                  paddingBottom: 20,
                }}
              >
                <img alt="" onClick={back} src={Ic_back_arrow} className={classes.button_arrow} />
                <img alt="" onClick={next} src={Ic_next_arrow} className={classes.button_arrow} />
              </div>
            </div>
          </Grid>
          <Grid item sm={5}>
            <div style={{ padding: 40, display: "flex", alignItems: "center", height: "100%" }}>
              <div style={{ marginBottom: 100 }}>
                <h2>{data.name}</h2>
                <p>{`$ ${formatMoney(
                  checkPrice(rol, distClass, data.distributorPrice, data.distributorPriceB, data.publicPrice)
                )} MXN`}</p>
                {data.enable ? (
                  <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <button onClick={decreaseTotal} className={classes.button} style={{ fontFamily: "m_semi_bold" }}>
                        {"-"}
                      </button>
                      <div style={{ width: 50, textAlign: "center" }}>
                        <label style={{ fontWeight: 600, fontSize: 22, fontFamily: "m_semi_bold" }}>{total}</label>
                      </div>
                      <button
                        onClick={() => setTotal(total + 1)}
                        className={classes.button}
                        style={{ fontFamily: "m_semi_bold" }}
                      >
                        {"+"}
                      </button>
                    </div>
                    <div style={{ height: 30 }} />
                    <button onClick={addProducts} style={{ fontFamily: "m_semi_bold" }} className={classes.button_add}>
                      {"Agregar a carrito"}
                    </button>
                    <div style={{ height: 30 }} />
                    <button
                      onClick={buyNow}
                      style={{ fontFamily: "m_semi_bold", background: colors.red }}
                      className={classes.button_add}
                    >
                      {"Comprar ahora"}
                    </button>
                  </>
                ) : (
                  <>
                    <p style={{ fontWeight: 600, fontSize: 20 }}>{"Este producto no se encuentra disponible"}</p>
                  </>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  store: state.Store.data,
  rol: state.Panel.rol,
  distClass: state.Panel.data.distribuitorClass,
});

export default connect(mapStateToProps, { updateStore })(IndividualProduct);
