import ReactLoading from "react-loading";
import { TableRow, TableCell } from '@material-ui/core';

const TableDiscount = ({value, setValue, onClick, background='#f2f2f2', color1='#000', loading}) => {
    return (
        <TableRow style={{borderTop:'3px solid #fff'}}>
            <TableCell align='center' style={{width:'20%', borderBottom:0}}/>
            <TableCell align='center' style={{width:'40%', borderBottom:0}}/>
            <TableCell align='center' style={{width:'20%', borderBottom:0}}/>
            <TableCell align='left'
                style={{width:'20%', paddingLeft:40, borderBottom:0, backgroundColor:background, fontWeight:600, color:color1, textTransform:'uppercase', fontSize:18}} >
                    {'Cupón'}
            </TableCell>
            <TableCell align='center' style={{padding: 0, borderBottom: 0, backgroundColor:background}}> 
                <div style={{display:'flex', alignItems:'center', position:'relative'}}>
                    <input value={value} onChange={(e)=>setValue(e.target.value)} style={{border:'none', paddingLeft:10, height:34, marginRight:10}} />
                    <p onClick={onClick} style={{margin:0, fontWeight:600, cursor:'pointer', fontFamily:'m_semi_bold', width:66, position:'relative'}}>
                        {
                        !loading?
                            'APLICAR'
                        :
                            <div style={{position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)'}}>
                                <ReactLoading type={"bubbles"} color={"white"} style={{fill:'#000', height:50, width:50}} />
                            </div>
                        }
                    </p>
                </div>
            </TableCell>
        </TableRow>
    );
};

export default TableDiscount;