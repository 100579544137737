import { useHistory } from "react-router-dom";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Button from "../global/Button2";

import { colors, Img_seller, Img_delivery_c } from "values";

export const useStyles = makeStyles((theme) => ({
  title: {
    color: "#fff",
    margin: 0,
    fontSize: 50,
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
      fontSize: 42,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 30,
    },
  },
  container: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "50px 0",
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
      justifyContent: "center",
    },
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    [theme.breakpoints.down("xs")]: {
      height: 400,
    },
  },
  text: {
    color: "#fff",
    margin: "15px 0",
    fontSize: 20,
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
}));

const YouWantToWork = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = () => {
    history.push("/registro");
  };
  return (
    <div style={{ padding: "70px 0" }}>
      <Container>
        <div style={{ textAlign: "center", marginBottom: 80 }}>
          <p style={{ color: colors.purple, fontSize: 18, fontFamily: "m_medium" }}>
            {
              "En Upee hemos rediseñado nuestro plan de distribución para brindarte  la mejor oportunidad de negocio en el nicho de mayor impacto comercial que somos las mujeres, y apoyándolas a través de un dispositivo de necesidad real que les brindará la confianza y seguridad que cambiará su vida."
            }
          </p>
          <p style={{ color: colors.purple, fontSize: 18, fontFamily: "m_medium" }}>
            {"Porque todas las mujeres del mundo necesitamos un Upee en nuestra vida y tu puedes ponerlo en sus manos."}
          </p>
        </div>
        <div style={{ width: "100%", position: "relative" }}>
          <div style={{ position: "absolute", top: "50%", left: 10, transform: "translate(0, -50%)" }}>
            <Container>
              <h2 className={classes.title} style={{ fontFamily: "walfords" }}>
                {"Quieres ser distribuidora"}
              </h2>
              <div style={{ width: "100%", maxWidth: 500, textAlign: "center" }}>
                <p className={classes.text} style={{ fontFamily: "m_medium" }}>
                  {"Tenemos todo un modelo de negocio rentable y creado para ti."}
                </p>
                <Button text="Comenzar" onAction={handleClick} />
              </div>
            </Container>
          </div>
          <img alt="" src={Img_delivery_c} className={classes.image} />
        </div>
      </Container>
    </div>
  );
};

export default YouWantToWork;
