import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { colors } from 'values';

export const useStyles = makeStyles(theme => ({
    title_back:{
        margin:0, color:`${colors.pink}`,
        fontSize:180, textTransform:'uppercase',
        [theme.breakpoints.down('sm')]:{
            right:'20%',
            fontSize:150,
        },
        [theme.breakpoints.down('sm')]:{
            right:'10%',
            fontSize:100,
        },
    },
    subtitle:{
        margin:0, color:`${colors.red}`, textTransform:'uppercase',
        fontSize:100, position:'absolute',
        bottom:17, right:'40%',
        [theme.breakpoints.down('sm')]:{
            right:'20%',
            fontSize:80,
        },
        [theme.breakpoints.down('sm')]:{
            right:'10%',
            fontSize:50,
            bottom:5
        },
    }
}));

const JoinTheTeam = () => {
    const classes = useStyles()
    return (
        <div style={{paddingBottom:50}}>
            <Container>
                <div style={{position:'relative'}}>
                    <p className={classes.title_back} style={{fontFamily:'m_extra_bold'}}>{'Únete'}</p>
                    <p className={classes.subtitle} style={{fontFamily:'m_extra_bold'}}>{'Al equipo'}</p>
                </div>
                <p style={{margin:0, fontSize:18, fontFamily:'m_regular'}}>{'Gracias por tu interés en Upee®, nos interesa mucho conocer más de ti, antes de comenzar por favor, crea tu cuenta en esta página.'}</p>
                <p style={{margin:0, fontSize:18, fontFamily:'m_regular'}}>{'Después ayudanos llenando el siguiente formulario.'}</p>
            </Container>
        </div>
    );
};

export default JoinTheTeam;