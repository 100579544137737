import { useState, useEffect, Fragment } from "react";
import { makeStyles, Container, Grid } from "@material-ui/core";
import { ComposableMap, ZoomableGroup, Geographies, Geography } from "react-simple-maps";
import ReactLoading from "react-loading";
import { connect } from "react-redux";

import DealersInfo from "./DealersInfo";

// Values
import { Flag_arg, Flag_col, Flag_eu, Flag_mx, Flag_pto, Flag_usa } from "values";
import geographyObject from "values/mexico.json";

// Api
import { getData } from "utils/distribuitors";
import { colors } from "values/index";

const useStyles = makeStyles((theme) => ({
  map: {
    width: "100%",
    height: 500,
    [theme.breakpoints.only("sm")]: {
      height: "600px",
    },
    [theme.breakpoints.only("xs")]: {
      height: "350px",
    },
  },
  columnRight: {
    display: "flex",
    paddingTop: "25px",
    paddingBottom: "25px",
    minHeight: "300px",
    width: "100%",
  },
  FederalEntity: {
    textTransform: "uppercase",
    fontFamily: "PT Sans Narrow",
    fontSize: "30px",
    fontWeight: "bold",
    "& span": {
      color: "#27E0CF",
    },
  },
  loading: {
    display: "flex",
    justifyContent: "center",
  },
  container_image: {
    minWidth: 350,
    height: 350,
    marginBottom: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 10px",
    cursor: "pointer",
  },
  title: {
    color: colors.red,
    fontSize: 80,
    fontWeight: 400,
    [theme.breakpoints.down("md")]: {
      fontSize: 60,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 40,
    },
  },
  image_point_sales: {
    width: 150,
    height: 150,
    objectFit: "cover",
    margin: 10,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "auto",
    },
  },
}));

const stylesGeography = {
  default: {
    fill: "#ECEFF1",
    stroke: "#607D8B",
    strokeWidth: 0.05,
    outline: "none",
  },
  hover: {
    fill: "#00c9b7",
    stroke: "#00c9b7",
    strokeWidth: 0.05,
    outline: "none",
  },
  pressed: {
    fill: "#0ba295",
    stroke: "#0ba295",
    strokeWidth: 0.05,
    outline: "none",
  },
};

const wrapperStyles = {
  width: "100%",
  maxWidth: 980,
  margin: "0 auto",
};

const Item = ({ bg, image, value, onAction }) => {
  const classes = useStyles();
  return (
    <a href="#map">
      <div onClick={() => onAction(value)} className={classes.container_image} style={{ background: bg }}>
        <div
          style={{
            background: "#fff",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 106,
            height: 106,
          }}
        >
          <img alt="" src={image} style={{ width: 100, height: 100 }} draggable={false} />
        </div>
      </div>
    </a>
  );
};

const CountryCards = ({ pointsales }) => {
  const classes = useStyles();
  const [federalEntity, setFederalEntity] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [thereIsData, setThereIsData] = useState(false);
  const [country, setCountry] = useState("");

  const [flagWidth, setFlagWidth] = useState(false);

  const [dealers, setDealers] = useState([]);

  const getDistribuitors = async () => {
    setDealers([]);
    setIsLoading(true);
    let extra;
    if (country === "México") {
      extra = `?country=${country}&state=${federalEntity}`;
    } else {
      extra = `?country=${country}`;
    }
    const res = await getData("", extra);
    setDealers(res.data.data);
    setIsLoading(false);
    setThereIsData(true);
  };

  useEffect(() => {
    if (federalEntity !== "") {
      getDistribuitors();
    }
  }, [federalEntity, country]);

  useEffect(() => {
    if (window.innerWidth > 1920) {
      setFlagWidth(true);
    } else {
      setFlagWidth(false);
    }
  }, []);
  return (
    <div style={{ padding: "70px 0" }}>
      <div style={{ textAlign: "center" }}>
        <h2 className={classes.title} style={{ fontFamily: "walfords", color: colors.back, margin: 0 }}>
          {"Encuentranos"}
        </h2>
        <p style={{ color: colors.purple, margin: 0, fontFamily: "m_regular" }}>
          {"Encuentra y compra tu Upee® en los siguientes lugares."}
        </p>
      </div>

      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap", marginTop: 20 }}>
        {pointsales && pointsales.map((i) => <img alt="" src={i.picture} className={classes.image_point_sales} />)}
      </div>

      <div style={{ textAlign: "center" }}>
        <h2 className={classes.title} style={{ fontFamily: "walfords" }}>
          {"Localiza un distribuidor cerca de ti"}
        </h2>
      </div>
      <div style={{ display: "flex", overflow: "auto", justifyContent: flagWidth ? "center" : "flex-start" }}>
        <Item value="México" bg={colors.red} image={Flag_mx} onAction={setCountry} />
        <Item value="Estados Unidos" bg={colors.back} image={Flag_usa} onAction={setCountry} />
        <Item value="Argentina" bg={colors.red} image={Flag_arg} onAction={setCountry} />
        <Item value="Europa" bg={colors.back} image={Flag_eu} onAction={setCountry} />
        <Item value="Colombia" bg={colors.red} image={Flag_col} onAction={setCountry} />
        <Item value="Puerto Rico" bg={colors.back} image={Flag_pto} onAction={setCountry} />
      </div>
      <Container>
        <div style={{ display: country === "México" ? "block" : "none", margin: "50px 0" }}>
          <Grid container>
            <Grid item sm={12} md={7} style={{ display: country === "México" ? "block" : "none" }} id="map">
              <div style={wrapperStyles}>
                <ComposableMap projectionConfig={{ scale: 900 }} className={classes.map}>
                  <ZoomableGroup center={[-460, 25]} zoom={2} disablePanning>
                    <Geographies geography={geographyObject}>
                      {(geographies, projection) =>
                        geographies.map(
                          (geography, i) =>
                            geography.id !== "ATA" && (
                              <Geography
                                key={i}
                                geography={geography}
                                projection={projection}
                                onClick={(geography, evt) => {
                                  setFederalEntity(geography.properties.NAME_1);
                                }}
                                style={stylesGeography}
                              />
                            )
                        )
                      }
                    </Geographies>
                  </ZoomableGroup>
                </ComposableMap>
              </div>
            </Grid>
            <Grid item sm={12} md={5} className={classes.columnRight}>
              <div style={{ position: "relative" }}>
                <p style={{ textTransform: "uppercase", fontSize: 30, fontFamily: "m_semi_bold" }}>
                  {country === "México" ? federalEntity : ""}
                </p>
                {isLoading ? (
                  <div>
                    <ReactLoading
                      type={"spinningBubbles"}
                      color={"white"}
                      style={{ fill: "#00c9b7", height: 50, width: 50 }}
                    />
                  </div>
                ) : dealers.length !== 0 ? (
                  dealers.map((i) => (
                    <DealersInfo
                      name={`${i.name} ${i.lastName}`}
                      address={i.distribuitorAddress}
                      state={i.distribuitorState}
                      phone={i.distribuitorPhone}
                      notes={i.distribuitorNotes}
                      email={i.distribuitorEmail}
                    />
                  ))
                ) : thereIsData ? (
                  <p style={{ fontFamily: "m_regular" }}>{"No hay distribuidores en esta zona"}</p>
                ) : country === "México" ? (
                  <p style={{ fontFamily: "m_regular" }}>{"Selecciona un estado"}</p>
                ) : (
                  <Fragment />
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  pointsales: state.Events.pointsales,
});

export default connect(mapStateToProps, {})(CountryCards);
