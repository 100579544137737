import { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'values';

export const useStyles = makeStyles(theme => ({
    input:{
        width:'calc(100% - 10px)', fontSize:16,
        border:'none', borderRadius:5,
        paddingLeft:10, marginTop:10, marginBottom:10, height:50,
    },
}));

const Input = ({title='Title', value, setValue, placeholder='', type='text', bg='#fff', color='#000', error}) => {
    const classes = useStyles()
    const handleChange = (e)=>{
        setValue({...value, value: e.target.value, flag:false})
    }
    return (
        <Fragment>
            <label style={{margin:0, fontFamily:'m_regular'}}>{title}</label>
            <input className={classes.input}
                value={value} onChange={handleChange} style={{fontFamily:'m_regular'}}
                type={type} style={{backgroundColor:bg, color:color, outline:error?`1px solid ${colors.red}`:''}}
                placeholder={placeholder} />
        </Fragment>
    );
};

export default Input;