
import { colors } from 'values';

const DealerPrice = () => {
    return (
        <div style={{background:colors.red, textAlign:'center', marginBottom:50}}>
            <p style={{margin:0, padding:'20px 0', fontSize:20, color:'#fff', textTransform:'uppercase', fontWeight:600, letterSpacing:6}}>{'Precios de distribuidora'}</p>
        </div>
    );
};

export default DealerPrice;