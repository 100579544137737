import { useHistory } from 'react-router-dom';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ScrollAnimation from 'react-animate-on-scroll';

import Button from 'components/global/Button';

import { Img_option_a, Img_option_b, Img_option_c, colors } from 'values';

export const useStyles = makeStyles(theme => ({
    title:{
        fontSize:60, margin:0, paddingBottom:50,
        [theme.breakpoints.down('sm')]:{
            fontSize:55
        },
    },
    images:{
        width:450,
        [theme.breakpoints.down('lg')]:{
            width:430,
        },
        [theme.breakpoints.down('md')]:{
            width:350,
        },
        [theme.breakpoints.down('xs')]:{
            width:'100%',
        },
    },
    container:{
        position:'relative', minHeight:900, marginTop:-60, zIndex:0,
        [theme.breakpoints.down('md')]:{
            minHeight:750,
        },
        [theme.breakpoints.down('sm')]:{
            marginTop:20,
            textAlign:'center'
        },
    },
    container1:{
        position:'absolute', left:0, top:0,
        [theme.breakpoints.down('sm')]:{
            position:'relative',
        },
    },
    container2:{
        position:'absolute', left:400, top:200,
        [theme.breakpoints.down('md')]:{
            left:300, top:150,
        },
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            left:0, top:0,
            marginTop:20
        },
    },
    container3:{
        position:'absolute', left:800, top:450,
        [theme.breakpoints.down('md')]:{
            left:600, top:350,
        },
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            left:0, top:0,
            marginTop:20
        },
    },
    container_button:{
        textAlign:'end',
        position:'relative', zIndex:1,
        [theme.breakpoints.down('sm')]:{
            textAlign:'center',
        },
    },
    display_button1:{
        display:'none',
        [theme.breakpoints.down('sm')]:{
            display:'block',
            marginBottom:30
        },
    },
    display_button2:{
        display:'block',
        [theme.breakpoints.down('sm')]:{
            display:'none',
        },
    }
}));

const Info = ({text}) =>{
    return(
    <div style={{display:'flex', justifyContent:'center', textAlign:'center'}}>
        <p style={{margin:0, width:250, paddingTop:10, fontFamily:'av_book'}}>{text}</p>
    </div>
    )
}

const UpeeTakesCareOfYou = () => {
    const classes = useStyles()
    const history = useHistory ()

    const handleClick = () =>{
        history.push('/uso')
    }
    return (
        <div style={{padding:'70px 0'}}>
            <Container>
                <div className={`${classes.container_button} ${classes.display_button1}`}>
                    <Button onClick={handleClick} text='¿Cómo se usa?' width={300} background={colors.red} />
                </div>
                <p className={classes.title} style={{fontFamily:'m_extra_bold'}}>{'upee te cuida ...'}</p>
                <div className={`${classes.container_button} ${classes.display_button2}`}>
                    <Button onClick={handleClick} text='¿Cómo se usa?' width={300} background={colors.red} />
                </div>
                <div className={classes.container}>
                    <div className={classes.container1}>
                        <ScrollAnimation animateIn="upee_take_care_of_you" animateOnce={false} duration={1.5}>
                            <img alt='' src={Img_option_a} className={classes.images} />
                            <Info text='Mantente lejos de superficies poco higiénicas' />
                        </ScrollAnimation>
                    </div>

                    <div className={classes.container2}>
                        <ScrollAnimation animateIn="upee_take_care_of_you" animateOnce={false} duration={2}>
                            <img alt='' src={Img_option_b} className={classes.images} />
                            <Info text='Tu salud y seguridad lo más importante' />
                        </ScrollAnimation>
                    </div>

                    <div className={classes.container3}>
                        <ScrollAnimation animateIn="upee_take_care_of_you" animateOnce={false} duration={2.5}>
                            <img alt='' src={Img_option_c}className={classes.images} />
                            <Info text='Único y exclusivo de Upee' />
                        </ScrollAnimation>
                    </div>

                </div>
            </Container>
        </div>
    );
};

export default UpeeTakesCareOfYou;