
const TableInfoMobile = ({title, value, fontFamily='av_book'}) => {
    return (
        <div style={{display:'flex', justifyContent:'space-between', width:'100%', borderBottom:'1px solid #e0e0e0', paddingBottom:10}}>
            <div style={{ width: '40%', display:'flex', alignItems:'center' }} >
                <p style={{fontFamily:fontFamily}}>{title}</p>
            </div>
            <div style={{ width: '60%', display:'flex', justifyContent: 'flex-end' }}>
                <p style={{fontFamily:fontFamily}}>{value}</p>
            </div>
        </div>
    );
};

export default TableInfoMobile;