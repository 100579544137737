import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

// Components
import ModalLogin from '../global/Login';

// Values
import { colors } from 'values';

// Styles
export const useStyles = makeStyles(theme => ({
    title:{
        fontWeight:600, fontSize:20, color:'#fff', textTransform:'uppercase', margin:0,
    },
    container:{
        background:colors.blue, paddingTop:10, paddingBottom:10, display:'flex',
        justifyContent:'center'
    },
    container_login:{
        background:'#f7f7f7', display:'flex', justifyContent:'center', alignItems:'center',
        paddingTop:15, paddingBottom:15
    },
    button:{
        backgroundColor:colors.primary,
        color:'#fff',
        textTransform:'uppercase',
        width:170, height:50,
        cursor:'pointer',
        border:'none',
        borderRadius:13,
        fontSize:20,
        '&:active':{
            transform: 'scale(0.96)',
        },
    },
}))

const Login = () => {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    return (
        <div style={{margin:'10px 0 70px 0'}}>
            <ModalLogin open={open} setOpen={setOpen} redirect={false} />
            <div className={classes.container} style={{}}>
                <p className={classes.title}>{'Inicia sesióno regístrate'}</p>
            </div>
            <div className={classes.container_login}>
                <p style={{margin:0}}>{'Crea una cuenta en upee para administrar tus compras.'}</p>
                <div style={{width:10}} />
                <button className={classes.button} onClick={()=>setOpen(true)}>{'Entrar'}</button>
            </div>
        </div>
    );
};

export default Login;