import { Fragment } from "react";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Button from "components/global/Button2";

import { colors } from "values";

export const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 90,
    margin: 0,
    color: colors.purple,
    fontWeight: 400,
    [theme.breakpoints.down("xs")]: {
      fontSize: 50,
      marginBottom: 20,
    },
  },
}));

const ToPrint = () => {
  const classes = useStyles();

  const openPdf = () => {
    window.open(
      "https://firebasestorage.googleapis.com/v0/b/upee-30b66.appspot.com/o/Documents%2FUpee%20-%20guia.pdf?alt=media&token=329cd222-23cb-40c3-8393-82cb57c0eb1a"
    );
  };
  return (
    <div style={{ padding: "70px 0" }}>
      <Container maxWidth="lg">
        <div style={{ textAlign: "center" }}>
          <h2 className={classes.title} style={{ fontFamily: "walfords" }}>
            {"Aprende a usarlo"}
          </h2>
          <p style={{ color: colors.purple, fontFamily: "m_medium" }}>
            {
              "Upee es fácil de usar y es compatible para niñas desde los 6 años hasta adultas mayores, es práctico e higiénico y lo puede usar parada o sentada, aquí te dejamos los pasos a seguir:"
            }
          </p>
          <Button text="Imprimir" style="xl" onAction={openPdf} />
        </div>
      </Container>
    </div>
  );
};

export default ToPrint;
