import { Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

import Button from "components/global/Button2";

import { colors, Img_banner_a, Img_banner_b } from "values";

export const useStyles = makeStyles((theme) => ({
  title2: {
    fontSize: 60,
    margin: 0,
    color: colors.red,
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
      fontSize: 40,
    },
  },
  container2: {
    padding: "100px 0",
    [theme.breakpoints.down("xs")]: {
      padding: "50px 0",
    },
  },
  container_info: {
    textAlign: "center",
    width: "90%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  container_images: {
    display: "flex",
    alignItems: "flex-start",
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
    },
  },
  image: {
    width: "50%",
    height: 370,
    objectFit: "cover",
    transition: "0.3s",
    transform: "scale(0.95)",
    "&:hover": {
      transform: "scale(1)",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  image_margin: {
    marginRight: 10,
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
      marginBottom: 20,
    },
  },
}));

const JustDoIt = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleChangePage = () => {
    history.push(`/que-es`);
  };
  return (
    <div className={classes.container2} style={{ overflow: "hidden" }}>
      <Container>
        <Grid container alignItems={"center"}>
          <Grid item xs={12} sm={6}>
            <div style={{ marginBottom: 100 }}>
              <h2 className={classes.title2} style={{ fontFamily: "walfords" }}>
                {"Que nada te detenga"}
              </h2>
              <div className={classes.container_info}>
                <p style={{ color: colors.red, fontFamily: "m_medium", fontSize: 18, lineHeight: 1.2 }}>
                  {
                    "Sabemos que quieres vivir plenamente en cada etapa de tu vida. Queremos acompañarte en todos tus retos, vívelos, enfréntalos, supéralos, disfrútalos y no dejes QUE NADA TE DETENGA!"
                  }
                </p>
                <Button text="Más información" onAction={handleChangePage} />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.container_images}>
              <img alt="" src={Img_banner_a} className={`${classes.image} ${classes.image_margin}`} />
              <img alt="" src={Img_banner_b} className={classes.image} />
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default JustDoIt;
