import { Fragment} from 'react'
import { useStyles } from './style';

const Select = ({title='Title', value, setValue, blockData, placeholder='Placeholder', type='text',options}) => {
    const classes = useStyles()
    return (
        <Fragment>
            <label style={{margin:0, fontSize:14, fontFamily:'m_regular'}}>{title}</label>
            <br/>
            <select value={value} onChange={e=>setValue(e.target.value)}
                style={{height:40, borderRadius:6}}
                className={classes.input_select} 
                type={type} style={{fontFamily:'m_regular'}}
                placeholder={placeholder} 
                disabled={blockData}>
                    <option value="" disabled hidden>{placeholder}</option>
                    {
                        options&&
                        options.map((item,index)=>(
                            <option key={index} value={item} style={{fontFamily:'arial'}}>{item}</option>
                        ))
                    }
                </select>
        </Fragment>
    );
};

export default Select;