import { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

// Components
import Input            from './Input';
import Button           from './Button';
import ContainerCard    from './ContainerCard';
import Title            from './Title';
// Values
import { toastText } from 'values';
// Api
import { updateShipping } from 'utils/users';

const Shipping = ({data, trigger, setTrigger}) => {
    const [blockData, setBlockData] = useState('disabled')
    const [loading, setLoading]     = useState(false)

    const [address, setAddress]             = useState('')
    const [numExt, setNumExt]               = useState('')
    const [numInt, setNumInt]               = useState('')
    const [area, setArea]                   = useState('')
    const [municipality, setMunicipality]   = useState('')
    const [state, setState]                 = useState('')
    const [postalCode, setPostalCode]       = useState('')

    const updateData = async() =>{
        setLoading(true)
        const res = await updateShipping(data.id, address, numExt, numInt, area, municipality, state, postalCode,)
        if(res){
            setBlockData('disabled')
            toast.success(toastText.update)
            setTrigger(()=>(trigger+1))
        } else{
            toast.warn(toastText.warning)
        }
        setLoading(false)
    }

    useEffect(()=>{
        setAddress(data.address)
        setNumExt(data.numExt)
        setNumInt(data.numInt)
        setArea(data.area)
        setMunicipality(data.municipality)
        setState(data.state)
        setPostalCode(data.postalCode)
    },[data])
    return (
        <ContainerCard>
            <Title text='Datos de envío' />

            <Grid container>
                <Grid item xs={12} md={8}>
                    <div style={{padding:'0 10px'}}>
                        <Input title={'Calle'} value={address} setValue={setAddress} blockData={blockData} placeholder={''} />
                    </div>
                </Grid>
                <Grid item xs={12} md={2}>
                    <div style={{padding:'0 10px'}}>
                        <Input title={'Num Ext'} value={numExt} setValue={setNumExt} blockData={blockData} placeholder={''} />
                    </div>
                </Grid>
                <Grid item xs={12} md={2}>
                    <div style={{padding:'0 10px'}}>
                        <Input title={'Num Int'} value={numInt} setValue={setNumInt} blockData={blockData} placeholder={''} />
                    </div>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} md={6}>
                    <div style={{padding:'0 10px'}}>
                        <Input title={'Colonia'} value={area} setValue={setArea} blockData={blockData} placeholder={''} />
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div style={{padding:'0 10px'}}>
                        <Input title={'Municipio'} value={municipality} setValue={setMunicipality} blockData={blockData} placeholder={''} />
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div style={{padding:'0 10px'}}>
                        <Input title={'Estado'} value={state} setValue={setState} blockData={blockData} placeholder={''} />
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div style={{padding:'0 10px'}}>
                        <Input title={'Cp'} value={postalCode} setValue={setPostalCode} blockData={blockData} placeholder={''} />
                    </div>
                </Grid>
            </Grid>
            <div style={{height:20}} />
            <Button blockData={blockData}
                setBlockData={()=>setBlockData('')}
                onClick={updateData} loading={loading} />
            <div style={{height:20}} />
        </ContainerCard>
    );
};

const mapStateToProps = state =>({
    data: state.Panel.data
})

export default connect(mapStateToProps, {})(Shipping)