// Values
import { Ic_camera } from 'values';
// Styles
import { useStyles } from './style';

const ButtonCamera = ({setImage}) => {
    const classes= useStyles();

    const handleImage = (imag) => {
        setImage(imag.files[0])
    }

    return (
        <label className={classes.camera}>
            <img alt='' loading='lazy' draggable='false' src={Ic_camera} style={{width:20}} />
            <input id={`file-upload`} type="file" accept="image/*" 
                style={{display:'none'}} 
                onChange={e => handleImage(e.target) } />
        </label>
    );
};

export default ButtonCamera;