import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import {app} from 'firebase';

import {urlApi, urlPath} from '../api';

export const verifyLogin = async(email, password) =>{
    // console.log('verifyLogin');
    const url = urlApi + urlPath.login + email
    let flag1 = false
    let flag2 = false
    let token = ''
    let data = {}

    if(email){
        await fetch(url)
        .then(res =>{
            if(res.status===200){
                flag1 = true
                return res.json()
            }
        })
        .then(res =>{
            if(flag1){
                // console.log('Res: ', res.data);
                const name      = res.data.name
                const lastName  = res.data.lastName
                const picture   = res.data.picture
                const id        = res.data.id
                const role      = res.data.role
                data = {name, lastName, picture, id, role, email}
                // console.log('Data: ', data);
            }
        })
    }
    if(email && password && flag1===true){
        const auth = getAuth(app);
        await signInWithEmailAndPassword(auth, email, password)
        .then((userCredential)=>{
            flag2 = true
            const user = userCredential.user
            token = user.accessToken
            // console.log('userCredential: ', user.accessToken);
        })
        .catch((error) =>{
            console.log('Error');
            flag2 = false
        })
    }

    return {flag:flag2, token, data}
}