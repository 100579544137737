import { useState, useEffect } from 'react';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import Button from 'components/global/Button2';

import { colors, urlVideo } from 'values';
import { getUrlVideo } from 'utils';

export const useStyles = makeStyles(theme => ({
    video: {
        width: '100%',
        height: 700,
        [theme.breakpoints.down('md')]: {
            height: 500,
        },
        [theme.breakpoints.down('sm')]: {
            height: 450,
        },
        [theme.breakpoints.down('xs')]: {
            height: 250,
        },
    },
}));

const Video = ({ viewText = true }) => {
    const classes = useStyles()
    const history = useHistory()

    const [codeVideo, setCodeVideo] = useState('')

    const handleChangePage = () => {
        history.push(`/compra`)
    }

    useEffect(() => {
        if (urlVideo) {
            const url = getUrlVideo(urlVideo)
            setCodeVideo(url)
        }
    }, [urlVideo])
    return (
        <div style={{ padding: '70px 0' }}>
            <Container>
                <iframe className={classes.video} src={`${codeVideo}?fs=1`} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <div style={{ textAlign: 'center', marginTop: 50, display: viewText ? 'block' : 'none' }}>
                    <Button text='Compra hoy' style='xl' onAction={handleChangePage} />
                    <p style={{ color: colors.red, fontFamily: 'm_medium' }}>{'Envios a todo México y el mundo, contactanos...'}</p>
                </div>
            </Container>
        </div>
    );
};

export default Video;