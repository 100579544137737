import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Button from 'components/global/Button2';

import { colors } from 'values';

export const useStyles = makeStyles(theme => ({
    container: {
        textAlign: 'center', padding: '70px 0', marginBottom: 50,
        lineHeight: 1.2,
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center'
        },
    },
    text1: {
        color: colors.purple,
        marginBottom: 50,
        fontSize: 20,
        // [theme.breakpoints.down('xs')]: {
        //     marginBottom: 60,
        // },
    },
    text2: {
        color: colors.purple, fontSize: 20,
        // marginTop: 100,
        // [theme.breakpoints.down('xs')]: {
        //     marginTop: 60,
        // },
    },
    container_text: {

    }
}));

const MoreInfo = () => {
    const classes = useStyles()
    return (
        <div className={classes.container}>
            <Container maxWidth='lg'>
                <p className={classes.text1} style={{ fontFamily: 'm_medium' }}>
                    {'UPee es más que una manera diferente y cómoda de ir al baño, Upee es seguridad, libertad, confianza y salud.'}
                </p>
                <Button text='Compra hoy' style='xl' />
                <p style={{ color: colors.red, fontFamily: 'm_medium' }}>{'Envios a todo México y el mundo, contactanos...'}</p>

                <div>
                    <p className={classes.text2} style={{ fontFamily: 'm_medium', marginTop: 50 }}>{'Nos ha permitido ir de manera digna al baño, sin preocupaciones por las bacterias que se anidan en los baños públicos, ha apoyado a miles de mujeres en su embarazo y posparto: evitando sentarse innecesariamente y hacer ese esfuerzo que en esta etapa vaya que cuesta.'}</p>
                    <p className={classes.text2} style={{ fontFamily: 'm_medium' }}>{'Miles de mamás han enseñado a sus hijas a usarlo, evitando llenar de papel el sanitario y cargar a las pequeñas, manteniendo un cero contacto con sanitarios sucios.'}</p>
                    <p className={classes.text2} style={{ fontFamily: 'm_medium' }}>{'Qué decir de las carreteras y esas urgencias en bosques; nos ha salvado en situaciones de urgencia, se parte del cambio y prueba Upee.'}</p>
                </div>

            </Container>
        </div>
    );
};

export default MoreInfo;