import { useState } from 'react';

// Values
import {
    Ic_card, Ic_oxxo, Ic_paypal, Ic_spei,
    Ic_card_white, Ic_oxxo_white, Ic_paypal_white, Ic_spei_white,
    colors
} from 'values';
import { formatMoney } from 'utils';

//Style
import { useStyles } from './styles';

const Options = ({ title, image, whiteImage, value, option, setOption }) => {
    const classes = useStyles()
    return (
        <div onClick={() => setOption(value)}
            style={{ width: '100%', backgroundColor: value === option ? colors.primary : '#f5f5f5', cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
            <img alt="" src={value === option ? whiteImage : image} style={{ width: 60, objectFit: 'scale-down', padding: 20 }} />
            <p className={classes.options_payments_text} style={{ color: value === option ? '#fff' : '#000', fontFamily: 'm_semi_bold' }}>
                {title}
            </p>
        </div>
    )
}

const Payments = ({ optionPayments, total }) => {
    const classes = useStyles()
    const [option, setOption] = useState(2)

    const setOptionPayments = (val) => {
        optionPayments(val)
        setOption(val)
    }

    return (
        <div className={classes.container_payments}>
            <div className={classes.container_method_payments1}>
                <p className={classes.title_payment} style={{ fontFamily: 'm_extra_bold' }}>
                    {'Selecciona tu método de pago'}
                </p>
            </div>
            <div className={classes.container_method_payments2}>
                <div style={{ width: '100%' }}>
                    <Options title='Pago en Oxxo' image={Ic_oxxo} whiteImage={Ic_oxxo_white} value={0} option={option} setOption={setOptionPayments} />
                    <Options title='Pago con Tarjeta' image={Ic_card} whiteImage={Ic_card_white} value={1} option={option} setOption={setOptionPayments} />
                    <Options title='Pago con Paypal' image={Ic_paypal} whiteImage={Ic_paypal_white} value={2} option={option} setOption={setOptionPayments} />
                    <Options title='Pago con Spei' image={Ic_spei} whiteImage={Ic_spei_white} value={3} option={option} setOption={setOptionPayments} />
                </div>
            </div>
            <div className={classes.container_method_payments3} style={{ background: colors.back }}>
                <div style={{ width: '100%', lineHeight: 1 }}>
                    <div style={{ marginLeft: 30, marginTop: -50 }}>
                        <p style={{ margin: 0, color: colors.front, fontSize: 110, fontFamily: 'm_extra_bold' }}>{'1'}</p>
                        <p style={{ margin: 0, color: '#fff', fontSize: 55, fontFamily: 'm_extra_bold' }}>{`$ ${formatMoney(total)}`}</p>
                        <p style={{ margin: 0, color: '#fff', fontSize: 55, fontFamily: 'm_extra_bold' }}>{'MXN'}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Payments;