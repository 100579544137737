import { Fragment } from "react";

import Banner from "./Banner";
import Subscribe from "../global/Subscribe";

const Contact = () => {
  return (
    <Fragment>
      <Banner />
      <Subscribe />
    </Fragment>
  );
};

export default Contact;
