import { makeStyles } from '@material-ui/core/styles';

import { colors } from 'values';

export const useStyles = makeStyles(theme => ({
    container_number:{
        position:'absolute', right:-150, top:-10,
        [theme.breakpoints.down('lg')]:{
            right:-100
        },
        [theme.breakpoints.down('md')]:{
            right:0,
            top:0
        },
    }
}));

const Page = ({number}) => {
    const classes = useStyles()
    return (
        <div className={classes.container_number}>
            <label style={{margin:0, fontSize:40, color:colors.primary, fontFamily:'m_semi_bold'}}>{number}</label>
            <label style={{margin:0, fontSize:40, color:colors.silver, fontFamily:'m_semi_bold'}}>{'/4'}</label>
        </div>
    )
}

const Title = ({title, number}) => {
    return (
        <div style={{position:'relative'}}>
            <p style={{fontWeight:600, margin:0, fontSize:25}}>{title}</p>
            <Page number={number} />
            <br />
        </div>
    );
};

export default Title;