import { Fragment } from 'react';
import { Container } from '@material-ui/core';

import Subscribe from '../global/Subscribe';

import { colors } from 'values';

const Main = () => {
    return (
        <Fragment>
            <div style={{ textAlign: 'center', marginTop: 70, marginBottom: 150 }}>
                <Container>
                    <h2 style={{ fontFamily: 'walfords', color: colors.red, fontSize: 60, margin: 0 }}>{'Terminos y condiciones'}</h2>
                    <p style={{ color: colors.purple }}>{'Con fundamento en los artículos 15 y 16 de la Ley Federal de Protección de Datos Personales en Posesión de Particulares hacemos conocimiento que UIXI S.A de C.V con domicilio en Av Compositores 4550 Zapopan Jalisco México es responsable de recabar sus datos personales, del uso que se le dé a los mismos y de su protección. Su información personal será utilizada para las siguientes finalidades: proveer los servicios y productos que ha solicitado; notificarle sobre nuevos productos y servicios que tengan relación con los ya contratados o adquiridos; comunicarle sobre cambios en los mismos; elaborar estudios y programas que son necesarios para determinar hábitos de consumo; realizar evaluaciones periódicas de nuestros productos y servicios a efecto de mejorar la calidad de los mismos; evaluar la calidad del servicio que brindamos, y en general, para dar cumplimiento a las obligaciones que hemos contraído con usted.'}</p>
                    <p style={{ color: colors.purple }}>{'Para las finalidades antes mencionadas, requerimos obtener los siguientes datos personales: Nombre completo, edad, sexo, teléfono fijo, correo electrónico, RFC. Es importante informarle que usted tiene derecho al acceso, rectificación y cancelación de sus datos personales a oponerse al tratamiento de los mismos o a revocar el consentimiento que para dicho fin nos haya otorgado.'}</p>
                    <p style={{ color: colors.purple }}>{'Para ello es necesario que envíe la solicitud en los términos que marca la Ley en su Art.29 a Cynthia Varela, responsable de nuestro Departamento de Protección de Datos Personales. Ubicado en Av Compositores 4550 Zapopan Jalisco México, o bien, se comunique al teléfono 33 3189 0887 o por correo electrónico a hola@upee.mx'}</p>
                    <p style={{ color: colors.purple }}>{'En caso de que no desee recibir mensajes promocionales de nuestra parte, puede enviarnos su solicitud por medio de la dirección electrónica puesta arriba.'}</p>
                </Container>
            </div>
            <Subscribe />
        </Fragment>
    );
};

export default Main;