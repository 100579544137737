import { Fragment } from 'react';

import Subscribe from '../global/Subscribe';
import Banner from './Banner';
import Info from '../global/Info';
import Top from '../global/Top';
import Text from './Text';
import MoreInfo from './MoreInfo';

const AboutUs = () => {
    return (
        <Fragment>
            <Banner />
            <Top displayTitle={false} />
            <Text />
            <Info />
            <MoreInfo />
            <Subscribe />
        </Fragment>
    );
};

export default AboutUs;