import { Fragment, useState } from "react";
import { Container, Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { colors, Img_delivery_b, Upee_chanels, infoChannels } from "values";

export const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 80,
    margin: 0,
    fontWeight: 400,
    color: colors.primary,
    [theme.breakpoints.down("xs")]: {
      fontSize: 40,
    },
  },
  card: {
    padding: "10px 20px",
    fontSize: 18,
    color: "#fff",
    height: 60,
    marginBottom: 10,
    marginRight: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    transition: "0.3s",
    fontFamily: "m_medium",
    "&:hover": {
      transform: "scale(1.05)",
    },
    [theme.breakpoints.down("xs")]: {
      width: "88% !important",
      marginRight: 0,
      // overflow: "hidden",
    },
  },
  image: {
    width: 230,
    height: 170,
    objectFit: "cover",
    transition: "0.3s",
    "&:hover": {
      transform: "scale(1.05)",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginBottom: 10,
    },
  },
  main_container: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
    },
  },
  container_partner: {
    width: 230,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));

const Card = ({ text = "", bg = colors.primary, w = 200, onAction }) => {
  const classes = useStyles();
  return (
    <div className={classes.card} onClick={onAction} style={{ background: bg, width: w, cursor: "pointer" }}>
      <p>{text}</p>
    </div>
  );
};

const Channels = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [option, setOption] = useState(0);

  const handleModal = (value) => {
    setOpen(true);
    setOption(value);
  };

  return (
    <Fragment>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Container maxWidth="md">
          <div style={{ background: "#fff", padding: "20px 40px", borderRadius: 7, minHeight: 200 }}>
            <h4 style={{ color: colors.purple }}>
              En Upee deseamos acompañarte en todo momento y por eso tenemos un canal diseñado para ti, tu negocio y tus
              necesidades
            </h4>
            <div>
              <label style={{ whiteSpace: "pre-line", color: colors.purple }}>{infoChannels[option].content}</label>
            </div>
          </div>
        </Container>
      </Modal>

      <Container>
        <div style={{ textAlign: "center" }}>
          <h2 className={classes.title} style={{ fontFamily: "walfords" }}>
            Canales Upee
          </h2>
          <p style={{ color: colors.purple, fontFamily: "m_medium", marginBottom: 50 }}>
            Estas son nuestros canales de venta oficial.
          </p>
        </div>

        <div className={classes.main_container}>
          <div>
            <div className={classes.main_container}>
              <Card text="Ventas menudeo/mayoreo" bg={colors.red} onAction={() => handleModal(0)} />
              <Card text="Distribuciones Nacionales" bg={colors.purple} onAction={() => handleModal(1)} />
            </div>
            <div className={classes.main_container}>
              <Card text="Franquicias" onAction={() => handleModal(2)} />
              <Card text="Distribuciones Internacionales" bg={colors.red} onAction={() => handleModal(3)} />
            </div>
          </div>
          <img alt="" src={Img_delivery_b} className={classes.image} />
        </div>

        <div className={classes.main_container}>
          <Card text="Farmacias y Hospitales" bg={colors.purple} onAction={() => handleModal(4)} />
          <Card text="Eventos" onAction={() => handleModal(5)} />
          <div className={classes.container_partner}>
            <Card text="Patrocinios" bg={colors.red} w={190} onAction={() => handleModal(6)} />
          </div>
        </div>
      </Container>
    </Fragment>
  );
};

export default Channels;
