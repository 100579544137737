import { useState, useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Ic_gallery, colors } from 'values';

export const useStyles = makeStyles(theme => ({
    container_image:{
        cursor:'pointer', position:'relative',
        background:colors.gray, textAlign:'center',
        height:350,
        display:'flex', alignItems:'center', justifyContent:'center',
        margin:'5px 20px',
        [theme.breakpoints.down('md')]:{
            margin:'5px 10px',
        },
    },
}));

const DragImage = ({image, setImage, option='', text=''}) => {
    const classes = useStyles()

    const [img, setImg] = useState(image)

    const handleImage = (imag) => {
        const url = window.URL.createObjectURL(imag.files[0])
        setImg(url)
        setImage(imag.files[0])
    }

    return (
        <Fragment>
            <label htmlFor={`file-upload${option}`} className={classes.container_image}>
                {
                    img?
                    <img alt='' src={img} style={{width:'100%', height:'100%', objectFit:'cover'}} />
                        :
                    <div style={{marginTop:-100}}>
                        <img alt=''src={Ic_gallery} style={{marginBottom:30}} />
                        <p style={{width:200}}>{text}</p>
                    </div>
                }
            </label>
            <input id={`file-upload${option}`} type="file" accept="image/*" style={{display:'none'}} onChange={e => handleImage(e.target) } />
        </Fragment>
    );
};

export default DragImage;