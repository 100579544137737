import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

// Values
import { Img_Banner, colors } from "values";
// Styles
export const useStyles = makeStyles((theme) => ({
  image_banner: {
    width: "100%",
    height: "100%",
    animation: `$image_animation 0.8s ease-in-out both`,
    objectFit: "cover",
    position: "absolute",
    top: 0,
  },
  "@keyframes image_animation": {
    "0%": {
      opacity: 0,
      transform: "scale(1.2)",
    },
    "100%": {
      opacity: 1,
      transform: "scale(1)",
    },
  },
  container: {
    height: "105vh",
    width: "100%",
    position: "relative",
    marginTop: -150,
    overflow: "hidden",
    background: "#ffc904",
    zIndex: 1,
    position: "relative",
  },
  text_carousel: {
    animation: "$animation_text 10s linear infinite",
  },
  "@keyframes animation_text": {
    "0%": { transform: "translate(0, 0)" },
    "100%": { transform: "translate(-100%, 0)" },
  },
  gradiant: {
    background: "linear-gradient(180deg, rgba(19,20,21,0.47) 0%, rgba(19,20,21,0.00) 100%)",
    height: "90vh",
    width: "100%",
    position: "absolute",
    zIndex: 2,
  },
  button: {
    backgroundColor: "#fff",
    color: "#000",
    textTransform: "uppercase",
    width: 200,
    height: 60,
    cursor: "pointer",
    border: "none",
    borderRadius: 45,
    fontSize: 16,
    fontWeight: 600,
    // letterSpacing: 4,
    "&:active": {
      transform: "scale(0.96)",
    },
  },
  title: {
    color: "#fff",
    fontSize: 64,
    textTransform: "uppercase",
    [theme.breakpoints.down("md")]: {
      fontSize: 60,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 40,
    },
  },
  container_title: {
    zIndex: 3,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    textAlign: "center",
  },
}));

const Item = () => {
  const classes = useStyles();
  return (
    <div className={classes.text_carousel}>
      <p style={{ margin: "0 50px", color: "#fff", fontFamily: "m_regular" }}>
        {"Envios a todo México y el mundo, contactanos..."}
      </p>
    </div>
  );
};

const Banner = ({ data }) => {
  const classes = useStyles();
  const history = useHistory();

  const [position, setPosition] = useState(0);
  const [maxData, setMaxData] = useState(data.length ? data.length : 0);

  const onAnimation = (pos) => {
    setPosition(() => pos);
  };

  const changeInfoImgaTime = () => {
    if (position < maxData - 1) {
      onAnimation(position + 1);
    } else {
      onAnimation(0);
    }
  };

  useEffect(() => {
    setMaxData(() => data.length);
    const timeout = setTimeout(() => changeInfoImgaTime(), 10000);
    return () => clearTimeout(timeout);
  }, [position]);
  return (
    <div>
      <div className={classes.container}>
        <div className={classes.gradiant} />
        <img loading="eager" alt="" src={Img_Banner} className={classes.image_banner} />
        <div className={classes.container_title}>
          <h1 className={classes.title}>{"Compra hoy"}</h1>
          <div onClick={() => history.push("/compra")} style={{ display: "flex", justifyContent: "center" }}>
            <button className={classes.button}>{"Comprar"}</button>
          </div>
        </div>
      </div>
      <div
        style={{
          position: "relative",
          height: 40,
          background: colors.red,
          width: "100%",
          display: "flex",
          overflow: "hidden",
          whiteSpace: "nowrap",
          alignItems: "center",
        }}
      >
        <Item />
        <Item />
        <Item />
        <Item />
        <Item />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.Banners.data,
});

export default connect(mapStateToProps, {})(Banner);
