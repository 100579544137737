import { makeStyles } from '@material-ui/core/styles';

import { colors } from 'values';

export const useStyles = makeStyles(theme => ({
    button:{
        color:'#000', textTransform:'uppercase',
        cursor:'pointer', border:'none',
        fontSize:16, fontWeight:600,
        letterSpacing:6, width:90, height:80,
        marginLeft:10,
        transition: 'transform .2s',
        '&:hover':{
            transform:'scale(1.05)',
            '&:active':{
                transform: 'scale(1)',
            },
        },
        [theme.breakpoints.down('xs')]:{
            marginLeft:0,
        },
    },
}));

const Button = ({option, value, background='#fff', onClick}) => {
    const classes = useStyles()

    const checkBackground = () =>{
        let bg = '#fff'
        if(option==='SI' && value===true){
            bg = colors.back
        }
        if(option==='NO' && value===false){
            bg = colors.red
        }
        return bg
    }
    const checkColor = () =>{
        let color = '#000'
        if(option==='SI' && value===true){
            color = '#fff'
        }
        if(option==='NO' && value===false){
            color = '#fff'
        }
        return color
    }
    return (
        <button onClick={onClick} className={classes.button} style={{background:checkBackground(), color:checkColor()}}>
            {option}
        </button>
    );
};

export default Button;