import { useState } from 'react';
import { Container, Grid } from '@material-ui/core';
import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';

import ContainerForm from './ContainerForm';
import Input from './Input';
import Button from 'components/global/Button';
import ButtonOption from './Button';
import Title from './Title';
import JoinTheTeam from './JoinTheTeam';
import DragImage from './DragImage';

import { colors } from 'values';

import { deployImage } from 'utils';
import { validateForm1, validateForm2, postData } from 'utils/postulants';

export const useStyles = makeStyles(theme => ({
    container_numbers:{
        display:'flex', alignItems:'center', justifyContent:'space-between',
        [theme.breakpoints.down('xs')]:{
            flexWrap:'wrap'
        },
    },
    container_options:{
        [theme.breakpoints.down('xs')]:{
            display:'flex', justifyContent:'space-between', width:'100%',
            marginBottom:20
        },
    }
}));

const Registration = () => {
    const classes = useStyles()

    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false)

    const [name, setName]   = useState({value:'', flag:false})
    const [phone, setPhone] = useState({value:'', flag:false})
    const [email, setEmail] = useState({value:'', flag:false})
    const [instagram, setInsta]     = useState({value:'', flag:false})

    const [businessName, setBN]     = useState({value:'', flag:false})
    const [businessPhone, setBP]    = useState({value:'', flag:false})
    const [webPage, setWebPage]     = useState({value:'', flag:false})
    const [facebook, setFacebook]   = useState({value:'', flag:false})
    const [location, setLoaction]   = useState({value:'', flag:false})
    const [address, setAddress]     = useState({value:'', flag:false})

    const [sell, setSell]           = useState('')

    const [business, setBusiness]   = useState({value:'', flag:false})
    const [brand, setBrand]         = useState({value:'', flag:false})
    const [time, setTime]           = useState({value:'', flag:false})
    const [workers, setWorkers]     = useState({value:'', flag:false})

    const [imageLocal, setImageL]   = useState('')
    const [imageArea, setImageArea] = useState('')
    const [imageProduct, setImageP] = useState('')
    const [logo, setLogo]           = useState('')

    const activateErrorLayout = (error, setError, value, valueDef) =>{
        if(error===value){
            setError({...valueDef, flag:true})
        }
    }

    const handleValidate1 = () =>{
        const res = validateForm1(name.value, phone.value, email.value)
        if(res.flag){
            setPage(2)
        } else{
            toast.warn(res.message)
            activateErrorLayout(res.error, setName, 1, name)
            activateErrorLayout(res.error, setPhone, 2, phone)
            activateErrorLayout(res.error, setEmail, 3, email)
        }
    }

    const handleValidate2 = () =>{
        const res = validateForm2(businessPhone.value, address.value)
        if(res.flag){
            setPage(3)
        } else{
            toast.warn(res.message)
            activateErrorLayout(res.error, setBP, 1, businessPhone)
            activateErrorLayout(res.error, setAddress, 2, address)
        }
    }

    const handleForm = async() =>{
        setLoading(true)

        let auxImage1 = imageLocal
        let auxImage2 = imageArea
        let auxImage3 = imageProduct
        let auxImage4 = logo

        if(typeof imageLocal==='object'){
            auxImage1 = await deployImage(imageLocal, "Postulants", `Postulants1`)
            setImageL(auxImage1)
        }
        if(typeof imageArea==='object'){
            auxImage2 = await deployImage(imageArea, "Postulants", `Postulants2`)
            setImageArea(auxImage2)
        }
        if(typeof imageProduct==='object'){
            auxImage3 = await deployImage(imageProduct, "Postulants", `Postulants3`)
            setImageP(auxImage3)
        }
        if(typeof logo==='object'){
            auxImage4 = await deployImage(logo, "Postulants", `Postulants4`)
            setLogo(logo)
        }

        const res = postData(name.value, phone.value, email.value, instagram.value, businessName.value, businessPhone.value, webPage.value,
            facebook.value, location.value, address.value, sell,
            business.value, brand.value, time.value, workers.value,
            auxImage1, auxImage2, auxImage3, auxImage4)
        if(res){
            toast.success('Se enviaron los datos')
        } else{
            toast.warn('Al parecer surgio un error vuelve a intentarlo')
        }

        setLoading(false)
    }
    return (
        <div style={{padding:'70px 0'}}>
            <JoinTheTeam />

            <div style={{display:page===1?'block':'none'}}>
                <ContainerForm>
                    <Title title='Datos personales' number='1' />

                    <Input title='Nombre completo' value={name.value} setValue={setName} error={name.flag} />
                    <Input title='Teléfono de contacto y horario' value={phone.value} setValue={setPhone} error={phone.flag} />
                    <Input title='Email' placeholder='Email (Registrado en la página)' value={email.value} setValue={setEmail} error={email.flag} />
                    <Input title='Instagram (Tal como apareces)' value={instagram.value} setValue={setInsta} />

                    <div style={{textAlign:'end'}}>
                        <Button text='Continuar' onClick={handleValidate1} background={colors.red} />
                    </div>
                </ContainerForm>
            </div>

            <div style={{display:page===2?'block':'none'}}>
                <ContainerForm>
                    <Title title='Informacioón comercial' number='2' />

                    <Input title='Razón social o nombre del negocio' value={businessName.value} setValue={setBN} />
                    <Input title='Teléfono de contacto y horario' value={businessPhone.value} setValue={setBP} error={businessPhone.flag} />
                    <Input title='Pagina web' value={webPage.value} setValue={setWebPage} />
                    <Input title='Facebook' value={facebook.value} setValue={setFacebook} />
                    <Input title='Estado y ciudad (Establecimiento)' value={location.value} setValue={setLoaction} />
                    <Input title='Dirección del lugar comercial' value={address.value} setValue={setAddress} error={address.flag} />

                    <div style={{textAlign:'end'}}>
                        <Button text='Continuar' onClick={handleValidate2} background={colors.red} />
                    </div>
                </ContainerForm>
            </div>

            <div style={{display:page===3?'block':'none'}}>
                <ContainerForm>
                    <Title title='Experiencia' number='3' />

                    <div className={classes.container_numbers}>
                        <p>{'Manejas venta de producto'}</p>
                        <div className={classes.container_options}>
                            <ButtonOption option='SI' value={sell} onClick={()=>setSell(true)} />
                            <ButtonOption option='NO' value={sell} onClick={()=>setSell(false)} />
                        </div>
                    </div>

                    <Input title='Giro' value={business.value} setValue={setBusiness} />
                    <Input title='¿Qué marcas manejas?' value={brand.value} setValue={setBrand} />
                    <Input title='Tiempo del establecimiento' value={time.value} setValue={setTime} />
                    <Input title='¿Cuantos colaboradores tienes?' value={workers.value} setValue={setWorkers} />

                    <div style={{textAlign:'end'}}>
                        <Button text='Continuar' onClick={()=>setPage(4)} background={colors.red} />
                    </div>
                </ContainerForm>
            </div>

            <div style={{display:page===4?'block':'none'}}>
                <div style={{backgroundColor:colors.light_gray, paddingTop:30, paddingBottom:30, borderBottom:'2px solid #fff'}}>
                    <Container maxWidth='md'>
                        <Title title='Experiencia' number='4' />
                        <p>{'Por favor, agrega fotografias si cuentas con alguno de los siguientes casos.'}</p>
                    </Container>
                    <Container maxWidth='lg'>
                        <Grid container>
                            <Grid item xs={12} sm={6} md={3}>
                                <DragImage image={imageLocal} setImage={setImageL}
                                    text='Fachada de tu establecimiento' option='1' />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <DragImage image={imageArea} setImage={setImageArea}
                                    text='Área de trabajo' option='2' />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <DragImage image={imageProduct} setImage={setImageP}
                                    text='Display de venta de producto' option='3' />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <DragImage image={logo} setImage={setLogo}
                                    text='Logotipo' option='4'/>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
                <Container>
                    <div style={{textAlign:'center', display:'flex', justifyContent:'center', paddingTop:50}}>
                        <p style={{maxWidth:800}}>{'AGRADECEMOS TU INTERES, RESPETAREMOS TU INFORMACION DE MANERA CONFIDENCIAL Y PRONTO NOS PONDREMOS EN CONTACTO CONTIGO.'}</p>
                    </div>
                    <div style={{textAlign:'center',}}>
                        <Button text={loading?'Cargando...':'Enviar'} onClick={handleForm} background={colors.red} />
                    </div>
                </Container>
            </div>

        </div>
    );
};

export default Registration;