import { makeStyles } from "@material-ui/core/styles";

import {
  colors,
  Upee_girl_a,
  Upee_girl_b,
  Img_top_1,
  Img_top_2,
  Img_top_3,
  Img_top_4,
  Img_top_5,
  Img_top_6,
} from "values";

export const useStyles = makeStyles((theme) => ({
  container_card_ball_top: {
    display: "flex",
    overflow: "auto",
    paddingBottom: 30,
    justifyContent: "center",
    [theme.breakpoints.up("xl")]: {
      // justifyContent: 'center',
    },
    [theme.breakpoints.down("xl")]: {
      // justifyContent: 'flex-start',
    },
    [theme.breakpoints.down("lg")]: {
      // justifyContent: 'flex-start'
    },

    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start",
    },
  },
  title1: {
    fontSize: 80,
    margin: 0,
    color: colors.purple,
    fontWeight: 400,
    margin: 0,
    [theme.breakpoints.down("lg")]: {
      fontSize: 45,
    },
  },
  image: {
    width: 450,
    margin: "0 20px",
    [theme.breakpoints.down("lg")]: {
      width: 300,
      margin: "0 10px",
    },
  },
}));

const Top = ({ displayTitle = true }) => {
  const classes = useStyles();
  return (
    <div style={{ padding: "70px 0" }}>
      <div style={{ textAlign: "center", marginBottom: 40, display: displayTitle ? "block" : "none" }}>
        <h2 className={classes.title1} style={{ fontFamily: "walfords" }}>
          {"Upee es diferente..."}
        </h2>
        <p style={{ margin: 0, marginTop: -5, color: colors.purple, fontFamily: "m_medium" }}>
          {"Lleva Upee contigo y que nada te detenga."}
        </p>
      </div>
      <div className={classes.container_card_ball_top}>
        <img alt="" src={Upee_girl_a} className={classes.image} />
        <img alt="" src={Upee_girl_b} className={classes.image} />

        {/* <img alt='' src={Img_top_1} className={classes.image} />
                <img alt='' src={Img_top_2} className={classes.image} />
                <img alt='' src={Img_top_3} className={classes.image} />
                <img alt='' src={Img_top_4} className={classes.image} />
                <img alt='' src={Img_top_5} className={classes.image} />
                <img alt='' src={Img_top_6} className={classes.image} /> */}
      </div>
    </div>
  );
};

export default Top;
