import { useState, useEffect, Fragment } from "react";
import { Container } from "@material-ui/core";
import { colors, User } from "values";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";

// Firebase
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
// Redux
import { saveToken, getUser, updatePicture } from "redux/actions/PanelAdmin";

import Profile from "./Profile";
import Shipping from "./Shipping";
import Billing from "./Billing";
import ButtonCamera from "./ButtonCamera";
import CropImage from "./CropImage";
import Records from "./Records";
import Distribuitor from "./Distribuitor";

// Style
import { useStyles } from "./style";
import { Profile as user_profile } from "../../values";

import { getData as getUserInfo, updateUserImage } from "utils/users";
import { deployImage } from "utils";

const MenuOption = ({ text, option, value, onChange }) => {
  const classes = useStyles();
  return (
    <div
      onClick={() => onChange(value)}
      style={{ background: option === value ? colors.red : colors.primary }}
      className={classes.menu_button}
    >
      <p className={classes.menu_text} style={{ fontFamily: "m_regular" }}>
        {text}
      </p>
    </div>
  );
};

const Panel = ({ name, lastName, getUser, picture, rol, email, saveToken, updatePicture }) => {
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();

  const [option, setOption] = useState(id ? id : "perfil");
  const [trigger, setTrigger] = useState(0);

  const [cropImage, setCropImage] = useState("");

  const logOut = async () => {
    if (window.confirm("Estas seguro que deseas cerrar sesion")) {
      const auth = getAuth();
      await signOut(auth)
        .then(() => {
          window.localStorage.clear();
          saveToken("", "", "", "", "", "");
          history.push("/");
        })
        .catch((error) => {
          console.log("Ocurrio un error la cerrar sesion");
        });
    }
  };

  const getInfoUser = async (uid) => {
    const resUser = await getUserInfo("", `/${uid}`);
    getUser(resUser.data.data);
  };

  const authUser = () => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        await getInfoUser(user.uid);
      }
    });
  };

  const updateImage = async (image) => {
    const auth = getAuth();
    let newImage = await deployImage(image, "User", "User");
    await onAuthStateChanged(auth, async (user) => {
      if (user) {
        await updateUserImage(user.uid, newImage);
        localStorage.setItem("gaser-picture", newImage);
        updatePicture(newImage);
        // setTrigger(()=>trigger+1)
      }
    });
  };

  useEffect(() => {
    authUser();
  }, [trigger]);
  return (
    <div style={{ minHeight: "100vh" }}>
      <CropImage image={cropImage} setImage={updateImage} />
      <Container>
        {/* -------------------------------------- */}
        {/* ------------ MENU DESKTOP ------------ */}
        {/* -------------------------------------- */}
        <div className={classes.container_panel_menu_desktop}>
          <div className={classes.bar_menu}>
            <div style={{ position: "relative", paddingTop: 50, height: 300 }}>
              <div style={{ position: "relative" }}>
                <div style={{ width: 190, height: 190, borderRadius: 100, background: "#fff", marginLeft: 55 }}>
                  <img
                    alt=""
                    src={picture ? picture : user_profile}
                    style={{ width: 190, height: 190, borderRadius: 100 }}
                  />
                </div>
                <ButtonCamera setImage={setCropImage} />
              </div>
              <div style={{ paddingTop: 13, textAlign: "center" }}>
                <p style={{ margin: 0, color: "#fff", fontSize: 24, fontWeight: 600, fontFamily: "m_semi_bold" }}>
                  {name}
                </p>
                <p style={{ margin: 0, color: "#fff", fontSize: 18, fontWeight: 600, fontFamily: "m_semi_bold" }}>
                  {lastName}
                </p>
                <p style={{ margin: 0, color: "#fff", fontSize: 14, fontFamily: "m_regular" }}>{email}</p>
              </div>
            </div>

            <div style={{ width: "100%" }}>
              <MenuOption text={"Mi perfil"} option={option} value={"perfil"} onChange={setOption} />
              <MenuOption text={"Datos de envío"} option={option} value={"shipping"} onChange={setOption} />
              <MenuOption text={"Datos de facturación"} option={option} value={"billing"} onChange={setOption} />
              <MenuOption text={"Mis compras"} option={option} value={"record"} onChange={setOption} />
              {rol === "Distribuitor" ? (
                <MenuOption text={"Datos distibuidor"} option={option} value={"distribuitor"} onChange={setOption} />
              ) : (
                <Fragment />
              )}
              <MenuOption text={"Cerrar sesión"} option={option} value={"close"} onChange={logOut} />
            </div>
          </div>

          <div style={{ width: "100%", paddingLeft: 30 }}>
            {option === "perfil" ? (
              <Profile trigger={trigger} setTrigger={setTrigger} />
            ) : option === "shipping" ? (
              <Shipping trigger={trigger} setTrigger={setTrigger} />
            ) : option === "billing" ? (
              <Billing trigger={trigger} setTrigger={setTrigger} />
            ) : option === "record" ? (
              <Records />
            ) : option === "distribuitor" ? (
              <Distribuitor />
            ) : (
              <div />
            )}
          </div>
        </div>

        {/* -------------------------------------- */}
        {/* ------------ MENU MOBILE ------------- */}
        {/* -------------------------------------- */}
        <div className={classes.container_panel_menu_mobile}>
          <div style={{ position: "relative", paddingBottom: 30 }}>
            {/* <img alt='' loading='lazy' src={Background_profile} draggable='false' style={{height:150, width:'100%', position:'absolute', top:0, zIndex:0}} /> */}
            <div style={{ display: "flex", justifyContent: "center", height: 200, paddingTop: 50 }}>
              <div style={{ position: "relative" }}>
                <div style={{ maxWidth: 190, maxHeight: 190, borderRadius: 100, background: "silver" }}>
                  <img
                    alt=""
                    loading="lazy"
                    draggable="false"
                    src={picture}
                    style={{ width: 190, height: 190, borderRadius: 100, objectFit: "cover" }}
                  />
                </div>
                <ButtonCamera setImage={setCropImage} />
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
              <Container>
                <p style={{ margin: 0, fontSize: 24, fontWeight: 600, fontFamily: "m_semi_bold" }}>
                  {name} {lastName}
                </p>
                <p style={{ margin: 0, fontSize: 14, fontFamily: "m_regular" }}>{email}</p>
              </Container>
            </div>
          </div>
          <div style={{ marginBottom: 50 }}>
            <MenuOption text={"Mi perfil"} option={option} value={"perfil"} onChange={setOption} />
            <MenuOption text={"Datos de envío"} option={option} value={"shipping"} onChange={setOption} />
            <MenuOption text={"Datos de facturación"} option={option} value={"billing"} onChange={setOption} />
            <MenuOption text={"Mis compras"} option={option} value={"record"} onChange={setOption} />
            {rol === "Distribuitor" ? (
              <MenuOption text={"Datos distibuidor"} option={option} value={"distribuitor"} onChange={setOption} />
            ) : (
              <Fragment />
            )}
            <MenuOption text={"Cerrar sesión"} option={option} value={"close"} onChange={logOut} />
          </div>
          <div style={{ width: "100%" }}>
            {option === "perfil" ? (
              <Profile trigger={trigger} setTrigger={setTrigger} />
            ) : option === "shipping" ? (
              <Shipping trigger={trigger} setTrigger={setTrigger} />
            ) : option === "billing" ? (
              <Billing trigger={trigger} setTrigger={setTrigger} />
            ) : option === "record" ? (
              <Records />
            ) : option === "distribuitor" ? (
              <Distribuitor />
            ) : (
              <div />
            )}
          </div>
        </div>
      </Container>
      <br />
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state.Panel.token,
  name: state.Panel.name,
  lastName: state.Panel.lastName,
  picture: state.Panel.picture,
  rol: state.Panel.rol,
  email: state.Panel.email,
  data: state.Panel.data,
});

export default connect(mapStateToProps, { saveToken, getUser, updatePicture })(Panel);
