import { Fragment } from "react";

import Info from "../global/Info";
import Subscribe from "../global/Subscribe";
import Channels from "./Channels";

const Main = () => {
  return (
    <Fragment>
      <Channels />
      <Info />
      <Subscribe />
    </Fragment>
  );
};

export default Main;
