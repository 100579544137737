import {reduxState, stateData}  from 'values';

const initialState = {
    data:       [],
    top:        [],
    status:     stateData.state1,
};

const reducer = (state = initialState, action) =>{
    if(action.type === reduxState.get_products){
        return{
            ...state,
            data:       action.products,
            top:        action.top,
            status:     stateData.state2
        }
    }
    return state
}
export default reducer;