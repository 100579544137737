import { useState, Fragment } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";

// Redux
import { updateStore } from "redux/actions/Store";
import { getSales } from "redux/actions/PanelAdmin";

// Components
import Tables from "./Tables";
import Payments from "./Payments";
import FormUser from "./FormUser";
import Invoice from "./Invoice";
import Login from "./Login";
import ButtonPay from "./ButtonPay";
import PayPalButton from "./PayPalButton";
import ToPay from "./ToPay";
import CartVoid from "./CartVoid";

// Api
import { paymentWithCard, createACustomer } from "utils/store";
import { updatePersonalData, updateShipping, updateBilling, getMySales, validateInfoShipping } from "utils/users";

const Cart = ({ store, id, rol, distribuitorClass, token, data, products, updateStore, getSales }) => {
  const [option, setOption] = useState(2);
  const [userData, setUserData] = useState({});
  const [invoiceData, setInvoiceData] = useState({});
  const [coupon, setCoupon] = useState("");
  const [total, setTotal] = useState(0);

  const [auxShippingType, setAuxShippingType] = useState("");

  const [sell, setSell] = useState(false);

  const [completed, setCompleted] = useState(false);
  const [purchase, setPurchase] = useState(true);

  const [flagInvoice, setFlagInvoice] = useState(false);
  const [invoiceSave, setInvoiceSave] = useState(true);

  const cleanStore = () => {
    updateStore([]);
  };

  const getAllSales = async () => {
    const resSales = await getMySales(token, `/${id}`);
    getSales(resSales.data.data);
  };

  const validateFormUser = () => {
    const name = userData.name;
    const lastName = userData.lastName;
    const phone = userData.phone;
    const address = userData.address;
    const numExt = userData.numExt;
    const state = userData.state;
    const municipality = userData.municipality;
    const area = userData.area;
    const postalCode = userData.postalCode;

    const res = validateInfoShipping(name, lastName, phone, address, numExt, state, municipality, area, postalCode);
    return res;
  };

  const toPayWithcard = async (tokenCard = "", order_Id = "") => {
    let paymentMethod = "PAYCARD";

    if (option === 0) {
      paymentMethod = "OXXO";
    }
    if (option === 1) {
      paymentMethod = "PAYCARD";
    }
    if (option === 2) {
      paymentMethod = "PAYPAL";
    }
    if (option === 3) {
      paymentMethod = "SPEI";
    }

    const name = userData.name;
    const lastName = userData.lastName;
    const phone = userData.phone;
    const address = userData.address;
    const numExt = userData.numExt;
    const numInt = userData.numInt;
    const state = userData.state;
    const municipality = userData.municipality;
    const area = userData.area;
    const postalCode = userData.postalCode;
    const email = data.email;
    const orderId = order_Id;

    const res = validateFormUser();
    if (res.flag === false) {
      toast.warn(res.message);
    }

    if (res.flag === true) {
      const legalName = flagInvoice ? invoiceData.legalName : "";
      const invoiceEmail = flagInvoice ? invoiceData.invoiceEmail : "";
      const rfc = flagInvoice ? invoiceData.rfc : "";
      const invoiceAddress = flagInvoice ? invoiceData.invoiceAddress : "";
      const invoiceInt = flagInvoice ? invoiceData.invoiceInt : "";
      const invoiceExt = flagInvoice ? invoiceData.invoiceExt : "";
      const invoiceArea = flagInvoice ? invoiceData.invoiceArea : "";
      const invoiceMunicipality = flagInvoice ? invoiceData.invoiceMunicipality : "";
      const invoiceState = flagInvoice ? invoiceData.invoiceState : "";
      const invoicePostalCode = flagInvoice ? invoiceData.invoicePostalCode : "";
      const cfdi = flagInvoice ? invoiceData.cfdi : "";
      const shippingType = auxShippingType;

      const userId = data.id;

      const res = await paymentWithCard(
        name,
        lastName,
        phone,
        address,
        numExt,
        numInt,
        state,
        municipality,
        area,
        postalCode,
        email,
        legalName,
        invoiceEmail,
        rfc,
        invoiceAddress,
        invoiceInt,
        invoiceExt,
        invoiceArea,
        invoiceMunicipality,
        invoiceState,
        invoicePostalCode,
        cfdi,
        userId,
        tokenCard,
        coupon,
        products,
        paymentMethod,
        rol,
        orderId,
        distribuitorClass,
        shippingType,
        token
      );
      if (res.status1 === 200 && (res.status2 === 200 || res.status2 === 415)) {
        cleanStore();
        toast.success("Compra exitosa, se te enviara un correo.");
        setCompleted(true);
        getAllSales();
        if (purchase) {
          await updatePersonalData(userId, name, lastName, phone, token);
          await updateShipping(userId, address, numExt, numInt, area, municipality, state, postalCode, token);
        }
        if (invoiceSave && flagInvoice) {
          await updateBilling(
            userId,
            legalName,
            rfc,
            invoiceAddress,
            invoiceArea,
            invoiceExt,
            invoiceInt,
            invoiceMunicipality,
            invoiceState,
            invoicePostalCode,
            invoiceEmail,
            token
          );
        }
      }
      if (res.status1 === 400 && res.status2 === 410) {
        toast.warn("El cupon expiro.");
      }
      if (res.status1 === 400 && res.status2 === 411) {
        toast.warn("Cupón sin minimo de compra");
      }
      if (res.status1 === 400 && res.status2 === 412) {
        toast.warn("Un producto se ha agotado");
      }
      if (res.status1 === 400 && res.status2 === 413) {
        toast.warn("Un producto se ha terminado");
      }
      if (res.status1 === 400 && res.status2 === 414) {
        toast.warn("Error al hacer el pago");
      }
    }
  };

  const generateCustomer = async () => {
    const name = userData.name;
    const lastName = userData.lastName;
    const email = data.email;
    const phone = userData.phone;
    const postalCode = userData.postalCode;
    const address = userData.address;
    const state = userData.state;
    const municipality = userData.municipality;

    return await createACustomer(
      name,
      lastName,
      email,
      phone,
      postalCode,
      address,
      state,
      municipality,
      coupon,
      products,
      rol,
      distribuitorClass,
      auxShippingType
    );
  };

  return (
    <div>
      {store.length !== 0 ? (
        <Fragment>
          <Tables
            setACoupon={setCoupon}
            setLocalTotal={setTotal}
            setSell={setSell}
            setAuxShippingType={setAuxShippingType}
          />
          {sell === false && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p style={{ fontSize: 25, fontFamily: "m_semi_bold", margin: "50px 0" }}>
                {"La compra mínima es de 100 productos"}
              </p>
            </div>
          )}
          {completed === false ? sell === true && <Payments optionPayments={setOption} total={total} /> : <Fragment />}
          {token === "" ? (
            <Login />
          ) : completed === false ? (
            <Fragment>
              {sell === true && <FormUser setUserData={setUserData} setPurchase={setPurchase} />}

              {sell === true && (
                <Invoice
                  setInvoiceSave={setInvoiceSave}
                  setFlagInvoice={setFlagInvoice}
                  setInvoiceData={setInvoiceData}
                />
              )}

              <Fragment>
                {option === 0 || option === 3 ? (
                  sell === true && <ButtonPay paymentCart={toPayWithcard} />
                ) : option === 2 ? (
                  <PayPalButton total={total} payment={toPayWithcard} sell={sell} />
                ) : (
                  sell === true && (
                    <ToPay paymentCart={toPayWithcard} customer={generateCustomer} validate={validateFormUser} />
                  )
                )}
              </Fragment>
            </Fragment>
          ) : (
            <Fragment />
          )}
        </Fragment>
      ) : (
        <CartVoid />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  store: state.Store.data,
  token: state.Panel.token,
  data: state.Panel.data,
  products: state.Store.data,
  id: state.Panel.data.id,
  rol: state.Panel.rol,
  distribuitorClass: state.Panel.data.distribuitorClass,
});

export default connect(mapStateToProps, { updateStore, getSales })(Cart);
