import { urlApi, urlPath, get, post } from '../api';

const url = urlApi + urlPath.reviews

export const getData = async (token = '', extra = '') => {
    return await get(url, token, extra)
}

export const postData = async (name, title, idUser, starts, review, token) => {
    const obj = {
        name, title, idUser, starts, review
    }
    return await post(obj, url, token)
}
