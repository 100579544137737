import { TableRow, TableCell } from '@material-ui/core';

import { Ic_check, Ic_uncheck } from 'values';

const Item = ({ shippingType, onClick, value, text }) => {
    return (
        <div style={{ display: 'flex', cursor: 'pointer' }} onClick={() => onClick(value)}>
            <img alt='' src={shippingType === value ? Ic_check : Ic_uncheck} draggable={false} />
            <p style={{ fontSize: 12, fontWeight: 400, marginLeft: 5, userSelect: 'none' }}>{text}</p>
        </div>
    )
}

const TableShipment = ({ title, value, background = '#f2f2f2', color1 = '#000', color2 = '#000', weight = 500, weight2 = 500, fontFamily = 'av_book', shippingType, setShippingType }) => {
    return (
        <TableRow style={{ borderTop: '3px solid #fff' }}>
            <TableCell align='center' style={{ width: '20%', borderBottom: 0 }} />
            <TableCell align='center' style={{ width: '40%', borderBottom: 0 }} />
            <TableCell align='center' style={{ width: '20%', borderBottom: 0 }} />
            <TableCell align='left'
                style={{ width: '20%', paddingLeft: 40, borderBottom: 0, backgroundColor: background, color: color1, textTransform: 'uppercase', fontSize: 18 }} >
                <div>
                    <p style={{ fontWeight: weight, fontFamily: 'm_semi_bold', margin: 0 }}>{title}</p>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Item shippingType={shippingType} onClick={setShippingType} value='SLOW' text='Estandar' />
                        <Item shippingType={shippingType} onClick={setShippingType} value='FAST' text='Rapido' />
                    </div>
                </div>
            </TableCell>
            <TableCell align='center' style={{ padding: 0, borderBottom: 0, backgroundColor: background }}>
                <p style={{ fontWeight: weight2, fontFamily: fontFamily, color: color2 }}>{value}</p>
            </TableCell>
        </TableRow>
    )
}

export default TableShipment;