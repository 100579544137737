import { useState, useEffect, Fragment } from "react";
import { Container } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";

// Redux
import { changeNewPosition } from "redux/actions/PanelAdmin";
import { changeFlagPath } from "redux/actions/Store";

// Components
import MenuDrawer from "./MenuDrawer";
import Login from "./Login";
// Values
import { Logo, Ic_cart, Ic_user, colors, Img_logo_white, Ic_user_white } from "values";
// Styles
import { useStyles } from "style";

const Title = ({ text, path, pathMenu, changePage }) => {
  const classes = useStyles();
  const standBy = () => {};
  return (
    <div style={{ position: "relative", display: "flex", justifyContent: "center" }}>
      <p
        className={classes.path_menu}
        style={{ fontFamily: "m_semi_bold", color: path === pathMenu ? colors.back : colors.purple }}
        onClick={() => changePage(pathMenu)}
      >
        {text}
      </p>
    </div>
  );
};

const TitleHome = ({ text, pathMenu, changePage, style }) => {
  const classes = useStyles();
  const standBy = () => {};
  return (
    <div style={{ position: "relative", display: "flex", justifyContent: "center" }}>
      <p
        className={classes.path_menu}
        style={{ fontFamily: "m_semi_bold", color: style ? colors.purple : "#fff" }}
        onClick={() => changePage(pathMenu)}
      >
        {text}
      </p>
    </div>
  );
};

const Menu = ({ token, store, changeNewPosition, changeFlagPath }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const [path, setPath] = useState(location.pathname.slice(1, location.pathname.length));

  const [offset, setOffset] = useState(0);

  const [open, setOpen] = useState(false);

  const [total, setTotal] = useState(0);

  const changePage = (path) => {
    history.push(`/${path.toLowerCase()}`);
    setPath(path);
  };

  const loginUser = () => {
    if (token) {
      setPath("panel");
      history.push(`/panel`);
    } else {
      setOpen(true);
    }
  };

  useEffect(() => {
    // console.log('location.pathname: ', location.pathname);
    if (location.pathname !== "/reset-password") {
      changePage(location.pathname.slice(1, location.pathname.length));
    }
  }, [location.pathname]);

  useEffect(() => {
    let auxTotal = 0;
    store.forEach((i) => {
      auxTotal += i.total;
    });
    setTotal(() => auxTotal);
  }, [store]);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <div className={`${classes.container} ${offset > 70 ? classes.container_box_shadow : ""}`}>
      <Login open={open} setOpen={setOpen} />
      <div className={classes.container_menu_drawer} style={{ marginBottom: 50 }}>
        <MenuDrawer menuChange={changePage} path={path} login={loginUser} />
      </div>
      <div className={classes.container_menu} id="menu">
        <Container maxWidth="lg">
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <img
              alt=""
              loading="lazy"
              draggable="false"
              src={path === "" ? (offset > 70 ? Logo : Img_logo_white) : Logo}
              style={{ cursor: "pointer", width: 160 }}
              onClick={() => changePage("")}
            />
            <div style={{ display: "flex", alignItems: "center" }}>
              {path === "" ? (
                <Fragment>
                  <TitleHome
                    text={"Qué es"}
                    pathMenu={"que-es"}
                    changePage={changePage}
                    style={offset > 70 ? true : false}
                  />
                  <TitleHome
                    text={"Compra"}
                    pathMenu={"compra"}
                    changePage={changePage}
                    style={offset > 70 ? true : false}
                  />
                  <TitleHome
                    text={"Distribuidoras"}
                    pathMenu={"distribuidoras"}
                    changePage={changePage}
                    style={offset > 70 ? true : false}
                  />
                  <TitleHome
                    text={"Canales Upee"}
                    pathMenu={"canales"}
                    changePage={changePage}
                    style={offset > 70 ? true : false}
                  />
                  <TitleHome
                    text={"Eventos"}
                    pathMenu={"eventos"}
                    changePage={changePage}
                    style={offset > 70 ? true : false}
                  />
                  <TitleHome
                    text={"Instructivo"}
                    pathMenu={"instructivo"}
                    changePage={changePage}
                    style={offset > 70 ? true : false}
                  />
                  <TitleHome
                    text={"Contacto"}
                    pathMenu={"contacto"}
                    changePage={changePage}
                    style={offset > 70 ? true : false}
                  />
                </Fragment>
              ) : (
                <Fragment>
                  <Title text={"Qué es"} pathMenu={"que-es"} path={path} changePage={changePage} />
                  <Title text={"Compra"} pathMenu={"compra"} path={path} changePage={changePage} />
                  <Title text={"Distribuidoras"} pathMenu={"distribuidoras"} path={path} changePage={changePage} />
                  <Title text={"Canales Upee"} pathMenu={"canales"} path={path} changePage={changePage} />
                  <Title text={"Eventos"} pathMenu={"eventos"} path={path} changePage={changePage} />

                  <Title
                    text={"Instructivo"}
                    pathMenu={"instructivo"}
                    path={path}
                    height={40}
                    changePage={changePage}
                  />

                  <Title text={"Contacto"} pathMenu={"contacto"} path={path} changePage={changePage} />
                </Fragment>
              )}
            </div>
            <div style={{ display: "flex", position: "relative" }}>
              <div className={classes.button_card} onClick={() => changePage("carrito")}>
                <img alt="" loading="lazy" draggable="false" src={Ic_cart} style={{ width: 25 }} />
              </div>
              {store.length !== 0 && <div className={classes.count_total_product}>{total}</div>}
              <img
                alt=""
                onClick={() => loginUser()}
                loading="lazy"
                src={path === "" ? (offset > 70 ? Ic_user : Ic_user_white) : Ic_user}
                draggable="false"
                style={{ width: 25, marginLeft: 20, cursor: "pointer" }}
              />
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state.Panel.token,
  store: state.Store.data,
});

export default connect(mapStateToProps, { changeNewPosition, changeFlagPath })(Menu);
