export const routesApi = {
  sandbox: "http://localhost:5001/upee-30b66/us-central1/api/",
  productive: process.env.REACT_APP_API_PRODUCTIVE,
};

export const urlApi = routesApi.productive;

export const urlPath = {
  login: "login/",
  webBanners: "webBanners",
  weSubscribers: "weSubscribers",
  webContacts: "webContacts",
  webUsers: "webUsers",
  webProducts: "webProducts",
  webCoupon: "webCoupon",
  paymentWithCard: "paymentWithCard",
  mySales: "mySales",
  // webAlliances:       'webAlliances',
  createCustomer: "createCustomer",
  distribuitors: "distribuitors",
  postulants: "postulants",
  shipment: "shipment",
  reviews: "reviews",
  pointSales: "pointSales",
  events: "events",
};

const keys_conekta = {
  sandboxDev: "key_D9yPzaSAqRAGNrrJzuSsNaQ",
  sandbox: "key_PRkBkxfhgst0Hwq73Dz1kb",
  productive: "key_UVt4LzlTqLll2NvrkvWDSuN",
};

export const conektaKey = keys_conekta.productive;

export const get = async (url, token, extra = "") => {
  let data = [];
  let flag = false;
  // console.log("URL: ", url+extra);
  const request = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  await fetch(url + extra, request)
    .then(async (res) => {
      if (res.status === 200) {
        flag = true;
        console.log(res.status);
        return res.json();
      } else {
        // console.log(res);
        // if(res.status === 401){
        //     await refreshToken()
        // }
      }
    })
    .then((response) => {
      if (flag === true) {
        data = response;
      }
    })
    .catch(() => {
      flag = false;
    });
  return { data: data, flag: flag };
};

export const post = async (obj, url, token) => {
  const request = {
    method: "POST",
    body: JSON.stringify(obj),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  };
  const res = await fetch(url, request)
    .then(async (res) => {
      if (res.status === 200) {
        return true;
      } else {
        console.log(res);
        // if(res.status === 401){
        //     await refreshToken()
        //     toast.warn("Actualizando Token")
        // }
        return false;
      }
    })
    .catch(() => {
      return false;
    });
  return res;
};

export const update = async (obj, url, id, token) => {
  const request = {
    method: "PATCH",
    body: JSON.stringify(obj),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  };
  const res = await fetch(url + "/" + id, request)
    .then(async (res) => {
      if (res.status === 200) {
        console.log(res.status);
        return true;
      } else {
        console.log(res);
        return false;
      }
    })
    .catch((err) => {
      return false;
    });
  return res;
};
