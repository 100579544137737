import { makeStyles } from '@material-ui/core/styles';

// Values
import { colors } from 'values';

// Style
export const useStyles = makeStyles(theme => ({
    button: {
        backgroundColor: colors.red,
        color: '#fff',
        textTransform: 'uppercase',
        cursor: 'pointer',
        border: 'none',
        borderRadius: 45,
        letterSpacing: 2,
        '&:active': {
            transform: 'scale(0.96)',
        },
    },
    styleLg: {
        width: 250,
        height: 45,
        fontSize: 14,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    styleLg: {
        width: 250,
        height: 45,
        fontSize: 16,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    styleXl: {
        width: 300,
        height: 60,
        fontSize: 20,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    }
}));
const Button2 = ({ text = 'Enter', onAction, style = 'lg' }) => {
    const classes = useStyles()
    const checkStyle = () => {
        let newStyle = `${classes.button}`

        if (style === 'xl') {
            newStyle += ` ${classes.styleXl}`
        } else {
            newStyle += ` ${classes.styleLg}`
        }

        return newStyle
    }
    return (
        <button onClick={onAction} style={{ fontFamily: 'm_medium' }}
            className={checkStyle()}>
            {text}
        </button>
    );
};

export default Button2;