import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Container } from '@material-ui/core';
import { toast } from 'react-toastify';
import queryString from 'query-string'; //https://ui.dev/react-router-v5-query-strings/

import Input from 'components/global/Input';
import Button from 'components/global/Button2';

import { colors } from 'values';
import { resetPassword, handleRecoverEmail } from 'utils/reset-password';

const Main = () => {
    const { search } = useLocation()
    const values = queryString.parse(search)

    const [password1, setPassword1] = useState('')
    const [password2, setPassword2] = useState('')
    const [email, setEmail] = useState('')
    const [expired, setExpired] = useState(false)

    const onSubmit = async (event) => {
        event.preventDefault()

        if (password1 === password2) {
            const res = await resetPassword(values.oobCode, password1)
            if (res) {
                toast.success('Contraseña cambiada')
            } else {
                toast.warn('Parece que surgio un error, vuelve a intentarlo.')
            }
        } else {
            toast.warn('La contraseña tienen que coincidir.')
        }
    }

    const getEmail = async () => {
        const res = await handleRecoverEmail(values.oobCode)
        setEmail(res.email)
        setExpired(res.expired)
    }

    useEffect(() => {
        getEmail()
    }, [])
    return (
        <div style={{ margin: '75px 0 150px' }}>
            <Container maxWidth='sm'>
                <div style={{ textAlign: 'center' }}>
                    <h2 style={{ fontFamily: 'walfords', color: colors.red, fontSize: 60, margin: 0 }}>Cambiar contrasena</h2>
                    <p>Cambia la contraseña de tu correo {email}</p>
                </div>

                {
                    expired === false ?
                        <form onSubmit={onSubmit}>
                            <Input title='Nueva contraseña' placeholder='' value={password1} setValue={setPassword1} requiered={true} />
                            <Input title='Confirmar nueva contraseña' placeholder='' value={password2} setValue={setPassword2} requiered={true} />
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button text='Guardar' />
                            </div>
                        </form>
                        :
                        <div style={{ textAlign: 'center' }}>
                            <p style={{ fontWeight: 600, fontSize: 18 }}>Su solicitud para restablecer su contraseña ha caducado o el enlace ya se ha utilizado.</p>
                        </div>
                }

            </Container>
        </div>
    );
};

export default Main;