import { reduxState } from 'values';

export const getReviews = (data, records, page) => {
    return (dispatch) => {
        dispatch({
            type: reduxState.get_reviews,
            data, records, page
        })
    }
}
