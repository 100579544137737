import { useState } from "react";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";

// values
import { colors, Ic_send } from "values";
import { validateData, postData } from "utils/subscriber";

// styles
export const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",
    maxWidth: 400,
    fontSize: 14,
    backgroundColor: "#fff",
    border: "none",
    paddingLeft: 30,
    marginTop: 20,
    marginBottom: 30,
    height: 38,
    [theme.breakpoints.down("xs")]: {
      maxWidth: "90%",
    },
  },
  image_send: {
    position: "absolute",
    right: 15,
    top: 30,
    cursor: "pointer",
    "&:active": {
      transform: "scale(0.95)",
    },
  },
  title: {
    margin: 0,
    fontSize: 80,
    color: "#fff",
    fontWeight: 400,
    [theme.breakpoints.down("xs")]: {
      fontSize: 50,
    },
  },
}));

const Input = ({ value, setValue, onClick, loading }) => {
  const classes = useStyles();
  const standBy = () => {};
  return (
    <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
      <div style={{ position: "relative", maxWidth: 430, width: "100%" }}>
        <input
          className={classes.input}
          type="email"
          placeholder="Email"
          style={{ fontFamily: "m_light" }}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <img alt="" src={Ic_send} className={classes.image_send} onClick={loading ? standBy : onClick} />
      </div>
    </div>
  );
};

const Subscribe = () => {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const cleanData = () => {
    setLoading(false);
    setEmail("");
  };

  const addSubscriber = async () => {
    setLoading(true);
    const res1 = validateData(email);
    if (res1.flag) {
      const res2 = await postData(email);
      if (res2) {
        toast.success("Suscripción exitosa");
      } else {
        toast.warn("Por favor verifica que tus datos esten correctos.");
      }
      setTimeout(() => cleanData(), 1000);
    } else {
      setLoading(false);
      toast.warn(res1.message);
    }
  };

  return (
    <div style={{ width: "100%", background: colors.red }}>
      <Container>
        <div style={{ textAlign: "center", padding: "50px 0 25px" }}>
          <h2 className={classes.title} style={{ fontFamily: "walfords" }}>
            {"Suscribete"}
          </h2>
          <p style={{ fontSize: 14, margin: 0, color: "#fff", fontFamily: "m_regular" }}>
            {"Suscríbete para no perder promociones ni descuentos y estar al tanto de las últimas noticias."}
          </p>
          <Input value={email} setValue={setEmail} onClick={addSubscriber} loading={loading} />
        </div>
      </Container>
    </div>
  );
};

export default Subscribe;
