import { useHistory } from 'react-router-dom';
import { useState } from 'react'
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Button from 'components/global/Button';

import { colors, Img_girl_a, Img_girl_b, Img_near, Background_near } from 'values';

export const useStyles = makeStyles(theme => ({
    button:{
        color:'#fff',
        textTransform:'uppercase',
        height:80, width:400,
        cursor:'pointer',
        border:'none',
        fontSize:16,
        fontWeight:600,
        letterSpacing:7,
        background: colors.red,
        marginTop:20,
        '&:active':{
            transform: 'scale(0.96)',
        },
        [theme.breakpoints.down('xs')]:{
            width:'100%',
        },
    },
    text:{
        color:colors.red,
        textTransform:'uppercase', letterSpacing:6,
        paddingTop:15,
    },
    image_girl1:{
        position:'absolute', zIndex:1, left:'15%', width:300, top:50,
        [theme.breakpoints.down('lg')]:{
            width:250,
        },
        [theme.breakpoints.down('sm')]:{
            left:100,
            width:200,
        },
        [theme.breakpoints.down('xs')]:{
            width:100,
            top:130,
            left:45,
        },
    },
    image_girl2:{
        position:'absolute', right:'23%', top:50, width:300,
        [theme.breakpoints.down('lg')]:{
            right:'20%',
            width:250,
        },
        [theme.breakpoints.down('sm')]:{
            right:100,
            width:200,
        },
        [theme.breakpoints.down('xs')]:{
            width:100,
            top:130,
            right:45,
        },
    },
    background:{
        padding:'70px 0',
        backgroundImage:`url(${Background_near})`,
        backgroundColor:colors.pink,
        backgroundRepeat:'no-repeat',
        backgroundSize:'cover',
        margin:'80px 0', position:'relative', zIndex:2
    }
}));

const NearYou = () => {
    const classes = useStyles()
    const history = useHistory ()

    const [transform1, setTransform1] = useState('translateX(0px) translateY(0px) scale(1)')
    const [transform2, setTransform2] = useState('translateX(0px) translateY(0px) scale(1)')

    const handleMove = (e) =>{
        let x1 = -(e.clientX/20 - e.clientX/15)
        let y1 = (e.clientY/20 - e.clientY/10) + 50

        let x2 = (e.clientX/20 - e.clientX/15)
        let y2 = -(e.clientY/50 - e.clientY/10) + 10

        let newMatrix1 = `translateX(${x1}px) translateY(${y1}px) scale(1.1)`
        let newMatrix2 = `translateX(${x2}px) translateY(${y2}px) scale(1.1)`

        setTransform1(newMatrix1)
        setTransform2(newMatrix2)
    }

    const handleClear = () =>{
        let newMatrix = `translateX(0px) translateY(0px) scale(1)`
        setTransform1(newMatrix)
        setTransform2(newMatrix)
    }

    const handleClick = () =>{
        history.push('/compra')
    }
    return (
        <div className={classes.background} onMouseMove={handleMove} onMouseOut={handleClear}>
            {/* <img alt='' src={Background_near} /> */}
            <Container maxWidth='xl' style={{position:'relative', paddingTop:200}}>
                <img alt='' src={Img_girl_a} className={classes.image_girl1} style={{transform:transform1}} />
                <img alt='' src={Img_girl_b} className={classes.image_girl2} style={{transform:transform2}} />
                <img alt='' src={Img_near} style={{position:'relative', zIndex:0, width:'100%'}} />
            </Container>
            <div style={{display:'flex', justifyContent:'center', paddingTop:50}}>
                <Button onClick={handleClick} text='Comprar' width={300} height={80} background={colors.red} />
            </div>
            <div style={{display:'flex', justifyContent:'center', textAlign:'center'}}>
                <p className={classes.text} style={{fontFamily:'m_medium'}}>{'Envíos a todo México'}</p>
            </div>
        </div>
    );
};

export default NearYou;