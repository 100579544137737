import { makeStyles } from '@material-ui/core/styles';

import { colors, Ic_change_option } from 'values';

export const useStyles = makeStyles(theme => ({
    container_panel_menu_desktop:{
        display:'flex',
        [theme.breakpoints.down('md')]:{
            display:'none',
        },
    },
    bar_menu:{
        width:400, background:colors.primary, height:825, 
        borderRadius:20, left:30,
        flexWrap:'wrap', alignItems:'flex-start'
    },
    input:{
        width:'calc(100% - 10px)',
        fontSize:16, backgroundColor:'#f7f7f7',
        border:'none', borderRadius:5,
        paddingLeft:10, marginTop:10,
        marginBottom:10, height:50,
    },
    container_panel_menu_options:{
        marginTop:0,
        [theme.breakpoints.down('md')]:{
            marginLeft:0,
        },
    },
    button_edit:{
        cursor:'pointer',
        '&:active':{ transform: 'scale(0.96)' },
    },
    button:{
        backgroundColor:'#000',
        color:'#fff',
        textTransform:'uppercase',
        width:180,
        height:60,
        cursor:'pointer',
        border:'none',
        borderRadius:13,
        fontSize:20,
        fontWeight:600,
        '&:active':{
            transform: 'scale(0.96)',
        },
    },
    menu_button:{
        width:'100%', textAlign:'center', cursor:'pointer', height:50, 
        display:'flex',alignItems:'center', justifyContent:'center',
    },
    menu_text:{
        color:'#fff', fontWeight:600, fontSize:16, userSelect:'none', margin:0, 
    },
    input_select:{
        width:'100%', fontSize:16,
        background: `url(${Ic_change_option}) 97.5% / 10px no-repeat #f7f7f7`,
        appearance:'none', border:'none',
        borderRadius:5, paddingLeft:8,
        marginTop:10, marginBottom:10,
        height:52,
    },
    camera:{
        background:'#2153a4', width:45, height:45, 
        display:'flex', alignItems:'center', 
        justifyContent:'center', borderRadius:12, 
        position:'absolute', right:45, bottom:5, 
        cursor:'pointer'
    },
    button_modal:{
        border:'none',
        borderRadius:9,
        width: 100,
        height:40,
        boxShadow:'0 6px 10px 0 rgba(6,24,77,0.09)',
        cursor:'pointer',
        fontSize:15,
        backgroundColor:'#fff',
    },
    my_records_title:{
        color:'#fff', margin:0, fontSize:18, fontWeight:600, textTransform:'uppercase',
        [theme.breakpoints.down('xs')]:{
            fontSize:13,
        },
    },
    info_text_record:{
        display:'none',
        [theme.breakpoints.down('xs')]:{
            display:'block',
            margin:0,
            paddingTop:10
        },
    },
    space_records:{
        [theme.breakpoints.down('xs')]:{
            height:100,
        },
    },
    container_panel_menu_mobile:{
        display:'none',
        [theme.breakpoints.down('md')]:{
            display:'block',
        },
    },
}));