import {reduxState}  from 'values';

export const getProducts = (products, top) =>{
    return(dispatch) =>{
        dispatch({
            type: reduxState.get_products,
            products, top
        })
    }
}
