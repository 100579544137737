import { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    input_area: {
        width: 'calc(100% - 10px)', fontSize: 16,
        backgroundColor: '#f7f7f7', border: 'none', paddingLeft: 10,
        paddingTop: 10, marginTop: 10, marginBottom: 10, height: 200, resize: 'none',
    },
    input: {
        width: 'calc(100% - 10px)', fontSize: 16,
        border: 'none', borderRadius: 5,
        paddingLeft: 10, marginTop: 10, marginBottom: 10, height: 50,
    },
}));

const Input = ({ title = 'Title', value, setValue, placeholder = 'Placeholder', requiered = false, type = 'text', bg = '#f7f7f7', color = '#000', titleW = 600, areaH = 200 }) => {
    const classes = useStyles()
    return (
        <Fragment>
            <label style={{ fontWeight: titleW, margin: 0, marginLeft: 15, fontFamily: 'm_semi_bold' }}>{title}</label>
            {
                requiered &&
                <Fragment>
                    <label style={{ fontWeight: 600, margin: 0, color: '#ff1771', marginLeft: 5 }}>{'*'}</label>
                </Fragment>
            }
            <br />
            {
                type === 'area' ?
                    <textarea value={value} onChange={e => setValue(e.target.value)} style={{ height: areaH, fontFamily: 'm_regular' }}
                        className={classes.input_area} placeholder={placeholder} />
                    :
                    <input className={classes.input}
                        value={value} onChange={e => setValue(e.target.value)}
                        type={type} style={{ fontFamily: 'm_regular', backgroundColor: bg, color: color }}
                        placeholder={placeholder} required={requiered} />
            }
        </Fragment>
    );
};

export default Input;