import { useEffect, useState } from 'react';
import { Container, Grid } from '@material-ui/core';
import { connect } from 'react-redux';

// Components
import Input from './Input';

// Values
import { colors, Ic_uncheck, Ic_check } from 'values';

// Styles
import { useStyles }  from './styles';

const FormUser = ({data, setUserData, setPurchase}) => {
    const classes = useStyles()

    const [name, setName]           = useState('')
    const [lastName, setLastName]   = useState('')
    const [phone, setPhone]         = useState('')
    const [address, setAddress]     = useState('')
    const [numExt, setNumExt]       = useState('')
    const [numInt, setNumInt]       = useState('')
    const [state, setState]         = useState('')
    const [municipality, setMun]    = useState('')
    const [area, setArea]           = useState('')
    const [postalCode, setPostalC]  = useState('')

    const [saveData, setSaveData]   = useState(true)

    const purchase = (val) =>{
        setPurchase(val)
        setSaveData(val)
    }

    useEffect(()=>{
        if(data){
            const obj = {
                name:data.name, lastName:data.lastName, phone:data.phone,
                address:data.address, numExt:data.numExt, numInt:data.numInt,
                state:data.state, municipality:data.municipality, area:data.area,
                postalCode:data.postalCode
            }
            setUserData(obj)
            setName(data.name)
            setLastName(data.lastName)
            setPhone(data.phone)
            setAddress(data.address)
            setNumExt(data.numExt)
            setNumInt(data.numInt)
            setState(data.state)
            setMun(data.municipality)
            setArea(data.area)
            setPostalC(data.postalCode)
        }
    }, [data])
    useEffect(()=>{
        const obj = {
            name, lastName, phone, address, numExt, numInt, state, municipality, area, postalCode
        }
        setUserData(obj)
    },[name, lastName, phone, address, numExt, numInt, state, municipality, area, postalCode])

    return (
        <div className={classes.container_payments2} style={{paddingTop:25, paddingBottom:25}}>
            <div className={classes.container_method_payments4} style={{background:colors.back}}>
                <div style={{textAlign:'end', lineHeight:1, marginRight:30}}>
                    <p style={{margin:0, color:colors.front, fontSize:110, fontFamily:'m_extra_bold'}}>{'2'}</p>
                    <p style={{margin:0, color:'#fff', fontSize:60, fontFamily:'m_extra_bold'}}>{'DATOS DE'}</p>
                    <p style={{margin:0, color:'#fff', fontSize:60, fontFamily:'m_extra_bold'}}>{'ENVIO'}</p>
                </div>
            </div>
            <div className={classes.container_method_payments6}>
                <div className={classes.content_method_form}>
                    <div style={{height:30}} />
                    <Container>
                        <Input title='Nombre' value={name} setValue={setName} />
                        <Input title='Apellido' value={lastName} setValue={setLastName} />
                        <Input title='Teléfono' value={phone} setValue={setPhone} />
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <Input title='Calle' value={address} setValue={setAddress} />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <div className={classes.margin_input}>
                                    <Input title='Num Ext' value={numExt} setValue={setNumExt} />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <div className={classes.margin_input}>
                                    <Input title='Num Int' value={numInt} setValue={setNumInt} />
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <Input title='Estado' value={state} setValue={setState} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div className={classes.margin_input}>
                                    <Input title='Municipio' value={municipality} setValue={setMun} />
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <Input title='Colonia' value={area} setValue={setArea} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div className={classes.margin_input}>
                                    <Input title='Cp' value={postalCode} setValue={setPostalC} />
                                </div>
                            </Grid>
                        </Grid>
                        <div onClick={()=>purchase(!saveData)}  style={{cursor:'pointer', display:'flex', alignItems:'center', maxWidth:350, margin:'10px 0'}}>
                            <img alt='' src={saveData?Ic_check:Ic_uncheck} draggable='false' style={{paddingRight:10, width:17}} />
                            <p style={{margin:0, userSelect:'none', fontFamily:'m_semi_bold'}}>{'Guardar datos para siguiente compra'}</p>
                        </div>
                        <div style={{height:30}} />
                    </Container>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state =>({
    data: state.Panel.data,
})

export default connect(mapStateToProps, {})(FormUser);