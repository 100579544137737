import Carousel from "react-pictures-carousel";
import { Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Values
import {
  Gallery_a_min,
  Gallery_b_min,
  Gallery_c_min,
  Gallery_d_min,
  Gallery_e_min,
  Gallery_f_min,
  Gallery_g_min,
  Icon_ig,
} from "values";
import { colors } from "values/index";

export const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 80,
    margin: 0,
    paddingBottom: 30,
    fontWeight: 400,
    color: colors.purple,
    [theme.breakpoints.down("sm")]: {
      fontSize: 55,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 40,
    },
  },
  container_image: {
    width: "100%",
    height: "100%",
    position: "relative",
    "&:hover": {
      "& #icon": {
        position: "absolute",
        zIndex: 2,
        bottom: "50%",
        left: "50%",
        transform: "translate(-50%, 50%)",
        cursor: "pointer",
        opacity: 1,
        transition: "0.5s ease-out",
      },
      "& #card": {
        background: "#000",
        opacity: 0.5,
        transition: "0.5s ease-out",
      },
    },
    "& div": {
      width: "100%",
      height: "100%",
      transition: "0.5s ease- out",
      position: "absolute",
      top: 0,
    },
    "& #icon": {
      position: "absolute",
      zIndex: 2,
      bottom: "0%",
      left: "50%",
      transform: "translate(-50%, 50%)",
      cursor: "pointer",
      opacity: 0,
    },
  },
}));

const TravelAndUpee = () => {
  const classes = useStyles();

  const handleClick = () => {
    window.open("https://www.instagram.com/upeeoficial/");
  };

  const list = [
    Gallery_a_min,
    Gallery_b_min,
    Gallery_c_min,
    Gallery_d_min,
    Gallery_e_min,
    Gallery_f_min,
    Gallery_g_min,
    Gallery_a_min,
  ];

  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <h2 className={classes.title} style={{ fontFamily: "walfords" }}>
          {"Siguenos en Instagram"}
        </h2>
      </div>
      <Grid container>
        {list.map((i, j) => (
          <Grid key={`travel-and-upee-list-${j}`} item xs={12} sm={6} md={4} lg={3}>
            <div className={classes.container_image}>
              <div id="card" />
              <img alt="" src={i} style={{ width: "100%", height: "100%", objectFit: "cover" }} draggable="false" />
              <img alt="" id="icon" src={Icon_ig} draggable="false" onClick={handleClick} />
            </div>
          </Grid>
        ))}
      </Grid>
      {/* <Carousel pictures={list} icon={Icon_ig} action={handleClick} /> */}
    </div>
  );
};

export default TravelAndUpee;
