import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { colors } from 'values';

export const useStyles = makeStyles(theme => ({
    container_image: {
        width: 170, height: 170, background: colors.silver, borderRadius: '50%', position: 'relative',
        top: 40, left: '50%', transform: 'translate(-50%, 0%)',
    },
    image_number: {
        width: 60, height: 60, top: 40, position: 'absolute'
    },
    container: {
        width: 'calc(100% - 30px)', marginBottom: 50, height: 425,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    }
}));

const Card = ({ text, image, bg }) => {
    const classes = useStyles()
    return (
        <div className={classes.container} style={{ background: bg }}>
            <Container>
                <div style={{ position: 'relative', height: 250 }}>
                    <div className={classes.container_image}>
                        <img alt='' src={image} />
                    </div>
                </div>
                <div style={{ textAlign: 'center', padding: 30, paddingBottom: 70 }}>
                    <p style={{ margin: 0, fontSize: 20, fontFamily: 'av_book', color: '#fff' }}>{text}</p>
                </div>
            </Container>
        </div>
    );
};

export default Card;