import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

// Redux
import { updateUser } from 'redux/actions/PanelAdmin';

// Components
import Input            from './Input';
import Button           from './Button';
import ContainerCard    from './ContainerCard';
import Title            from './Title';

// Values
import { toastText } from 'values';

// Api
import { updatePersonalData } from 'utils/users';

const Profile = ({data, updateUser, trigger, setTrigger}) => {
    const [blockData, setBlockData] = useState('disabled')
    const [name, setName]           = useState('')
    const [lastName, setLastName]   = useState('')
    const [phone, setPhone]         = useState('')
    const [loading, setLoading]     = useState(false)

    const updateData = async() =>{
        setLoading(true)
        const res = await updatePersonalData(data.id, name, lastName, phone)
        if(res){
            setBlockData('disabled')
            setLoading(false)
            toast.success(toastText.update)
            localStorage.setItem('upee-name', name)
            localStorage.setItem('upee-lastname', lastName)
            updateUser(name, lastName)
            setTrigger(()=>(trigger+1))
        } else{
            toast.warn(toastText.warning)
        }
        setLoading(false)
    }

    useEffect(()=>{
        setName(data.name)
        setLastName(data.lastName)
        setPhone(data.phone)
    },[data])
    return (
        <ContainerCard>
            <Title text='Personales' />
            {/* <p style={{margin:0, fontWeight:600, fontSize:20, marginBottom:25, paddingTop:15, fontFamily:'m_semi_bold'}}>{'Personales'}</p> */}
            <div style={{padding:'0 10px'}}>
                <Input title={'Nombre'} value={name} setValue={setName} blockData={blockData} placeholder={''} />
                <Input title={'Apellido'} value={lastName} setValue={setLastName} blockData={blockData}placeholder={''} />
                <Input title={'Teléfono'} value={phone} setValue={setPhone} blockData={blockData} placeholder={''} />
            </div>
            <div style={{height:20}} />
            <Button blockData={blockData} 
                setBlockData={()=>setBlockData('')}
                onClick={updateData} loading={loading} />
            <div style={{height:20}} />
        </ContainerCard>
    );
};

const mapStateToProps = state =>({
    data: state.Panel.data
})

export default connect(mapStateToProps, {updateUser})(Profile)