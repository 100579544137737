import { useEffect, useState } from "react";

import PayPalExpressButton from "react-paypal-express-checkout";
import { toast } from "react-toastify";
import { Container } from "@material-ui/core";

import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";

const PayPalButton = ({ total, payment, sell }) => {
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [orderId, setOrderId] = useState(false);

  const env = "production";
  const currency = "MXN";

  const client = {
    sandboxDev: "ASPZ5-kNj3fyXEU33ewmv-ikvsOa4UyWCTW7Zw4AIDF7EfPAXjiVS3-Qw5sVAz0nXgNWl-2Wg0vN1-MG",
    productionDev: "AXL2bGd7SlJujFUEXdRAHT_4riCCGZgxOZ9giHdGIslubHOGAzztBuTAy870q5PHZe_ddTn0H0TMj578",
    sandbox: "AWoeTGXOm6SSaysXBmVcBY-huJ-zC4F-YGzppfBMdEDMjPkRabo3PvCRO5_1D_z7E9P0UF-Oo6EVzcmb",
    production: "AUsz05ef1xIznRk5-Cd0DoifC1E2VrfuCaZ577qrGXHiAcUB9LrnAjCE8IwTzaHPKIFoN2GKhqk4VwPi",
  };

  const onSuccess = () => {
    console.log("Pago exitoso");
    // toast.success('Pago exitoso, se te enviara un correo.')
    payment();
  };
  const onCancel = () => {
    console.log("Pago cancelado");
    toast.error("El pago fue cancelado");
  };

  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            amount: {
              currency_code: "MXN",
              value: total,
            },
          },
        ],
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then((orderID) => {
        setOrderId(orderID);
        return orderID;
      });
  };

  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;
      setSuccess(true);
      payment();
    });
  };

  const onError = (data, actions) => {
    console.log("data: ", data);

    setErrorMessage("An error occured with your payment");
    toast.warn("Ocurrió un error al procesar tu pago. Por favor intentalo de nuevo más tarde");
  };

  return (
    <div style={{ textAlign: "center", paddingBottom: 50, display: sell === true ? "block" : "none" }}>
      <Container maxWidth="xl">
        <div style={{ maxWidth: 500, display: "block", margin: "0 auto" }}>
          <PayPalScriptProvider
            options={{
              "client-id": client.production,
              components: "buttons",
              currency: "MXN",
            }}
          >
            <PayPalButtons
              style={{ layout: "vertical" }}
              fundingSource="paypal"
              createOrder={createOrder}
              onApprove={onApprove}
              onError={onError}
            />
          </PayPalScriptProvider>
        </div>
      </Container>
    </div>
  );
};

export default PayPalButton;
