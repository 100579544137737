import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { colors, Ic_health, Ic_innovate, Ic_mexican, Ic_practice, Ic_reuse, Ic_save, Ic_social } from "values";

export const useStyles = makeStyles((theme) => ({
  image: {
    margin: 20,
    [theme.breakpoints.down("sm")]: {
      margin: 15,
    },
    [theme.breakpoints.down("xs")]: {
      margin: 20,
    },
  },
  title1: {
    fontSize: 80,
    margin: 0,
    color: colors.purple,
    fontWeight: 400,
    [theme.breakpoints.down("xs")]: {
      fontSize: 40,
    },
  },
  container1: {
    paddingTop: 150,
    paddingBottom: 100,
    [theme.breakpoints.down("xs")]: {
      paddingTop: 30,
      paddingBottom: 50,
    },
  },
}));

const Info = () => {
  const classes = useStyles();
  return (
    <div className={classes.container1}>
      <Container>
        <div style={{ textAlign: "center" }}>
          <h2 className={classes.title1} style={{ fontFamily: "walfords" }}>
            {"Upee es diferente..."}
          </h2>
          <p style={{ color: colors.purple, fontFamily: "m_medium", fontSize: 18, lineHeight: 1.2 }}>
            {"Upee es una marca 100% Mexicana, registrada y avalada ante COFEPRIS."}
          </p>
          <p style={{ color: colors.purple, fontFamily: "m_medium", fontSize: 18, lineHeight: 1.2 }}>
            {
              "Nos preocupamos  por  mantener los más altos estándares de calidad en nuestros productos, es por ello que contamos con presencia mundial, esto gracias a la calidad y cuidado con que se realizacada dispositivo."
            }
          </p>
        </div>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "start", flexWrap: "wrap" }}>
          <img alt="" className={classes.image} src={Ic_reuse} />
          <img alt="" className={classes.image} src={Ic_practice} />
          <img alt="" className={classes.image} src={Ic_health} />
          <img alt="" className={classes.image} src={Ic_save} />
          <img alt="" className={classes.image} src={Ic_innovate} />
          <img alt="" className={classes.image} src={Ic_mexican} />
          <img alt="" className={classes.image} src={Ic_social} />
        </div>
      </Container>
    </div>
  );
};

export default Info;
