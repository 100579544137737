import { Parallax } from 'react-parallax';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Container } from '@material-ui/core';

import Button from 'components/global/Button';

import { Img_partner, colors, Ic_health, Ic_innovate, Ic_mexican,  Ic_practice, Ic_reuse, Ic_save, Ic_social  } from 'values';

export const useStyles = makeStyles(theme => ({
    title:{
        margin:0, fontSize:40, color:'#fff', fontWeight:700
    },
    subtitle:{
        margin:0, fontSize:27, color:'#fff',
    },
    button:{
        color:'#fff',
        textTransform:'uppercase',
        height:80, width:300,
        cursor:'pointer',
        border:'none',
        fontSize:16,
        fontWeight:600,
        letterSpacing:7,
        background: colors.red,
        marginTop:20,
        '&:active':{
            transform: 'scale(0.96)',
        },
    },
    container:{
        padding:100, width:'50%',
        display:'flex', alignItems:'center',
        [theme.breakpoints.down('lg')]:{
            padding:50,
        },
        [theme.breakpoints.down('md')]:{
            padding:20,
        },
        [theme.breakpoints.down('xs')]:{
            // padding:10,
            width:'100%',
            justifyContent:'center'
        },
    },
    container_primary:{
        height:700,
        display:'flex', alignItems:'center',
        // [theme.breakpoints.up('xl')]:{
        //     height:800
        // },
    },
    image:{
        width:'60%',
        height:'100%',
        minHeight:366,
        objectFit:'cover', position:'relative',
        [theme.breakpoints.down('xs')]:{
            width:'100%',
        },
    },
    container_info:{
        width:'100%', display:'flex', background:colors.red,
        [theme.breakpoints.down('xs')]:{
            flexWrap:'wrap'
        },
    }
}));

const ParallaxBanner = () => {
    const classes = useStyles()
    const history = useHistory ()

    const handleClick = () =>{
        history.push('/registro')
    }
    return (
        <div style={{padding:'70px 0'}}>
            {/* <Parallax strength={800} bgImage={Img_delivery_min}>
                <div className={classes.container_primary}>
                    <div className={classes.container}>
                        <p className={classes.title} style={{fontFamily:'m_semi_bold'}}>{'¿Quieres ser distribuidora?'}</p>
                        <p className={classes.subtitle} style={{fontFamily:'av_book'}}>{'Vende y promociona Upee en tu localidad'}</p>
                        <Button onClick={handleClick} text='Más información' width={300} height={80} background={colors.red} />
                    </div>
                </div>
            </Parallax> */}

            <div className={classes.container_info}>
                <img alt='' src={Img_partner} className={classes.image} />
                <div className={classes.container}>
                    <div>
                        <p className={classes.title} style={{fontFamily:'m_semi_bold'}}>{'¿Quieres ser distribuidora?'}</p>
                        <p className={classes.subtitle} style={{fontFamily:'av_book'}}>{'Vende y promociona Upee en tu localidad'}</p>
                        <Button onClick={handleClick} text='Más información' width={300} height={80} background='#fff' color={colors.red} />
                    </div>
                </div>
            </div>

            <Container>
                <div style={{textAlign:'center', margin:'60px 0'}}>
                    <p style={{fontFamily:'m_medium'}}>{'En muchas partes del mundo y sobre todo en Europa las mujeres llevan años usando dispositivos para orinar, te invitamos a que lo pruebes y descubras por ti misma todos los beneficios.'}</p>
                </div>
            </Container>

            <Container maxWidth='xl'>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center', flexWrap:'wrap'}}>
                    <img alt='' src={Ic_reuse} />
                    <img alt='' src={Ic_practice} />
                    <img alt='' src={Ic_health} />
                    <img alt='' src={Ic_save} />
                    <img alt='' src={Ic_innovate} />
                    <img alt='' src={Ic_mexican} />
                    <img alt='' src={Ic_social} />
                </div>
            </Container>
        </div>
    );
};

export default ParallaxBanner;