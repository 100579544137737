import { Container } from '@material-ui/core';

const ContainerCard = ({children}) => {
    return (
        <div style={{boxShadow:'0px 0px 10px -4px rgba(0,0,0,0.35)', borderRadius:15, width:'100%'}}>
            <Container>
                {children}
            </Container>
        </div>
    );
};

export default ContainerCard;