import { Fragment } from 'react';
import ReactLoading from "react-loading";
import { makeStyles } from '@material-ui/core/styles';

import { colors } from 'values';

export const useStyles = makeStyles(theme => ({
    button_payment:{
        backgroundColor:'#2ed37c',
        color:'#fff',
        textTransform:'uppercase',
        width:'100%',
        height:60,
        cursor:'pointer',
        border:'none',
        // borderRadius:13,
        fontSize:20,
        fontWeight:600,
        letterSpacing:4,
        '&:active':{
            transform: 'scale(0.96)',
        },
    },
}));

const ButtonPayment = ({text='PAGAR', onClick, loading, backgroundColor=colors.yellow}) => {
    const classes = useStyles()
    return (
        <Fragment>
            <button className={classes.button_payment} onClick={onClick} style={{position:'relative', fontFamily:'m_medium', backgroundColor:backgroundColor}}>
                {
                    loading? 
                        <div style={{position:'absolute', top:'50%', left:'50%', transform:'translate(-10%, -50%)'}}>
                            <ReactLoading type={"bars"} color={"white"} style={{fill:'#fff', height:60, width:60}} />
                        </div>
                    :
                        text
                }
            </button>
        </Fragment>
    );
};

export default ButtonPayment;