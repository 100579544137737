import { reduxState } from 'values';

const initialState = {
    events: [],
    pointsales: []
};

const reducer = (state = initialState, action) => {
    if (action.type === reduxState.get_pointsales) {
        return {
            ...state,
            pointsales: action.data,
        }
    }
    if (action.type === reduxState.get_events) {
        return {
            ...state,
            events: action.data,
        }
    }
    return state
}
export default reducer;