import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

// Components
import Input            from './Input';
import Select           from './Select';
import Button           from './Button';
import ContainerCard    from './ContainerCard';
import Title            from './Title';
// Values
import { toastText } from 'values';
import geographyObject from 'values/mexico.json';

// Api
import { updateDistribuitors } from 'utils/users';

const countrys = ['México', 'Estados Unidos', 'Argentina', 'Europa', 'Colombia', 'Puerto Rico']

const Distribuitor = ({data}) => {
    const [blockData, setBlockData] = useState('disabled')
    const [loading, setLoading]     = useState(false)

    const [distribuitorCountry, setCountry] = useState('')
    const [distribuitorState, setState]     = useState('')
    const [distribuitorAddress, setAddress] = useState('')
    const [distribuitorEmail, setEmail]     = useState('')
    const [distribuitorPhone, setPhone]     = useState('')
    const [distribuitorNotes, setNotes]     = useState('')

    const [states, setListStates] = useState([])

    const mapStates = () =>{
        let aux = []
        try {
            geographyObject.objects.MEX_adm1.geometries.forEach(i => {
                aux.push(i.properties.NAME_1)
            });
        } catch (error) { }
        setListStates(aux)
    }

    const setCountryAndCleanState = (v) =>{
        setState('')
        setCountry(v)
    }

    const updateData = async() =>{
        setLoading(true)
        const res = await updateDistribuitors(data.id, distribuitorCountry, distribuitorState, distribuitorAddress, distribuitorEmail, distribuitorPhone, distribuitorNotes)
        if(res){
            setBlockData('disabled')
            setLoading(false)
            toast.success(toastText.update)
        } else{
            toast.warn(toastText.warning)
        }
        setLoading(false)
    }

    useEffect(()=>{
        setCountry(data.distribuitorCountry)
        setState(data.distribuitorState)
        setAddress(data.distribuitorAddress)
        setEmail(data.distribuitorEmail)
        setPhone(data.distribuitorPhone)
        setNotes(data.distribuitorNotes)
    },[data])

    useEffect(()=>{
        mapStates()
    },[])
    return (
        <ContainerCard>
            <Title text='Dónde entregas como distribuidor' />
            <Select title='Páis' placeholder='Selecciona el país' options={countrys}
                value={distribuitorCountry} setValue={setCountryAndCleanState} 
                blockData={blockData}/>

            {
                distribuitorCountry==='México'?
                    <Select title='Páis' placeholder='Selecciona el estado' options={states}
                        value={distribuitorState} setValue={setState} blockData={blockData}/>
                :
                    <Input title={'Estado'} value={distribuitorState} setValue={setState} blockData={blockData} placeholder={''} />
            }
            
            <Input title={'Dirección'} value={distribuitorAddress} setValue={setAddress} blockData={blockData} placeholder={''} />
            <Input title={'Email'} value={distribuitorEmail} setValue={setEmail} blockData={blockData} placeholder={''} />
            <Input title={'Teléfono'} value={distribuitorPhone} setValue={setPhone} blockData={blockData} placeholder={''} />
            <Input title={'Nota'} value={distribuitorNotes} setValue={setNotes} blockData={blockData} placeholder={''} />

            <div style={{height:20}} />
            <Button blockData={blockData} 
                setBlockData={()=>setBlockData('')}
                onClick={updateData} loading={loading} />
            <div style={{height:20}} />
        </ContainerCard>
    );
};

const mapStateToProps = state =>({
    data: state.Panel.data
})

export default connect(mapStateToProps, {})(Distribuitor)