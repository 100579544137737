import { useState, useEffect, Fragment } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import uniqid from "uniqid";

// Redux
import { getProducts } from "./redux/actions/Products";
import { getBanners } from "./redux/actions/Banners";
import { getReviews } from "./redux/actions/Reviews";
import { getPointSales, getEvents } from "./redux/actions/Events";
import { refreshToken, getUser, saveToken, getSales, getShipments } from "./redux/actions/PanelAdmin";

// Firebase
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { app } from "./firebase";

import Menu from "components/global/Menu";
import Home from "components/home/Home";
import Footer from "components/global/Footer";
import AboutUs from "components/aboutUs/AboutUs";
import Contact from "components/contact/Contact";
import Use from "components/Use/Use.js";
import Panel from "components/panel/Panel";
import Dealers from "components/dealers/Dealers";
import Shopping from "components/shopping/Shopping";
import Cart from "components/cart/Cart";
import Registration from "components/registration/Registration";
import LoadingScreen from "components/global/LoadingScreen";
import PrivacyPolicies from "components/privacyPolicies/PrivacyPolicies";
import Events from "components/events/Events";
import TermsAndConditions from "components/terms-and-conditions/Main";
import ResetPassword from "components/reset-password/Main";
import Channels from "components/channels/Main";
import Loading from "components/global/Loading";
import ScrollToTop from "components/global/ScrollToTop";

// Api
import { getData as getProductsInfo } from "utils/products";
import { getData as getReviewsInfo } from "utils/reviews";
import { getData as getBannersInfo } from "utils/banners";
import { getData as getUserInfo, getMySales } from "utils/users";
import { getData as getShipmentsInfo } from "utils/shipment";
import { getData as getPointSalesInfo } from "utils/pointsales";
import { getData as getEventsInfo } from "utils/events";

// Styles
import "./App.css";
import "./fonts/font.css";

const Default = () => {
  return <div></div>;
};

function App({
  getProducts,
  getBanners,
  refreshToken,
  getUser,
  saveToken,
  getSales,
  getShipments,
  getReviews,
  getPointSales,
  getEvents,
}) {
  const [auxProducts, setAuxProducts] = useState([]);
  const [auxBanner, setAuxBanner] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const getInfo = async () => {
    const resBanners = await getBannersInfo();
    getBanners(resBanners.data.data);
    setAuxBanner(resBanners.data.data);
  };

  const getInfoProducts = async () => {
    const resProducts = await getProductsInfo();
    try {
      setAuxProducts(resProducts.data.data);
      getProducts(resProducts.data.data, []);
    } catch (error) {}
  };

  const getInfoShipments = async () => {
    const res = await getShipmentsInfo();
    getShipments(res.data.data);
  };

  const getInfoPointSales = async () => {
    const res = await getPointSalesInfo();
    getPointSales(res.data.data);
  };

  const getInfoEvents = async () => {
    const res = await getEventsInfo();
    getEvents(res.data.data);
  };

  const getInfoReviews = async () => {
    const res = await getReviewsInfo();
    try {
      const data = res.data;
      const records = data.records;
      const page = data.page;
      getReviews(data.data, records, page);
    } catch (error) {}
  };

  const loadImages = () => {
    setLoadingData(true);
    // setTimeout(()=> setLoadingData(true) , 4500)
  };

  const getInfoUser = async (uid) => {
    const resUser = await getUserInfo("", `/${uid}`);
    try {
      localStorage.setItem("upee-picture", resUser.data.data.picture);
      localStorage.setItem("upee-name", resUser.data.data.name);
      localStorage.setItem("upee-lastname", resUser.data.data.lastName);
      localStorage.setItem("upee-rol", resUser.data.data.role);
      localStorage.setItem("upee-email", resUser.data.data.email);
    } catch (error) {}
    getUser(resUser.data.data);
  };

  const getAllSales = async (uid) => {
    const resSales = await getMySales("", `/${uid}`);
    getSales(resSales.data.data);
  };

  const verifyUser = async () => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log("Admin auth");
        // const uid = user.uid;
        // console.log('Uid: ', uid);
        await getInfoUser(user.uid);
        await getAllSales(user.uid);
        await auth.currentUser.getIdToken(true).then((token) => {
          // console.log('Token: ', token);
          localStorage.setItem("upee-key", token);
          refreshToken(token);
        });
        setLoading(true);
      } else {
        setLoading(false);
        window.localStorage.clear();
        saveToken("", "", "", "", "", "");
      }
    });
  };

  useEffect(() => {
    verifyUser();
    getInfoProducts();
    getInfo();
    getInfoShipments();
    getInfoReviews();
    getInfoPointSales();
    getInfoEvents();
  }, []);
  return (
    <Router>
      <ToastContainer />
      <ScrollToTop />

      <Fragment>
        <Menu />
        <Route exact path="/" component={Home} />
        <Route exact path="/que-es" component={AboutUs} />
        <Route exact path="/contacto" component={Contact} />
        <Route exact path="/eventos" component={Events} />
        <Route exact path="/instructivo" component={Use} />
        <Route exact path="/distribuidoras" component={Dealers} />
        <Route exact path="/compra/:id?" component={Shopping} />
        <Route exact path="/carrito" component={Cart} />
        <Route exact path="/registro" component={Registration} />
        <Route exact path="/politicas-de-privacidad" component={PrivacyPolicies} />
        <Route exact path="/terminos-y-condiciones" component={TermsAndConditions} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route exact path="/canales" component={Channels} />

        <Route exact path="/panel/:id?" component={loading ? Panel : Loading} />
        <Footer />
      </Fragment>

      <Fragment>
        {/* <LoadingScreen /> */}
        {auxProducts &&
          auxProducts.map((i) => (
            <div key={uniqid()}>
              <img alt="" loading="eager" src={i.picture1} style={{ display: "none" }} onLoad={loadImages} />
              <img alt="" src={i.picture2} style={{ display: "none" }} />
              <img alt="" src={i.picture3} style={{ display: "none" }} />
            </div>
          ))}
        {/* {
                            auxBanner&&
                            auxBanner.map((i, j)=>(
                                <div key={uniqid()}>
                                    <img alt='' src={i.image} onLoad={loadImages} style={{display:'none'}} />
                                    <img alt='' src={i.image_back} onLoad={loadImages} style={{display:'none'}} />
                                    <img alt='' src={i.image_text} onLoad={loadImages} style={{display:'none'}} />
                                </div>
                            ))
                        } */}
      </Fragment>
    </Router>
  );
}

const mapDispatchToProps = {
  getProducts,
  getBanners,
  refreshToken,
  getUser,
  saveToken,
  getSales,
  getShipments,
  getReviews,
  getPointSales,
  getEvents,
};

export default connect(null, mapDispatchToProps)(App);
