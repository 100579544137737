import { reduxState } from 'values';

export const getPointSales = (data) => {
    return (dispatch) => {
        dispatch({
            type: reduxState.get_pointsales,
            data
        })
    }
}

export const getEvents = (data) => {
    return (dispatch) => {
        dispatch({
            type: reduxState.get_events,
            data
        })
    }
}
