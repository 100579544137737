import Lottie from 'react-lottie';

import { Loader_cart, Img_cart_empty } from 'values';

const CartVoid = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: Loader_cart,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <div style={{display:'flex', alignItems:'center', justifyContent:'center', height:650, paddingTop:200}}>
            <div style={{position:'relative', width:'100%', textAlign:'center'}}>
                <div style={{width:350, marginTop:30, position:'absolute', left:'50%', top:0, transform:'translate(-50%, -100%)'}}>
                    <Lottie options={defaultOptions} />
                </div>
                <img alt='' src={Img_cart_empty} style={{maxWidth:450, width:'100%'}} />
                {/* <p style={{fontSize:30, fontFamily:'m_regular'}}>{'No hay productos en tu carrito'}</p> */}
            </div>
        </div>
    );
};

export default CartVoid;