import { reduxState, stateData } from 'values';

const initialState = {
    data: [],
    status: stateData.state1,
    dataEdit: [],
    records: 1,
    page: 1
};

const reducer = (state = initialState, action) => {
    if (action.type === reduxState.get_reviews) {
        return {
            ...state,
            data: action.data,
            records: action.records,
            page: action.page,
            status: stateData.state2
        }
    }
    return state
}
export default reducer;