import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';

import Panel from './reducers/PanelAdmin';
import Banners from './reducers/Banners';
import Products from './reducers/Products';
import Store from './reducers/Store';
import Alliances from './reducers/Alliances';
import Reviews from './reducers/Reviews';
import Events from './reducers/Events';

const reducers = combineReducers({
    Panel, Banners, Products, Store, Alliances, Reviews, Events
})

const store = createStore(
    reducers,
    compose(
        applyMiddleware(thunkMiddleware),
        window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    )
)

export default store;