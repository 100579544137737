import { Container } from '@material-ui/core';

import { colors } from 'values';

const Text = () => {
    return (
        <div style={{ textAlign: 'center', padding: '20px 0' }}>
            <Container>
                <p style={{ color: colors.purple, marginBottom: 70, marginTop: -15, fontSize: 20, fontFamily: 'm_medium' }}>{'El diseño de UPee también facilita el orinar a mujeres con discapacidades, ya sea después de una cirugía, cesárea o cuando por inmovilidad dependen de una silla de ruedas. Sabemos que el concepto es nuevo para ti, pero UPee no es el primer dispositivo de su tipo.'}</p>
            </Container>
        </div>
    );
};

export default Text;