import { useHistory } from 'react-router-dom';

import { Container, Grid } from '@material-ui/core';
import ScrollAnimation from 'react-animate-on-scroll';
import { makeStyles } from '@material-ui/core/styles';

import Button from 'components/global/Button';

import { Comodo, Higienico, Seguro, Upee_info, colors } from 'values';

// Styles
export const useStyles = makeStyles(theme => ({
    image1:{
        textAlign:'center', paddingBottom:40,
        [theme.breakpoints.down('sm')]:{
            paddingBottom:10,
        },
    },
    image2:{
        textAlign:'start',
        [theme.breakpoints.down('xs')]:{
            textAlign:'center',
            padding:'30px 0'
        },
    },
    image3:{
        textAlign:'end',
        paddingBottom:20,
        [theme.breakpoints.down('xs')]:{
            textAlign:'center',
        },
    },
    container_text:{
        padding:'50px 40px',
        [theme.breakpoints.down('sm')]:{
            padding:'20px 0px',
        },
    }
}));

const WhatIsUpee = () => {
    const classes = useStyles()
    const history = useHistory ()

    const handleClick = () =>{
        history.push('/dispositivo')
    }
    return (
        <div style={{padding:'70px 0'}}>
            <Container>
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <ScrollAnimation animateIn="upee_image_what_is_upee_animation"  animateOnce={false} duration={0.7} >
                            <img alt='' src={Upee_info} style={{width:'100%'}} />
                        </ScrollAnimation>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Container style={{height:'100%'}}>
                            <div style={{height:'100%', display:'flex',alignItems:'center'}}>
                                <div>
                                    <div className={classes.container_text}>
                                        <ScrollAnimation animateIn="text1_what_is_upee_animation" animateOnce={false} duration={0.4}>
                                            <div className={classes.image1}>
                                                <img alt='' src={Higienico} />
                                            </div>
                                        </ScrollAnimation>

                                        <ScrollAnimation animateIn="text1_what_is_upee_animation" animateOnce={false} duration={0.7}>
                                            <div className={classes.image2}>
                                                <img alt='' src={Seguro} />
                                            </div>
                                        </ScrollAnimation>

                                        <ScrollAnimation animateIn="text1_what_is_upee_animation" animateOnce={false} duration={1}>
                                            <div className={classes.image3}>
                                                <img alt='' src={Comodo} />
                                            </div>
                                        </ScrollAnimation>

                                        <p style={{margin:0, fontSize:20, paddingBottom:20, fontFamily:'av_book'}}>{'Es un dispositivo que ayuda a las mujeres a orinar estando de pie cuando los baños están llenos, sucios o simplemente no hay uno cerca.'}</p>
                                        <p style={{margin:0, fontSize:20, fontFamily:'av_book'}}>{'Es práctico, higiénico y muy fácil de usar.'}</p>
                                        <Button onClick={handleClick} text='Más información' width={300} background={colors.red} />
                                    </div>

                                </div>
                            </div>
                        </Container>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default WhatIsUpee;