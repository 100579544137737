import { makeStyles } from '@material-ui/core/styles';

// Values
import { colors } from 'values';
// Styles
export const useStyles = makeStyles(theme => ({
    button: {
        color: '#fff',
        textTransform: 'uppercase',
        // height:60,
        cursor: 'pointer',
        border: 'none',
        fontSize: 16,
        fontWeight: 600,
        letterSpacing: 6,
        marginTop: 30,
        transition: 'transform .2s',
        '&:hover': {
            transform: 'scale(1.1)',
            '&:active': {
                transform: 'scale(1)',
            },
        },
    },
}));

const Button = ({ text = 'Comenzar', onClick, background = colors.yellow, color = '#fff', width = 230, height = 60 }) => {
    const classes = useStyles()
    return (
        <button className={classes.button} onClick={onClick} style={{ background: background, width: width, height: height, color: color, fontFamily: 'm_medium' }}>
            {text}
        </button>
    );
};

export default Button;