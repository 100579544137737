import { useEffect, useState } from "react";
import { Container, Grid, Modal } from "@material-ui/core";
import { toast } from "react-toastify";

// Components
import Input from "./Input";
import ButtonPayment from "../global/ButtonPayment";
// Styles
import { useStyles } from "../../style";
// Utils
import { validateCards } from "utils/store";

import { conektaKey } from "utils/api";

const publicKey = conektaKey;

const ToPay = ({ paymentCart, customer, validate }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const [nameCard, setNameCard] = useState("");
  const [numberCard, setNumberCard] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [ccv, setCcv] = useState("");

  const [open, setOpen] = useState(false);
  const [checkOutId, setCheckOutId] = useState("");

  const [payed, setPayed] = useState(false);

  const successResponseHandler = (token) => {
    paymentCart(token.id);
    // setLoading(false)
  };

  const errorResponseHandler = () => {
    setLoading(false);
    console.log("Error in generate token card");
    toast.error("Ocurrio un error verifica los datos de tu tarjeta.");
  };

  const onSubmit = () => {
    setLoading(true);
    const card = { nameCard, numberCard, month, year, ccv };
    const res = validateCards(card);
    if (res.flag) {
      let tokenParams = {
        card: {
          number: card.numberCard,
          name: card.nameCard,
          exp_year: card.year,
          exp_month: card.month,
          cvc: card.ccv,
        },
      };
      window.Conekta.token.create(tokenParams, successResponseHandler, errorResponseHandler);
    } else {
      setLoading(false);
      toast.warn(res.message);
    }
  };

  const payWithCheckOut = async () => {
    const res = validate();
    if (res.flag === false) {
      toast.warn(res.message);
    }
    if (res.flag === true) {
      setLoading(true);
      const res = await customer();
      // console.log('Res: ', res);
      if (res) {
        setCheckOutId(() => res);
        setOpen(true);
      } else {
        toast.error("Ocurrio un error, por favor vuelve a intentarlo.");
      }
      setLoading(false);
    }
  };

  const payWithCheckOutCancel = () => {
    setLoading(false);
    setOpen(false);
  };

  const standBy = () => {};
  useEffect(() => {
    const handler = (event) => {
      try {
        const data = JSON.parse(event.data);
        if (data.pay === true) {
          paymentCart("", data.orderId);
        }
      } catch (error) {}
    };
    window.addEventListener("message", handler);
    // clean up
    return () => window.removeEventListener("message", handler);
  });
  return (
    <div className={classes.container_payments} style={{ paddingBottom: 30 }}>
      <div style={{ display: "flex", justifyContent: "center", marginBottom: 20, width: "100%" }}>
        <div style={{ width: 500 }}>
          <ButtonPayment onClick={loading ? standBy : payWithCheckOut} loading={loading} />
        </div>
      </div>
      <Modal
        open={open}
        onClose={payWithCheckOutCancel}
        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Container maxWidth="md">
          <div style={{ display: "flex", justifyContent: "center", background: "#fff" }}>
            <iframe
              width="100%"
              height="700px"
              style={{ border: "none" }}
              title="test"
              srcDoc={`
                            <!DOCTYPE html>
                            <html>
                            <head>
                                <!-- Conekta checkout -->
                                <script type="text/javascript" src="https://pay.conekta.com/v1.0/js/conekta-checkout.min.js"></script>
                            </head>
                            <body>
                                <div id="conektaIframeContainer" style="height:900px;"></div>
                                <script type="text/javascript">
                                    window.ConektaCheckoutComponents.Integration({
                                        targetIFrame: "#conektaIframeContainer",
                                        allowTokenization: true, 
                                        checkoutRequestId: "${checkOutId}",
                                        publicKey: "${publicKey}",
                                        options: {
                                            styles: {
                                                inputType: 'basic',
                                                buttonType: 'rounded',
                                                states: {
                                                    empty: { borderColor: '#FFAA00' },
                                                    invalid: { borderColor: '#FF00E0' },
                                                    valid: { borderColor: '#0079c1' }
                                                },
                                            },
                                            languaje: 'es',
                                            button: {
                                                colorText: '#ffffff',
                                                backgroundColor: '#301007'
                                            },
                                            iframe: {
                                                colorText: '#2153a4',
                                                backgroundColor: '#FFFFFF'
                                            }
                                        },
                                        onCreateTokenSucceeded: function(token) {
                                            console.log("T", token)
                                            window.top.postMessage(
                                                JSON.stringify({
                                                    pay: true,
                                                    message: "PAYED"
                                                }),
                                                '*'
                                            );
                                        },

                                        onFinalizePayment: function(event){
                                            console.log("E", event);
                                            window.top.postMessage(
                                                JSON.stringify({
                                                    pay: true,
                                                    message: "PAYED",
                                                    orderId: event.id
                                                }),
                                                '*'
                                            );
                                        },
                                        onCreateTokenError: function(error) {
                                            console.log("Error create token:",error)
                                            window.top.postMessage(
                                                JSON.stringify({
                                                    pay: false,
                                                    message: "Error"
                                                }),
                                                '*'
                                            );
                                        }
                                    })
                                </script>
                            </body>
                            </html>
                        `}
            />
          </div>
        </Container>
      </Modal>
    </div>
  );
};

export default ToPay;
