import { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';

import CardRecords from './CardRecords';

const Records = ({mySales}) => {
    const [open, setOpen] = useState([true, false, false, false])

    const changeOpen = (index, status) =>{
        let auxOpen = open
        auxOpen.forEach((i,j)=>{
            if(j===index){ auxOpen[j] = status }
        })

        setOpen([...open], auxOpen)
    }
    useEffect(()=>{
        let auxOpen = []
        mySales.forEach((i,j)=>{
            auxOpen.push(false)
        })
        setOpen(auxOpen)
    },[mySales])
    return (
        <Fragment>
            {
                mySales&&
                mySales.map((i, j)=>(
                    <CardRecords key={j} data={i}
                        status={'En espera de envio'}
                        index={j}
                        open={open} setOpen={changeOpen} />
                ))
            }
        </Fragment>
    );
};

const mapStateToProps = state =>({
    mySales: state.Panel.sales,
})

export default connect(mapStateToProps, {})(Records)
