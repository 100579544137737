import { Fragment } from "react";
import { Grid } from "@material-ui/core";
import { Button, makeStyles } from "@material-ui/core";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { updateStore } from "redux/actions/Store";

import { Ic_favorites, colors, Ic_card_add, Ic_details } from "values";
import { formatMoney, checkPrice } from "utils";

const useStyles = makeStyles((theme) => ({
  card1: {
    width: "calc(100% - 40px)",
    position: "relative",
    height: 600,
    background: colors.back,
    margin: 20,
    "&:hover": {
      "& #cart": {
        display: "flex",
        animationDelay: "0.4s",
        animation: `$button_animation 0.3s ease both`,
      },
      "& #details": {
        display: "flex",
        animationDelay: "0.2s",
        animation: `$button_animation 0.3s ease both`,
      },
      "& #favorite": {
        display: "flex",
        // animationDelay: '0.4s',
        animation: `$button_animation 0.3s ease both`,
      },
    },
    "& button": {
      display: "none",
      borderRadius: 50,
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 40px)",
      height: 500,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: 300,
      margin: 0,
      marginBottom: 20,
    },
  },
  card2: {
    width: "calc(100% - 40px)",
    height: 280,
    background: colors.back,
    margin: 20,
    marginBottom: 40,
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 30px)",
      marginTop: 20,
      margin: 10,
      height: 240,
      marginBottom: 20,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: 300,
      margin: 0,
      marginBottom: 20,
      display: "none",
    },
  },
  card3: {
    width: "calc(100% - 40px)",
    height: 280,
    background: colors.back,
    margin: 20,
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 30px)",
      height: 240,
      margin: 10,
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  "@keyframes button_animation": {
    "0%": {
      opacity: 0,
      transform: "scale(0.4)",
    },
    "100%": {
      opacity: 1,
      transform: "scale(1)",
    },
  },
  title: {
    margin: 0,
    marginTop: 10,
    fontSize: 50,
    color: "#fff",
    letterSpacing: 4,
    fontWeight: 400,
    [theme.breakpoints.down("xs")]: {
      fontSize: 38,
    },
  },
  container_buttons: {
    width: "100%",
    height: 0,
    bottom: "30%",
    left: "83%",
    transform: "translate(-50%, 0)",
    position: "absolute",
    zIndex: 3,
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      left: "60%",
      position: "relative",
      bottom: -10,
    },
  },
  container_info: {
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      marginTop: 100,
    },
  },
  container: {
    marginBottom: 150,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 10,
    },
  },
}));

const ButtonAction = ({ image, id, onClick }) => {
  return (
    <Button id={id} onClick={onClick} style={{ width: 70, height: 70, justifyContent: "center", alignItems: "center" }}>
      <img alt="" src={image} draggable={false} style={{ imageRendering: "crisp-edges" }} />
    </Button>
  );
};

const MostSold = ({ data, rol, distClass, store, details, updateStore }) => {
  const classes = useStyles();
  const history = useHistory();

  const buyNow = () => {
    addProducts();
    setTimeout(() => history.push("/carrito"), 600);
  };

  const addProducts = () => {
    toast.success("Upee agregado al carrito.");
    let flag = false;
    store.forEach((i) => {
      if (i.id === data.id) {
        i.total += 1;
        flag = true;
      }
    });

    if (!flag) {
      let storeAux = [];
      let auxData = {};
      auxData.id = data.id;
      auxData.name = data.name;
      auxData.sku = data.sku;
      auxData.color = data.color;
      auxData.total = 1;
      auxData.publicPrice = data.publicPrice;
      auxData.distributorPrice = data.distributorPrice;
      auxData.distributorPriceB = data.distributorPriceB;

      storeAux.push(auxData);
      store.forEach((i) => {
        storeAux.push(i);
      });
      updateStore(storeAux);
    } else {
      updateStore([...store]);
    }
  };

  const addFavorite = (newData) => {
    // console.log('ND: ', newData);
    localStorage.setItem("upee-favorites", JSON.stringify(newData));
    toast.success("Upee favorito agregado.");
  };

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item xs={12} sm={8}>
          <div className={classes.card1}>
            <img alt="" src={data.picture1} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
            <div
              style={{
                width: "100%",
                height: 70,
                background: `${colors.red}b3`,
                position: "absolute",
                zIndex: 1,
                bottom: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h3 className={classes.title} style={{ fontFamily: "walfords" }}>
                {"El mas vendido"}
              </h3>
            </div>
            <div className={classes.container_buttons}>
              {data.enable ? (
                <Fragment>
                  <ButtonAction image={Ic_favorites} id={"favorite"} onClick={() => addFavorite(data)} />
                  <div style={{ width: 20 }} />
                  <ButtonAction image={Ic_details} id={"details"} onClick={() => details(data)} />
                  <div style={{ width: 20 }} />
                  <ButtonAction image={Ic_card_add} id={"cart"} onClick={buyNow} />
                </Fragment>
              ) : (
                <Fragment />
              )}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={4}>
          <div className={classes.card2}>
            <img alt="" src={data.picture2} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
          </div>
          <div className={classes.card3}>
            <img alt="" src={data.picture3} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
          </div>
        </Grid>
      </Grid>
      <div className={classes.container_info}>
        <p style={{ margin: 0, fontWeight: 400, fontSize: 52, color: colors.red, fontFamily: "walfords" }}>
          {data.name}
        </p>
        <p style={{ margin: 0, color: colors.red, fontFamily: "m_light" }}>
          {`$ ${formatMoney(
            checkPrice(rol, distClass, data.distributorPrice, data.distributorPriceB, data.publicPrice)
          )} MXN`}
        </p>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  store: state.Store.data,
  rol: state.Panel.rol,
  distClass: state.Panel.data.distribuitorClass,
});

export default connect(mapStateToProps, { updateStore })(MostSold);
