import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";

// Components
import Input from "../global/Input";
// Values
import { colors } from "values";
import { validateData, postData } from "utils/contact";

export const useStyles = makeStyles((theme) => ({
  button_form: {
    background: colors.back,
    color: "#fff",
    textTransform: "uppercase",
    width: "calc(200px - 20px)",
    height: 50,
    borderRadius: 10,
    paddingRight: 20,
    cursor: "pointer",
    border: "none",
    fontSize: 20,
    fontWeight: 600,
    "&:active": {
      transform: "scale(0.96)",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingRight: 0,
    },
  },
}));

const Forms = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const cleanData = () => {
    setLoading(false);
    setEmail("");
    setName("");
    setLastName("");
    setPhone("");
    setMessage("");
  };

  const addContact = async () => {
    setLoading(true);
    const res1 = validateData(name, lastName, email);
    if (res1.flag) {
      const res2 = await postData(name, lastName, email, phone, message);
      if (res2) {
        toast.success("Gracias por tu mensaje, pronto nos pondremos en contacto");
      } else {
        toast.warn("Por favor verifica que tus datos esten correctos.");
      }
      setTimeout(() => cleanData(), 1000);
    } else {
      setLoading(false);
      toast.warn(res1.message);
    }
  };

  const standBy = () => {};
  return (
    <div style={{ padding: "30px 0" }}>
      <Container>
        <Input title={"Nombre"} placeholder={"Tu nombre"} value={name} setValue={setName} requiered={true} />
        <Input
          title={"Apellidos"}
          placeholder={"Tus apellidos"}
          value={lastName}
          setValue={setLastName}
          requiered={true}
        />
        <Input
          title={"Correo"}
          placeholder={"Tu correo"}
          value={email}
          setValue={setEmail}
          requiered={true}
          type={"email"}
        />
        <Input title={"Teléfono"} placeholder={"Tu teléfono"} value={phone} setValue={setPhone} />
        <Input
          title={"Mensaje"}
          placeholder={"Cuentanos sobre tu duda, o danos tu comentario."}
          value={message}
          setValue={setMessage}
          type="area"
        />
        <br />
        <label style={{ fontFamily: "m_regular" }}>{'Al hacer clic en "Enviar", acepto la '}</label>
        <label style={{ fontWeight: 600, fontFamily: "m_regular" }}>{"Política de privacidad "}</label>
        <label style={{ fontFamily: "m_regular" }}>{"de Upee."}</label>
        <br />
        <p style={{ color: "#ff1771", margin: 0, fontFamily: "m_regular" }}>
          {"Los campos marcados con un asterisco (*) son obligatorios."}
        </p>
        <div style={{ height: 50 }} />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <button
            className={classes.button_form}
            style={{ fontFamily: "m_medium" }}
            onClick={loading ? standBy : addContact}
          >
            {loading ? (
              <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                <ReactLoading type={"bubbles"} color={"white"} style={{ fill: "#fff", height: 50, width: 50 }} />
              </div>
            ) : (
              "Enviar"
            )}
          </button>
        </div>
        <div style={{ height: 20 }} />
      </Container>
    </div>
  );
};

export default Forms;
