import { Fragment} from 'react';

import {useStyles}  from './style';
const Input = ({title='Title', value, setValue, blockData, placeholder='Placeholder', type='text'}) => {
    const classes = useStyles()
    return (
        <Fragment>
            <label style={{margin:0, fontSize:14, fontFamily:'m_regular'}}>{title}</label>
            <br/>
            <input value={value} onChange={e=>setValue(e.target.value)}
                style={{height:40, borderRadius:6}}
                className={classes.input} 
                type={type} style={{fontFamily:'m_regular'}}
                placeholder={placeholder} 
                disabled={blockData} />
        </Fragment>
    );
};

export default Input;