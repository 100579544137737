
// Styles
import { useStyles }  from './styles';

const Input = ({title='Title', value, setValue, placeholder='', type='text'}) => {
    const classes = useStyles()
    return (
        <div style={{width:'100%'}}>
            <label style={{margin:0, fontFamily:'m_semi_bold'}}>{title}</label>
            <input className={classes.input} value={value} onChange={e=>setValue(e.target.value)}
                type={type} style={{background:'#ffff', fontFamily:'av_book'}}
                placeholder={placeholder} />
        </div>
    );
};

export default Input;