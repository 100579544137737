import { checkActionCode, verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
import { auth } from 'firebase';

export const resetPassword = async (actionCode, newPassword) => {
    let flag = false
    let expired = false

    await confirmPasswordReset(auth, actionCode, newPassword).then((resp) => {
        flag = true
    }).catch((error) => {
        flag = false
    });

    // await firebase.auth().confirmPasswordReset(code, newPassword)
    //     .then(function (succes) {
    //         flag = true
    //     })
    //     .catch(function (error) {
    //         flag = false
    //     })

    return flag
}

export const handleRecoverEmail = async (actionCode) => {
    let restoredEmail = null;
    let expired = false

    try {
        await checkActionCode(auth, actionCode).then((info) => {
            restoredEmail = info['data']['email'];
            console.log('restoredEmail: ', restoredEmail);
        })
    } catch (error) {
        expired = true
    }

    return { email: restoredEmail, expired }
}