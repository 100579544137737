import { Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

import Button from "components/global/Button2";

import { Img_delivery_a, Img_delivery_b, colors } from "values";

export const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 45,
    margin: 0,
    color: colors.purple,
    fontWeight: 400,
    [theme.breakpoints.down("md")]: {
      fontSize: 40,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 35,
    },
  },
  container_text: {
    marginRight: 70,
    marginBottom: 20,
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      marginRight: 30,
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: -10,
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
      marginBottom: 50,
    },
  },
  container_image: {
    textAlign: "end",
    [theme.breakpoints.down("sm")]: {
      textAlign: "start",
    },
  },
  image1: {
    width: "100%",
    maxWidth: 523,
    objectFit: "cover",
    [theme.breakpoints.down("sm")]: {
      maxWidth: 300,
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  image2: {
    width: "100%",
    maxWidth: 450,
    marginLeft: 30,
    objectFit: "cover",
    [theme.breakpoints.down("sm")]: {
      maxWidth: 300,
      marginLeft: 0,
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
      marginBottom: 30,
    },
  },
  item1: {
    order: 1,
    [theme.breakpoints.down("xs")]: {
      order: 2,
    },
  },
  item2: {
    order: 2,
    [theme.breakpoints.down("xs")]: {
      order: 1,
    },
  },
  container: {
    padding: "70px 0",
    [theme.breakpoints.down("xs")]: {
      paddingTop: 0,
      paddingBottom: 70,
    },
  },
}));

const DoYouWantToBeADistributor = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleRegister = () => {
    history.push(`/registro`);
  };
  return (
    <div className={classes.container}>
      <Container>
        <Grid container>
          <Grid item xs={12} sm={6} className={classes.item1}>
            <div className={classes.container_image}>
              <img alt="" src={Img_delivery_a} className={classes.image1} />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.item2}>
            <div>
              <div className={classes.container_text}>
                <h2 className={classes.title} style={{ fontFamily: "walfords" }}>
                  {"Quiero ser distribuidora"}
                </h2>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <p style={{ width: 400, color: colors.purple, fontFamily: "m_medium" }}>
                    {
                      "Eres una emprendedora… La oportunidad de crear un negocio Y apoyar a mujeres y de manera  independiente inicia hoy. "
                    }
                  </p>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <p style={{ width: 400, color: colors.purple, fontFamily: "m_medium" }}>
                    {
                      "Atrevete a ser una chica upee y se parte de la revolución con el producto que todas las mujeres necesitan tener."
                    }
                  </p>
                </div>
                <Button text="Más información" onAction={handleRegister} />
              </div>
              <img alt="" src={Img_delivery_b} className={classes.image2} />
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default DoYouWantToBeADistributor;
