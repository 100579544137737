import { Container, Grid } from '@material-ui/core';

import ToPrint from './ToPrint';
import Card from './Card';

import { Img_step_1, Img_step_2, Img_step_3, Img_step_4, Img_step_5, Img_step_6, Img_step_7, colors } from 'values';

const CardContainer = ({ text, image, bg }) => {
    return (
        <Grid item sm={4}>
            <Card text={text} image={image} bg={bg} />
        </Grid>
    )
}

const Cards = () => {
    return (
        <div style={{ padding: '70px 0' }}>
            <ToPrint />
            <Container>
                <Grid container>
                    <CardContainer bg={colors.red} image={Img_step_1} text={'Colócalo correctamente, que la parte más ancha quede entre tus piernas y al tope.'} />
                    <CardContainer bg={colors.red} image={Img_step_2} text={'Inclina y direcciona hacia adelante. Realiza los puntos 3, 4 y 5 de la lista anterior.'} />
                    <CardContainer bg={colors.red} image={Img_step_3} text={'Relajate y orina, no separes tus puernas para evitar derrames.'} />
                    <CardContainer bg={colors.back} image={Img_step_4} text={'Asegurate de retirarlo hasta terminar, hazlo con cuidado y escurriendo hacia abajo.'} />
                    <CardContainer bg={colors.back} image={Img_step_5} text={'Enjuaga, limpia y sacude tu Upee, guardalo en su bolsita. Listo!'} />
                </Grid>

                <h1 style={{ fontSize: 40, marginTop: 100, fontFamily: 'm_extra_bold' }}>{'Úsalo también sentada'}</h1>
                <Grid container>
                    <CardContainer bg={colors.light_purple} image={Img_step_6} text={'Sientate en la orilla de la silla, colócalo correctamente,que la parte más ancha quede entre sus piernas.'} />
                    <CardContainer bg={colors.light_purple} image={Img_step_7} text={'Inclina y direcciona hacia adelante.Realiza los puntos 3, 4 y 5 de la lista anterior.'} />
                </Grid>

            </Container>
        </div>
    );
};

export default Cards;