import { Fragment } from 'react';
import { Container } from '@material-ui/core';

import Subscribe from '../global/Subscribe';

import { colors } from 'values';

const PrivacyPolicies = () => {
    return (
        <Fragment>
            <div style={{ marginTop: 70, marginBottom: 150 }}>
                <Container>
                    <div style={{ textAlign: 'center' }}>
                        <h2 style={{ fontFamily: 'walfords', color: colors.red, fontSize: 60, margin: 0 }}>{'Politicas de privacidad'}</h2>
                    </div>
                    {/* <p style={{ color: colors.purple }}>{'Con fundamento en los artículos 15 y 16 de la Ley Federal de Protección de Datos Personales en Posesión de Particulares hacemos conocimiento que UIXI S.A de C.V con domicilio en Av Compositores 4550 Zapopan Jalisco México es responsable de recabar sus datos personales, del uso que se le dé a los mismos y de su protección. Su información personal será utilizada para las siguientes finalidades: proveer los servicios y productos que ha solicitado; notificarle sobre nuevos productos y servicios que tengan relación con los ya contratados o adquiridos; comunicarle sobre cambios en los mismos; elaborar estudios y programas que son necesarios para determinar hábitos de consumo; realizar evaluaciones periódicas de nuestros productos y servicios a efecto de mejorar la calidad de los mismos; evaluar la calidad del servicio que brindamos, y en general, para dar cumplimiento a las obligaciones que hemos contraído con usted.'}</p>
                    <p style={{ color: colors.purple }}>{'Para las finalidades antes mencionadas, requerimos obtener los siguientes datos personales: Nombre completo, edad, sexo, teléfono fijo, correo electrónico, RFC. Es importante informarle que usted tiene derecho al acceso, rectificación y cancelación de sus datos personales a oponerse al tratamiento de los mismos o a revocar el consentimiento que para dicho fin nos haya otorgado.'}</p>
                    <p style={{ color: colors.purple }}>{'Para ello es necesario que envíe la solicitud en los términos que marca la Ley en su Art.29 a Cynthia Varela, responsable de nuestro Departamento de Protección de Datos Personales. Ubicado en Av Compositores 4550 Zapopan Jalisco México, o bien, se comunique al teléfono 33 3189 0887 o por correo electrónico a hola@upee.mx'}</p>
                    <p style={{ color: colors.purple }}>{'En caso de que no desee recibir mensajes promocionales de nuestra parte, puede enviarnos su solicitud por medio de la dirección electrónica puesta arriba.'}</p>
                 */}
                    <p> UPEE CORP SA DE CV; mejor conocido como UPEE, con el portal de https://upee.mx/, es el responsable del uso y protección de sus datos personales, y al respecto le informamos lo siguiente: </p>
                    <p> ¿Para qué fines utilizaremos sus datos personales? </p>
                    <p style={{ margin: 0 }}>Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son necesarias para el servicio que solicita:</p>
                    <li style={{ margin: 0 }}>Para poner a su disposición el producto UIXI.</li>
                    <li style={{ margin: 0 }}>Para integrarla dentro de nuestra base de datos pública de distribuidores autorizados</li>
                    <li>Además, para personalizar nuestros servicios, mejorar su experiencia y mantener una comunicación efectiva.</li>

                    <p style={{ margin: 0, paddingTop: 16 }}>De manera adicional, utilizaremos su información personal para las siguientes finalidades secundarias que no son necesarias para el servicio solicitado, pero que nos permiten y facilitan brindarle una mejor atención:</p>
                    <li style={{ margin: 0 }}>Mercadotecnia o publicitaria</li>
                    <li style={{ margin: 0 }}>Prospección comercial</li>

                    <p>En caso de que no desee que sus datos personales se utilicen para estos fines secundarios, indíquelo a continuación envié un correo a hola@upee.mx La negativa para el uso de sus datos personales para estas finalidades no podrá ser un motivo para que le neguemos los servicios y productos que solicita o contrata con nosotros.</p>
                    <p>¿Qué datos personales utilizaremos para estos fines?</p>

                    <p style={{ margin: 0 }}>Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales:</p>
                    <li>Datos de identificación y/o contacto: nombre, domicilio, fecha de nacimiento, correo electrónico.</li>
                    <li>Datos bancarios.</li>

                    <p>¿Cómo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso?</p>

                    <p>Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada adecuadamente (Cancelación); así como oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO.</p>

                    <p style={{ margin: 0 }}>Para el ejercicio de cualquiera de los derechos ARCO, usted deberá presentar la solicitud respectiva a través del siguiente medio:</p>
                    <p style={{ margin: 0 }}>Enviando una solicitud al correo electrónico hola@upee.mx</p>

                    <p >Con relación al procedimiento y requisitos para el ejercicio de sus derechos ARCO, le informamos lo siguiente:</p>
                    <p style={{ margin: 0 }}>a) ¿A través de qué medios pueden acreditar su identidad el titular y, en su caso, su representante, así como la personalidad este último?</p>
                    <p style={{ margin: 0 }}>Por medio del correo electrónico hola@upee.mx</p>

                    <p style={{ margin: 0, paddingTop: 16 }}>b) ¿Qué información y/o documentación deberá contener la solicitud?</p>
                    <p style={{ margin: 0 }}>1.El nombre del titular y domicilio u otro medio para comunicarle la respuesta a su solicitud;</p>
                    <p style={{ margin: 0 }}>2.Los documentos que acrediten la identidad o, en su caso, la representación legal del titular;</p>
                    <p style={{ margin: 0 }}>3.La descripción clara y precisa de los datos personales respecto de los que se busca ejercer alguno de los derechos antes mencionados;</p>
                    <p style={{ margin: 0 }}>4.Cualquier otro elemento o documento que facilite la localización de los datos controvertidos, y las modificaciones a realizarse; y</p>
                    <p style={{ margin: 0 }}>5.Aportar la documentación que sustente su petición.</p>

                    <p style={{ margin: 0, paddingTop: 16 }}>c) ¿En cuántos días le daremos respuesta a su solicitud?</p>
                    <p style={{ margin: 0 }}>15 días hábiles.</p>

                    <p style={{ margin: 0, paddingTop: 16 }}>d) ¿Por qué medio le comunicaremos la respuesta a su solicitud?</p>
                    <p style={{ margin: 0 }}>Por medio del correo electrónico hola@upee.mx</p>

                    <p style={{ margin: 0, paddingTop: 16 }}>e) Para mayor información sobre el procedimiento, ponemos a disposición la siguiente pagina web: https://upee.mx/</p>
                    <p>Los datos de contacto de la persona o departamento de datos personales, que está a cargo de dar trámite a las solicitudes de derechos ARCO, son los siguientes:</p>

                    <p>Departamento de recepción de información. - Correo electrónico: hola@upee.mx</p>

                    <p>Usted puede revocar o limitar su consentimiento para el uso de sus datos personales</p>

                    <p>Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales. Asimismo, usted deberá considerar que, para ciertos fines, la revocación de su consentimiento implicará que no le podamos seguir prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros.</p>

                    <p style={{ margin: 0 }}>Para revocar o limitar su consentimiento deberá presentar su solicitud a través del siguiente medio:</p>
                    <p style={{ margin: 0 }}>Correo electrónico hola@upee.mx.</p>

                    <p style={{ margin: 0, paddingTop: 16 }}>Con relación al procedimiento y requisitos para la revocación de su consentimiento, le informamos lo siguiente:</p>
                    <p style={{ margin: 0 }}>a) ¿A través de qué medios pueden acreditar su identidad el titular y, en su caso, su representante, así como la personalidad este último?</p>

                    <p>Por medio del correo electrónico hola@upee.mx.</p>

                    <p style={{ margin: 0 }}>b) ¿Qué información y/o documentación deberá contener la solicitud?</p>
                    <p style={{ margin: 0 }}>1. El nombre del titular y domicilio u otro medio para comunicarle la respuesta a su solicitud;</p>
                    <p style={{ margin: 0 }}>2. Los documentos que acrediten la identidad o, en su caso, la representación legal del titular;</p>
                    <p style={{ margin: 0 }}>3.La descripción clara y precisa de los datos personales respecto de los que se busca ejercer alguno de los derechos antes mencionados;</p>
                    <p style={{ margin: 0 }}>4. Cualquier otro elemento o documento que facilite la localización de los datos controvertidos, y las modificaciones a realizarse; y</p>
                    <p style={{ margin: 0 }}>5. Aportar la documentación que sustente su petición.</p>

                    <p style={{ margin: 0, paddingTop: 16 }}>c) ¿En cuántos días le daremos respuesta a su solicitud?</p>
                    <p style={{ margin: 0 }}>15 días hábiles.</p>

                    <p style={{ margin: 0, paddingTop: 16 }}>d) ¿Por qué medio le comunicaremos la respuesta a su solicitud?</p>
                    <p style={{ margin: 0 }}>Por medio del correo electrónico hola@upee.mx</p>

                    <p style={{ margin: 0, paddingTop: 16 }}>e) Para más información sobre el procedimiento, ponemos a disposición la siguiente página web: https://upee.mx/</p>

                    <p>¿Cómo puede conocer los cambios en este aviso de privacidad?</p>

                    <p>El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas.</p>
                    <p>Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a través de este medio.</p>
                    <p>Su consentimiento para el tratamiento de sus datos personales</p>
                    <p>Consiento que mis datos personales sean tratados de conformidad con los términos y condiciones informados en el presente aviso de privacidad.</p>

                </Container>
            </div>
            <Subscribe />
        </Fragment>
    );
};

export default PrivacyPolicies;