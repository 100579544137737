import { Container } from '@material-ui/core';
import { colors } from 'values';

const ContainerForm = ({children}) =>{
    return(
        <div style={{backgroundColor:colors.light_gray, paddingTop:30, paddingBottom:30, borderBottom:'2px solid #fff'}}>
            <Container maxWidth='md'>
                {children}
            </Container>
        </div>
    )
}

export default ContainerForm;