import { makeStyles } from "@material-ui/core/styles";

import { colors } from "values";

export const useStyles = makeStyles((theme) => ({
  container: {
    position: "sticky",
    top: 0,
    zIndex: 5,
    // background: 'transparent',
    width: "100%",
  },
  container_box_shadow: {
    boxShadow: "0px 0px 12px 0px rgba(0,0,0,0.2)",
    background: "#fff",
    position: "sticky",
    // height: 120,
  },
  container_menu_drawer: {
    display: "none",
    background: "#fff",
    color: "#392E74",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  container_menu: {
    paddingTop: 40,
    paddingBottom: 30,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  button_card: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    background: "#fffffe",
    width: 60,
    height: 50,
    borderRadius: 18,
    cursor: "pointer",
    boxShadow: "0px 0px 12px 0px rgba(0,0,0,0.2)",
    "&:active": {
      transform: "scale(0.96)",
    },
    // [theme.breakpoints.down('xs')]:{
    //     width:'calc(100% - 40px)',
    // },
  },
  count_total_product: {
    background: "#2ed37c",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 50,
    height: 28,
    width: 28,
    color: "#fff",
    fontSize: 14,
    fontWeight: 600,
    bottom: -10,
    right: 35,
  },
  line_menu: {
    background: "#FFB6D1",
    width: 40,
    position: "absolute",
    top: -10,
    left: 15,
    zIndex: 0,
    transition: "width 0.4s ease-in-out",
  },
  path_menu: {
    margin: 20,
    marginBottom: 0,
    marginTop: 0,
    // color: colors.red,
    cursor: "pointer",
    userSelect: "none",
    position: "relative",
    fontSize: 16,
  },
  container_products: {
    padding: "70px 0",
    [theme.breakpoints.down("md")]: {
      overflowX: "hidden",
    },
  },
}));
