import { Fragment } from "react";
import { Button, makeStyles } from "@material-ui/core";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

// Redux
import { updateStore } from "redux/actions/Store";

// Values
import { Ic_favorites, colors, Ic_card_add, Ic_details } from "values";
import { formatMoney, checkPrice } from "utils";

// styles
const useStyles = makeStyles((theme) => ({
  card: {
    borderLeft: "none",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    position: "relative",
    "&:hover": {
      "& #cart": {
        display: "flex",
        animationDelay: "0.4s",
        animation: `$button_animation 0.3s ease both`,
      },
      "& #details": {
        display: "flex",
        animationDelay: "0.2s",
        animation: `$button_animation 0.3s ease both`,
      },
      "& #favorite": {
        display: "flex",
        // animationDelay: '0.4s',
        animation: `$button_animation 0.3s ease both`,
      },
    },
    "& button": {
      display: "none",
      borderRadius: 50,
    },
  },
  container: {
    width: "100%",
    height: "100%",
    margin: 20,
    [theme.breakpoints.down("xs")]: {
      margin: 0,
    },
  },
  "@keyframes button_animation": {
    "0%": {
      opacity: 0,
      transform: "scale(0.4)",
    },
    "100%": {
      opacity: 1,
      transform: "scale(1)",
    },
  },
  container_buttons: {
    width: "100%",
    height: 0,
    top: "50%",
    left: "70%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    zIndex: 3,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      left: "65%",
    },
  },
  title: {
    margin: 0,
    fontWeight: 400,
    fontSize: 40,
    [theme.breakpoints.down("xs")]: {
      fontSize: 38,
    },
  },
}));

const ButtonAction = ({ image, id, onClick }) => {
  return (
    <Button id={id} onClick={onClick} style={{ width: 70, height: 70, justifyContent: "center", alignItems: "center" }}>
      <img alt="" src={image} draggable={false} style={{ imageRendering: "crisp-edges" }} />
    </Button>
  );
};

const Product = ({ data, rol, distClass, offert, details, store, updateStore, color }) => {
  const classes = useStyles();
  const history = useHistory();

  const buyNow = () => {
    addProducts();
    setTimeout(() => history.push("/carrito"), 600);
  };

  const addProducts = () => {
    toast.success("Upee agregado al carrito.");
    let flag = false;
    store.forEach((i) => {
      if (i.id === data.id) {
        i.total += 1;
        flag = true;
      }
    });

    if (!flag) {
      let storeAux = [];
      let auxData = {};
      auxData.id = data.id;
      auxData.name = data.name;
      auxData.sku = data.sku;
      auxData.color = data.color;
      auxData.total = 1;
      auxData.publicPrice = data.publicPrice;
      auxData.distributorPrice = data.distributorPrice;
      auxData.distributorPriceB = data.distributorPriceB;

      storeAux.push(auxData);
      store.forEach((i) => {
        storeAux.push(i);
      });
      updateStore(storeAux);
    } else {
      updateStore([...store]);
    }
  };

  const addFavorite = (newData) => {
    // console.log('ND: ', newData);
    localStorage.setItem("upee-favorites", JSON.stringify(newData));
    toast.success("Upee favorito agregado.");
  };

  return (
    <div className={classes.card}>
      <div className={classes.container}>
        <img alt="" src={data.picture1} style={{ width: "100%", height: 400, objectFit: "cover" }} />
      </div>
      <div style={{ textAlign: "center", marginBottom: 50 }}>
        <h3 className={classes.title} style={{ color: color, fontFamily: "walfords" }}>
          {data.name}
        </h3>
        <p style={{ margin: 0, color: color, fontFamily: "m_light" }}>
          {`$ ${formatMoney(
            checkPrice(rol, distClass, data.distributorPrice, data.distributorPriceB, data.publicPrice)
          )} MXN`}
        </p>
      </div>

      <div className={classes.container_buttons}>
        {data.enable ? (
          <Fragment>
            <ButtonAction image={Ic_favorites} id={"favorite"} onClick={() => addFavorite(data)} />
            <div style={{ width: 20 }} />
            <ButtonAction image={Ic_details} id={"details"} onClick={() => details(data)} />
            <div style={{ width: 20 }} />
            <ButtonAction image={Ic_card_add} id={"cart"} onClick={buyNow} />
          </Fragment>
        ) : (
          <Fragment />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  store: state.Store.data,
  rol: state.Panel.rol,
  distClass: state.Panel.data.distribuitorClass,
});

export default connect(mapStateToProps, { updateStore })(Product);
