
import { Ic_check, Ic_uncheck } from 'values';

const Item = ({shippingType, onClick, value, text}) =>{
    return(
        <div style={{display:'flex', cursor:'pointer'}} onClick={()=> onClick(value)}>
            <img alt='' src={shippingType===value?Ic_check:Ic_uncheck} draggable={false} />
            <p style={{fontSize:12, fontWeight:400, marginLeft:5, userSelect:'none'}}>{text}</p>
        </div>
    )
}

const TableShipmentMobile = ({title, value, fontFamily='av_book', shippingType, setShippingType}) => {
    return (
        <div style={{display:'flex', justifyContent:'space-between', width:'100%', borderBottom:'1px solid #e0e0e0', paddingBottom:10}}>
            <div style={{ width: '60%', }} >
                <p style={{fontFamily:fontFamily}}>{title}</p>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                    <Item shippingType={shippingType} onClick={setShippingType} value='SLOW' text='Estandar' />
                    <Item shippingType={shippingType} onClick={setShippingType} value='FAST' text='Rapido' />
                </div>
            </div>
            <div style={{ width: '40%', display:'flex', justifyContent: 'flex-end' }}>
                <p style={{fontFamily:fontFamily}}>{value}</p>
            </div>
        </div>
    );
};

export default TableShipmentMobile;