import { useState, useEffect, Fragment } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Container, Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import uniqid from 'uniqid';

// Redux
import { changeNewPosition } from 'redux/actions/PanelAdmin';
import { changeFlagPath } from 'redux/actions/Store';

// import Products from './Products';
import Product from "./Product";
import DealerPrice from './DealerPrice';
import IndividualProduct from './IndividualProduct';
import Subscribe from '../global/Subscribe';
import MostSold from './MostSold';
import Reviews from './Reviews';
import Info from '../global/Info';

import { colors } from 'values';

import { useStyles } from 'style';

const Shopping = ({ position, changeNewPosition, flagPath, changeFlagPath, data, rol }) => {
    const location = useLocation()
    const history = useHistory()
    const classes = useStyles()
    const { id } = useParams()

    const [path, setPath] = useState(location.pathname.slice(1, location.pathname.length))

    const [individualP, setIndividualP] = useState(false)
    const [auxData, setAuxData] = useState({})

    const openIndividualProduct = (individual) => {
        history.push(`/${path.toLowerCase()}/${individual.id}`)
        setIndividualP(() => true)
        setAuxData(individual)
    }

    const closeIndividualProduct = () => {
        let count = path.toLowerCase().indexOf(`/${id}`)
        let newPath = ''
        for (let i = 0; i < count; i++) { newPath += path[i] }

        if (newPath) {
            setPath(newPath.toLowerCase())
            history.push(`/${newPath.toLowerCase()}`)
        } else {
            history.push(`/${path.toLowerCase()}`)
        }
        setAuxData({})
        setIndividualP(() => false)
    }

    const setNewPosition = () => {
        if (individualP === false) {
            changeNewPosition(window.pageYOffset)
        }
    }

    const mapData = () => {
        if (id !== undefined) {
            let aux = {}
            data.forEach(i => {
                if (i.id.toUpperCase() === id.toUpperCase()) { aux = i }
            })
            if (aux.length !== 0) {
                setIndividualP(true)
                changeFlagPath(true)
                setAuxData(aux)
            }
        }
    }

    useEffect(() => {
        mapData()
    }, [path, id])

    useEffect(() => {
        // if (individualP === false) { window.scrollTo(0, position); }
    }, [individualP])

    useEffect(() => {
        if (flagPath === false && individualP === true) {
            setIndividualP(false)
            setAuxData({})
        }
    }, [flagPath, individualP])
    return (
        <Fragment>
            <div className={classes.container_products}>
                {
                    rol === 'Distribuitor' ?
                        <DealerPrice />
                        :
                        <Fragment />
                }

                {
                    individualP ?
                        <IndividualProduct data={auxData} details={closeIndividualProduct} />
                        :
                        <Container>
                            {
                                data &&
                                data.map((i, j) => (
                                    j === 0 ?
                                        <MostSold key={uniqid()} data={i} offert={i.offert} details={openIndividualProduct} />
                                        : null
                                ))
                            }

                            <Grid container onMouseMove={setNewPosition}>
                                {
                                    data &&
                                    data.map((i, j) => (
                                        <Grid item xs={12} sm={6} md={6} lg={4} key={j} style={{ display: j === 0 ? 'none' : 'block' }}>
                                            <Product data={i} offert={i.offert} details={openIndividualProduct} color={j % 2 === 0 ? colors.red : colors.back} />
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </Container>
                }
            </div>
            <Info />
            <Reviews />
            <Subscribe />
        </Fragment>
    );
};

const mapStateToProps = state => ({
    position: state.Panel.position,
    data: state.Products.data,
    flagPath: state.Store.flagPath,
    rol: state.Panel.rol,
})

export default connect(mapStateToProps, { changeNewPosition, changeFlagPath })(Shopping)