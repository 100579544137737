import ReactLoading from "react-loading";
import {useStyles}  from './style';
import {Ic_edit}    from 'values';

const Button = ({text='Guardar', color='#fff', blockData, setBlockData, background='#DB2D6D', onClick, loading}) => {
    const classes = useStyles()
    return (
        <div style={{display:'flex', justifyContent:'space-between', padding:'0 10px', height:75}}>
            <div style={{display:blockData==='disabled'?'block':'none'}}>
                <img alt='Editar' title='Editar' src={Ic_edit} onClick={setBlockData}
                    className={classes.button_edit} />
            </div>
            <div style={{display:blockData==='disabled'?'none':'block'}}/>
            <div style={{display:blockData==='disabled'?'none':'block'}}>
                <button className={classes.button} onClick={onClick}
                    style={{backgroundColor:background, color:color, height:45, width:100, fontSize:14, position:'relative'}}>
                        {
                        loading?
                        <div style={{position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)'}}>
                            <ReactLoading type={"bars"} color={"white"} style={{fill:'#fff', height:50, width:50}} />
                        </div>
                        :
                        text
                    }
                </button>
            </div>
        </div>
    );
};
export default Button;