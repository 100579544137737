import { Fragment } from 'react';

import CountryCards from './CountryCards';
import YouWantToWork from './YouWantToWork';
import Subscribe from '../global/Subscribe';

const Dealers = () => {
    return (
        <Fragment>
            <CountryCards />
            <YouWantToWork />
            <Subscribe />
        </Fragment>
    );
};

export default Dealers;