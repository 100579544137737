import { reduxState } from 'values';

let initialState = {
    name: localStorage.getItem('upee-name'),
    lastName: localStorage.getItem('upee-lastname'),
    picture: localStorage.getItem('upee-picture'),
    rol: localStorage.getItem('upee-rol'),
    token: localStorage.getItem('upee-key') ? localStorage.getItem('upee-key') : '',
    email: localStorage.getItem('upee-email'),
    // favorites:  localStorage.getItem('upee-favorites'),
    data: [],
    sales: [],
    position: 0,
    shipments: [],
}

const reducer = (state = initialState, action) => {
    if (action.type === reduxState.login) {
        return {
            ...state,
            name: action.name,
            lastName: action.lastName,
            picture: action.picture,
            rol: action.rol,
            token: action.token,
            email: action.email
        }
    }
    if (action.type === reduxState.refresh) {
        return {
            ...state,
            token: action.token,
        }
    }
    if (action.type === reduxState.get_user) {
        return {
            ...state,
            data: action.data,
        }
    }
    if (action.type === reduxState.update_picture) {
        return {
            ...state,
            picture: action.picture,
        }
    }
    if (action.type === reduxState.update_user) {
        return {
            ...state,
            name: action.name,
            lastName: action.lastName,
        }
    }
    if (action.type === reduxState.get_my_sales) {
        return {
            ...state,
            sales: action.data,
        }
    }
    if (action.type === reduxState.change_positio_page) {
        return {
            ...state,
            position: action.position,
        }
    }
    if (action.type === reduxState.get_shipments) {
        return {
            ...state,
            shipments: action.shipments,
        }
    }
    if (action.type === reduxState.get_pointsales) {
        return {
            ...state,
            pointsales: action.data,
        }
    }

    return state
}
export default reducer