import {urlApi, urlPath, post, } from '../api';
import {emailformat} from '../index';

export const validateForm1 = (name, phone, email) =>{
    if(!name){
        return {flag:false, message:'Parece que algo anda mal con el nombre.', error:1}
    }
    if(!phone){
        return {flag:false, message:'Parece que algo anda mal con el teléfono.', error:2}
    }
    if(!email.match(emailformat)){
        return {flag:false, message:'Parece que algo anda mal con el correo.', error:3}
    }
    return {flag:true, message:'', error:0}
}

export const validateForm2 = (phone, address) =>{
    if(!phone){
        return {flag:false, message:'Parece que algo anda mal con el teléfono de contacto.', error:1}
    }
    if(!address){
        return {flag:false, message:'Parece que algo anda mal con la dirreción del local.', error:2}
    }
    return {flag:true, message:'', error:0}
}

export const postData = async(name, phone, email, instagram, businessName, businessPhone, webPage, facebook, location, address, sell, business, brand, time, workers, imageLocal, imageArea, imageProduct, logo) =>{
    const url = urlApi + urlPath.postulants
    const obj = {
        name, phone, email, instagram, businessName, businessPhone, webPage,
        facebook, location, address, sell:sell!==''?sell:false, business, brand, time,
        workers, imageLocal, imageArea, imageProduct, logo
    }
    return await post(obj, url)
}