import { useState } from "react";
import { Modal, Container } from "@material-ui/core";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { makeStyles } from "@material-ui/core/styles";
import ReactLoading from "react-loading";

// Redux
import { saveToken } from "redux/actions/PanelAdmin";

// Values
import { Logo, Ic_visibility, Ic_visibility_off, toastText } from "values";
// Styles
// import { useStyles } from 'style';
import { emailformat } from "utils";
// Api
import { verifyLogin } from "utils/login";
import { validateDataUser, postDataUser } from "utils/users";

const useStyles = makeStyles((theme) => ({
  modal_login: {
    background: "#fff",
    height: 500,
    width: "90%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "90%",
    },
  },
  login_option1: {
    // textTransform:'uppercase',
    fontSize: 20,
    fontWeight: 600,
    color: "#3854B2",
    margin: 0,
    cursor: "pointer",
    userSelect: "none",
  },
  login_option2: {
    // textTransform:'uppercase',
    fontSize: 20,
    fontWeight: 600,
    color: "#c6c6c6",
    margin: 0,
    cursor: "pointer",
    userSelect: "none",
  },
  button: {
    backgroundColor: "#000",
    color: "#fff",
    textTransform: "uppercase",
    width: 180,
    height: 60,
    cursor: "pointer",
    border: "none",
    borderRadius: 13,
    fontSize: 20,
    fontWeight: 600,
    "&:active": {
      transform: "scale(0.96)",
    },
  },
}));

const Input = ({ text, type = "text", changeData, opt, pattern = "", view = false, eye, setEye, status }) => {
  return (
    <div style={{ position: "relative" }}>
      <img
        alt=""
        style={{
          display: view ? "block" : "none",
          position: "absolute",
          right: 10,
          top: 5,
          cursor: "pointer",
          width: 20,
        }}
        src={eye ? Ic_visibility : Ic_visibility_off}
        onClick={() => setEye(!eye)}
      />
      <label style={{ userSelect: "none", fontFamily: "m_regular" }}>
        <input
          style={{
            borderBottomColor: status === 0 ? "" : status === 1 || status === 3 ? "#2fd25a" : "#FF1973",
            fontFamily: "m_regular",
          }}
          className={"login_input"}
          type={type}
          pattern={pattern}
          onChange={(e) => changeData(e.target.value, opt)}
        />
        <span className="login_input_text">{text}</span>
      </label>
    </div>
  );
};

const Button = ({
  text = "Comprar",
  onClick,
  color = "#fff",
  background = "#000",
  width = 180,
  height = 60,
  fontSize = 20,
  loading,
}) => {
  const classes = useStyles();
  return (
    <>
      <button
        className={classes.button}
        onClick={onClick}
        style={{
          backgroundColor: background,
          color: color,
          width: width,
          height: height,
          fontSize: fontSize,
          position: "relative",
          fontFamily: "m_bold",
        }}
      >
        {loading ? (
          <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
            <ReactLoading type={"bars"} color={"white"} style={{ fill: "#fff", height: 50, width: 50 }} />
          </div>
        ) : (
          text
        )}
      </button>
    </>
  );
};

const Login = ({ open = false, setOpen, saveToken, redirect = true }) => {
  const classes = useStyles();
  const history = useHistory();

  const [option, setOption] = useState(true);

  const [status1, setStatus1] = useState(0); //0 nada, 1 verde y imagen, 2 rojo e imagen, 3 bien verde e imagen
  const [status2, setStatus2] = useState(0);
  const [eye, setEye] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isError, setIsError] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const validateData = (value, option) => {
    if (option === 1) {
      setEmail(value);
      if (value.match(emailformat)) {
        setStatus1(3);
      } else {
        setStatus1(2);
      }
    } else {
      setPassword(value);
      if (value.length >= 6) {
        setStatus2(3);
      } else {
        setStatus2(2);
      }
    }
  };
  const closeModal = () => {
    setOpen(false);
    setEmail("");
    setPassword("");
    setEye(false);
    setStatus1(0);
    setStatus2(0);
    setOption(true);
  };

  const register = async () => {
    const res1 = validateDataUser(password, email);
    if (res1.flag) {
      const res2 = await postDataUser(password, email);
      if (res2.flag) {
        login();
      } else {
        if (res2.status === 403) {
          toast.warn("Este correo ya esta registrado.");
          setIsError(true);
        } else {
          toast.warn("Por favor verifica que tus datos esten correctos.");
        }
      }
      setLoading(false);
    } else {
      setLoading(false);
      toast.warn(res1.message);
    }
  };

  const login = async () => {
    const res = await verifyLogin(email, password);
    if (res.flag) {
      localStorage.setItem("upee-key", res.token);
      localStorage.setItem("upee-name", res.data.name);
      localStorage.setItem("upee-lastname", res.data.lastName);
      localStorage.setItem("upee-picture", res.data.picture);
      localStorage.setItem("upee-rol", res.data.role);
      localStorage.setItem("upee-email", res.data.email);
      saveToken(res.token, res.data.name, res.data.lastName, res.data.picture, res.data.role, res.data.email);
      closeModal();
      setLoading(false);
      if (redirect) {
        history.push(`/panel`);
      }
    } else {
      toast.warn(toastText.failLogin);
      setLoading(false);
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (status1 === 3 && status2 === 3) {
      setLoading(true);
      if (option) {
        login();
      } else {
        register();
      }
    }
    if (status1 !== 3) {
      setStatus1(2);
    }
    if (status2 !== 3) {
      setStatus2(2);
    }
  };

  const resetPassword = async () => {
    if (email.match(emailformat)) {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email)
        .then(() => {
          toast.success(`Se envío a ${email} un correo para restablecer la contraseña.`);
        })
        .catch((error) => {
          toast.warn("Parece que surgio un problema, revisa tus datos.");
        });
    } else {
      toast.warn("Introduce un correo valido");
    }
  };

  const standBy = (event) => {
    event.preventDefault();
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={closeModal}
        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Container maxWidth="md">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className={classes.modal_login}>
              <Container style={{ padding: "35px 40px" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}
                >
                  <img alt="gaser logo" loading="eager" src={Logo} style={{ width: 170 }} />
                  <div style={{ display: "flex", flexWrap: "wrap", paddingTop: 20 }}>
                    <p
                      onClick={() => setOption(true)}
                      style={{ fontFamily: "m_extra_bold" }}
                      className={option ? classes.login_option1 : classes.login_option2}
                    >
                      {"Entrar"}
                    </p>
                    <div style={{ width: 20 }} />
                    <p
                      onClick={() => setOption(false)}
                      style={{ fontFamily: "m_extra_bold" }}
                      className={option ? classes.login_option2 : classes.login_option1}
                    >
                      {"Registrarme"}
                    </p>
                  </div>
                </div>
                <div style={{ height: 100 }} />

                <Input text={"Correo"} type={"email"} opt={1} status={status1} changeData={validateData} />
                <div style={{ height: 30 }} />

                <Input
                  text={"Contraseña"}
                  type={eye ? "text" : "password"}
                  view={true}
                  eye={eye}
                  setEye={setEye}
                  changeData={validateData}
                  opt={2}
                  status={status2}
                />

                <div style={{ height: 30 }} />
                {option ? (
                  <div style={{ height: 30 }}>
                    <label
                      onClick={resetPassword}
                      style={{ color: "#0047d4", cursor: "pointer", fontFamily: "m_semi_bold" }}
                    >
                      {"¿Olvidaste tu contraseña?"}
                    </label>
                  </div>
                ) : (
                  <div style={{ height: 30 }} />
                )}
                {!option && isError ? (
                  <div style={{ height: 30 }}>
                    <label
                      onClick={resetPassword}
                      style={{ color: "#0047d4", cursor: "pointer", fontFamily: "m_semi_bold" }}
                    >
                      {"¿Quieres enviar un correo para restablecer la contraseña?"}
                    </label>
                  </div>
                ) : null}
                <div style={{ height: 50 }} />

                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    text={option ? "Entrar" : "Registrarse"}
                    onClick={loading ? standBy : onSubmit}
                    loading={loading}
                  />
                </div>
              </Container>
            </div>
          </div>
        </Container>
      </Modal>
    </div>
  );
};

export default connect(null, { saveToken })(Login);
