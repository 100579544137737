import ic_back from "images/ic_back.svg";
import ic_cart from "images/ic_cart.svg";
import ic_next from "images/ic_next.svg";
import ic_user from "images/ic_user.svg";
import ic_edit from "images/ic_edit.svg";
import logo from "images/logo.svg";
import upee_min from "images/upee-min.png";
import img_upee_a_min from "images/img_upee_a-min.jpg";
import img_upee_b_min from "images/img_upee_b-min.jpg";
import img_upee_c_min from "images/img_upee_c-min.jpg";
import gallery_a_min from "images/gallery_a-min.jpg";
import gallery_b_min from "images/gallery_b-min.jpg";
import gallery_c_min from "images/gallery_c-min.jpg";
import gallery_d_min from "images/gallery_d-min.jpg";
import gallery_e_min from "images/gallery_e-min.jpg";
import gallery_f_min from "images/gallery_f-min.jpg";
import gallery_g_min from "images/gallery_g-min.jpg";
import img_moutains_min from "images/img_moutains-min.jpg";
import ic_facebook from "images/ic_facebook.svg";
import ic_instagram from "images/ic_instagram.svg";
import ic_youtube from "images/ic_youtube.svg";
import img_logo_white from "images/img_logo_white.svg";
import ic_send from "images/ic_send.svg";
import img_letters from "images/img_letters.svg";
import img_upee_mountains from "images/img_upee_mountains.png";
import img_1 from "images/img_1.svg";
import img_2 from "images/img_2.svg";
import img_3 from "images/img_3.svg";
import img_4 from "images/img_4.svg";
import img_5 from "images/img_5.svg";
import img_6 from "images/img_6.svg";
import img_7 from "images/img_7.svg";
import img_step_1 from "images/img_step_1.svg";
import img_step_2 from "images/img_step_2.svg";
import img_step_3 from "images/img_step_3.svg";
import img_step_4 from "images/img_step_4.svg";
import img_step_5 from "images/img_step_5.svg";
import img_step_6 from "images/img_step_6.svg";
import img_step_7 from "images/img_step_7.svg";
import user from "images/user.jpg";
import ic_change_option from "images/ic_change_option.svg";
import ic_camera from "images/ic_camera.svg";
import flag_arg from "images/flag_arg.png";
import flag_col from "images/flag_col.png";
import flag_eu from "images/flag_eu.png";
import flag_mx from "images/flag_mx.png";
import flag_pto from "images/flag_pto.png";
import flag_usa from "images/flag_usa.png";
import img_seller from "images/img_seller.svg";
import ic_details from "images/ic_expand.svg";
import ic_card_add from "images/ic_add_to_cart.svg";
import ic_next_arrow from "images/ic_next_arrow.svg";
import ic_back_arrow from "images/ic_back_arrow.svg";
import ic_less from "images/ic_less.svg";
import ic_more from "images/ic_more.svg";
import ic_remove from "images/ic_remove.svg";
import ic_uncheck from "images/ic_uncheck.svg";
import ic_check from "images/ic_check.svg";
import logo_loading from "images/logo_loading.svg";
import ic_unflod from "images/ic_unflod.svg";
import ic_menu from "images/ic_menu.svg";
import team from "images/team.jpg";
import ic_card from "images/ic_card.svg";
import ic_oxxo from "images/ic_oxxo.svg";
import ic_paypal from "images/ic_paypal.svg";
import ic_spei from "images/ic_spei.svg";
import ic_card_white from "images/ic_card_white.svg";
import ic_oxxo_white from "images/ic_oxxo_white.svg";
import ic_paypal_white from "images/ic_paypal_white.svg";
import ic_spei_white from "images/ic_spei_white.svg";
import ic_visibility from "images/ic_visibility.svg";
import ic_visibility_off from "images/ic_visibility_off.svg";
import comodo from "images/COMODO.svg";
import higienico from "images/HIGIENICO.svg";
import seguro from "images/SEGURO.svg";
// import upee_info    from 'images/img_upee.png';
import upee_info from "images/img_upee.webp";
import img_option_a from "images/img_option_a.svg";
import img_option_b from "images/img_option_b.svg";
import img_option_c from "images/img_option_c.svg";
import img_delivery_min from "images/img_delivery-min.jpg";
import img_girl_a from "images/img_girl_a.svg";
import img_girl_b from "images/img_girl_b.svg";
import img_near from "images/img_near.svg";
import img_payment_methods from "images/img_payment_methods.svg";
import img_payment from "images/img_payment.svg";
import img_truck from "images/img_truck.svg";
import img_trust from "images/img_trust.svg";
import img_follow_us from "images/img_follow_us.png";
import uppe_device_min from "images/uppe_device-min.png";
import ic_gallery from "images/ic_gallery.svg";
import icon_ig from "images/icon_ig.svg";
import background_footer from "images/background_footer.png";
// import background_device from 'images/background_device.png';
import background_device from "images/background_device.webp";
import background_contact from "images/background_contact.svg";
import background_near from "images/background_near.svg";
import img_cart_empty from "images/img_cart_empty.svg";
import img_partner from "images/img_partner.webp";
import ic_user_white from "images/ic_user_white.svg";

import ic_health from "images/ic_health.svg";
import ic_innovate from "images/ic_innovate.svg";
import ic_mexican from "images/ic_mexican.svg";
import ic_practice from "images/ic_practice.svg";
import ic_reuse from "images/ic_reuse.svg";
import ic_save from "images/ic_save.svg";
import ic_social from "images/ic_social.svg";

import img_banner_a from "images/upee_with_a.webp";
import img_banner_b from "images/upee_with_b.webp";

import banner from "images/background.webp";

import img_top_1 from "images/img_top_1.webp";
import img_top_2 from "images/img_top_2.webp";
import img_top_3 from "images/img_top_3.webp";
import img_top_4 from "images/img_top_4.webp";
import img_top_5 from "images/img_top_5.webp";
import img_top_6 from "images/img_top_6.webp";

import upee_girl_a from "images/upee_girl_a.webp";
import upee_girl_b from "images/upee_girl_b.webp";

import img_delivery_a from "images/upee_distribution_a.webp";
import img_delivery_b from "images/upee_distribution_b.webp";
import img_delivery_c from "images/img_delivery_c.webp";

import ic_favorites from "images/ic_favorites.svg";
import background_events from "images/background_events.webp";

import upee_chanels from "images/upee_chanels.webp";

import profile from "images/profile.png";

// Video
import uppe_animation_final from "images/upee_animation.mp4";
import upee_video from "images/upee.mp4";

// Json
import dots from "lotties/dots.json";
import loader_cart from "lotties/loader_cart.json";
import loading from "lotties/loading.json";

export const Ic_back = ic_back;
export const Ic_cart = ic_cart;
export const Ic_next = ic_next;
export const Ic_user = ic_user;
export const Logo = logo;
export const Upee_min = upee_min;
export const Img_upee_a_min = img_upee_a_min;
export const Img_upee_b_min = img_upee_b_min;
export const Img_upee_c_min = img_upee_c_min;
export const Img_Banner = banner;

export const Gallery_a_min = gallery_a_min;
export const Gallery_b_min = gallery_b_min;
export const Gallery_c_min = gallery_c_min;
export const Gallery_d_min = gallery_d_min;
export const Gallery_e_min = gallery_e_min;
export const Gallery_f_min = gallery_f_min;
export const Gallery_g_min = gallery_g_min;
export const Img_moutains_min = img_moutains_min;
export const Img_logo_white = img_logo_white;
export const Ic_facebook = ic_facebook;
export const Ic_instagram = ic_instagram;
export const Ic_youtube = ic_youtube;
export const Ic_send = ic_send;
export const Img_letters = img_letters;
export const Img_upee_mountains = img_upee_mountains;
export const Img_1 = img_1;
export const Img_2 = img_2;
export const Img_3 = img_3;
export const Img_4 = img_4;
export const Img_5 = img_5;
export const Img_6 = img_6;
export const Img_7 = img_7;
export const Img_step_1 = img_step_1;
export const Img_step_2 = img_step_2;
export const Img_step_3 = img_step_3;
export const Img_step_4 = img_step_4;
export const Img_step_5 = img_step_5;
export const Img_step_6 = img_step_6;
export const Img_step_7 = img_step_7;
export const User = user;
export const Ic_edit = ic_edit;
export const Ic_change_option = ic_change_option;
export const Ic_camera = ic_camera;

export const Flag_arg = flag_arg;
export const Flag_col = flag_col;
export const Flag_eu = flag_eu;
export const Flag_mx = flag_mx;
export const Flag_pto = flag_pto;
export const Flag_usa = flag_usa;
export const Img_seller = img_seller;
export const Ic_details = ic_details;
export const Ic_card_add = ic_card_add;
export const Ic_next_arrow = ic_next_arrow;
export const Ic_back_arrow = ic_back_arrow;
export const Ic_less = ic_less;
export const Ic_more = ic_more;
export const Ic_remove = ic_remove;
export const Ic_uncheck = ic_uncheck;
export const Ic_check = ic_check;
export const Ic_user_white = ic_user_white;

export const Ic_card = ic_card;
export const Ic_oxxo = ic_oxxo;
export const Ic_paypal = ic_paypal;
export const Ic_spei = ic_spei;

export const Ic_card_white = ic_card_white;
export const Ic_oxxo_white = ic_oxxo_white;
export const Ic_paypal_white = ic_paypal_white;
export const Ic_spei_white = ic_spei_white;

export const Ic_visibility = ic_visibility;
export const Ic_visibility_off = ic_visibility_off;
export const Logo_loading = logo_loading;

export const Ic_unflod = ic_unflod;
export const Ic_menu = ic_menu;

export const Team = team;

export const Comodo = comodo;
export const Higienico = higienico;
export const Seguro = seguro;
export const Upee_info = upee_info;

export const Img_option_a = img_option_a;
export const Img_option_b = img_option_b;
export const Img_option_c = img_option_c;
export const Img_delivery_min = img_delivery_min;

export const Img_girl_a = img_girl_a;
export const Img_girl_b = img_girl_b;
export const Img_near = img_near;

export const Img_payment_methods = img_payment_methods;
export const Img_payment = img_payment;
export const Img_truck = img_truck;
export const Img_trust = img_trust;
export const Img_follow_us = img_follow_us;
export const Uppe_device_min = uppe_device_min;
export const Ic_gallery = ic_gallery;
export const Icon_ig = icon_ig;

export const Background_footer = background_footer;
export const Background_device = background_device;
export const Background_contact = background_contact;
export const Background_near = background_near;

export const Img_cart_empty = img_cart_empty;
export const Img_partner = img_partner;

export const Ic_health = ic_health;
export const Ic_innovate = ic_innovate;
export const Ic_mexican = ic_mexican;
export const Ic_practice = ic_practice;
export const Ic_reuse = ic_reuse;
export const Ic_save = ic_save;
export const Ic_social = ic_social;

export const Img_banner_a = img_banner_a;
export const Img_banner_b = img_banner_b;

export const Img_top_1 = img_top_1;
export const Img_top_2 = img_top_2;
export const Img_top_3 = img_top_3;
export const Img_top_4 = img_top_4;
export const Img_top_5 = img_top_5;
export const Img_top_6 = img_top_6;

export const Upee_girl_a = upee_girl_a;
export const Upee_girl_b = upee_girl_b;

export const Img_delivery_a = img_delivery_a;
export const Img_delivery_b = img_delivery_b;
export const Img_delivery_c = img_delivery_c;

export const Ic_favorites = ic_favorites;
export const Background_events = background_events;

export const Upee_chanels = upee_chanels;
export const Profile = profile;

// Video
export const Uppe_animation_final = uppe_animation_final;
export const Upee_video = upee_video;

// lottie
export const Dots = dots;
export const Loader_cart = loader_cart;
export const Loading_lottie = loading;

// ─── COLORS ─────────────────────────────────────────────────────────────────────
export const colors = {
  primary: "#00A798",
  front: "#36b8ad",
  back: "#12ac9f",
  blue: "#3854b2",
  light_gray: "#f5f5f5",
  silver: "#c0c0c0",
  gray: "#dddddd",
  yellow: "#ffc000",
  yellow_light: "#f1dd64",
  red: "#DB2D6D",
  pink: "#F9E3EB",
  purple: "#422482",
  light_purple: "#bcc5dc",
};

// ─── TOAST TEXT ─────────────────────────────────────────────────────────────────
export const toastText = {
  update: "Datos actualizados.",
  add: "Datos agregados.",
  delete: "Datos eliminados.",
  warning: "Parace que surgio un problema.",
  void: "Parace que faltan campos por llenar.",
  password: "Las contraseñas coinciden.",
  failLogin: "No se pudo iniciar sesión, verifica tus datos.",
  successLogin: "Iniciando sesión",
};

// ─── ACTIONS ────────────────────────────────────────────────────────────────────
export const stateData = {
  downloading: "DOWNLOADING",
  downloaded: "DOWNLOADED",
};

// ─── VIEW ───────────────────────────────────────────────────────────────────────
export const stateView = {
  view: "VIEW_DATA",
  created: "CREATE_DATA",
  edited: "EDIT_DATA",
  deleted: "DELETE_DATA",
};

// ─── STATE OF REDUX ─────────────────────────────────────────────────────────────
export const reduxState = {
  drawer: "UPDATE_DRAWER",
  view: "CHANGE_VIEW",
  login: "LOGIN",
  refresh: "REFRESH",
  get_banners: "GET_BANNERS",
  get_user: "GET_USER",
  update_user: "UPDATE_USER",
  update_picture: "UPDATE_PICTURE",
  get_products: "GET_PRODUCTS",
  store: "STORE",
  get_my_sales: "GET_MY_SALES",
  get_alliances: "GET_ALLIANCES",
  change_flag_path: "CHANGE_FLAG_PATH",
  change_positio_page: "CHANGE_POSITION_PAGE",
  get_shipments: "GET_SHIPMENTS",
  get_reviews: "GET_REVIEWS",
  get_pointsales: "GET_POINTSALES",
  get_events: "GET_EVENTS",
};

export const cfdiList = ["Gastos en general", "Adquisición de mercancia"];

export const deliveryGlobal = 165;

export const paths = {
  homeTitle: "Inicio",
  homePath: "",
  usTitle: "Nosotros",
  usPath: "nosotros",
  soccerTitle: "Fútbol",
  soccerPath: "futbol",
  basketTitle: "Básquetbol",
  basketPath: "basketbol",
  voleyTitle: "Vóleibol",
  voleyPath: "voleibol",
  cartTitle: "Carrito",
  cartPath: "carrito",
  panelTitle: "Panel",
  panelPath: "panel",
  contactTitle: "Contacto",
  contactPath: "contacto",
  alliancesTitle: "Alianzas",
  alliancesPath: "alianzas",
};

export const urlVideo = "https://www.youtube.com/watch?v=yju2Yh9Qz7c&ab_channel=UIXIEurope";

export const infoChannels = [
  {
    content:
      "A)  VENTA DE MENUDEO \nSi tu deseas adquirir un dispositivo Upee para tu uso personal o regalo esta opción es ideal y la podrás concluir directo en nuestra área de compras dentro de este sitio web o en cualquiera de nuestras redes sociales\n\nB)  VENTA MAYOREO\nSi tu deseas crear un negocio seguro, rentable y poder compartir UPEE con amigas tenemos 3 paquetes de venta de mayoreo\nPaquete Reseller a partir de 25 pzas\nPaquete B a partir de 50 piezas\nPaquete A a partir de 100 piezas",
  },
  {
    content:
      "C)   DISTRIBUCIONES NACIONALES\nTienes la visión empresarial, te gusta formar equipos de trabajo y deseas tener la exclusividad de tu zona, estas \ndispuesta a que UPEE sea todo un negocio para ti que te permita tener una libertad financiera ofreciendo soluciones \nreales para las mujeres de hoy.",
  },
  {
    content:
      "D)  FRANQUICIAS\nTienes una cadena de puntos de venta en una zona o en diversos puntos de la República, deseas incluir a UPEE como uno de tus productos para hacer crecer tu cartera de opciones para tu cliente, tenemos una solución para ti, que te dará la oportunidad de satisfacer las necesidades actuales de tus clientes, lleva innovación a tus puntos de venta",
  },
  {
    content:
      "E)  DISTRIBUCIONES INTERNACIONALES\nSin duda eres una persona de negocios, que estas buscando un producto rentable, práctico, funcional que te permita construir toda una cadena de comercialización, Radicas fuera de México y deseas que este producto este en tu país ahora puedes hacerlo, contamos con la experiencia y la infraestructura para llevarte UPEE hasta tu país de residencia, obtén la distribución y comercialización exclusiva de tu país y se nuestro embajador oficial.",
  },
  {
    content:
      "F)   FARMACIAS Y HOSPITALES\nUPEE es un dispositivo de funcionalidad hospitalaria que permite una mejor recuperación y calidad de vida de personas con discapacidad, incontinencia, adultos mayores, post cirugía, con una altísima calidad de materiales que permiten con toda seguridad ser parte estratégica y básica para mejorar y acelerar la recuperación de pacientes",
  },
  {
    content:
      "E) EVENTOS\nTienes un evento en puerta y no deseas incluir lo mismo de siempre porque buscas un producto innovador, práctico y confiable que te permita mayor visibilidad, hacer de tu evento algo memorable y recordable, UPEE te da la oportunidad de hacerlo",
  },
  {
    content:
      "G)  PATROCINIOS\nDurante más de 7 años UPEE se ha caracterizado por sumarse a causas humanitarias es por eso que contamos con un área que evaluá y atiende estas necesidades, si tu tienes una causa o movimiento que apoye el desarrollo, libertad y bienestar de las mujeres, estamos atentos a conocerla.",
  },
];
