import ReactLoading from "react-loading";

const Loading = () => {
  return (
    <div style={{ height: 500, position: "relative" }}>
      <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-10%, -50%)" }}>
        <ReactLoading type={"bubbles"} color={"white"} style={{ fill: "#000", height: 100, width: 100 }} />
      </div>
    </div>
  );
};

export default Loading;
