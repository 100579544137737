import {urlApi, urlPath, get, update} from '../api';
import {emailformat} from '../index';

const url = urlApi + urlPath.webUsers

export const getData = async(token='', extra='') =>{
    return await get(url, token, extra)
} 

export const getMySales = async(token='', extra='')=>{
    const auxUrl = urlApi + urlPath.mySales
    return await get(auxUrl, token, extra)
}

export const postDataUser = async(password, email, token) =>{
    let status = 200
    const obj = {
        email, password
    }
    const request = {
        method:"POST",
        body: JSON.stringify(obj),
        headers:{
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    }
    const res = await fetch(url, request)
    .then(async(res) =>{
        if(res.status === 200){
            return true
        } else{
            // console.log(res);
            if(res.status === 403){
                status = 403
            } else{
                status = 400
            }
            return false
        }
    })
    .catch(() =>{
        return false
    })
    return {flag:res, status:status}
}

export const updatePersonalData = async(id, name, lastName, phone, token) =>{
    const obj = {
        name, lastName, phone,
    }
    return await update(obj, url, id, token)
}

export const updateShipping = async(id, address, numExt, numInt, area, municipality, state, postalCode, token) =>{
    const obj = {
        address, numExt, numInt, area, municipality, state, postalCode
    }
    return await update(obj, url, id, token)
}

export const updateBilling = async(id, legalName, rfc, invoiceAddress, invoiceArea, invoiceNumExt, invoiceNumInt, 
    invoiceMunicipality, invoiceState, invoiceCp, invoiceEmail, cfdi, token) =>{
    const obj = {
        legalName, rfc, invoiceAddress, invoiceArea, invoiceExt:invoiceNumExt, 
        invoiceInt:invoiceNumInt, invoiceMunicipality, invoiceState, invoicePostalCode:invoiceCp, 
        invoiceEmail, cfdi
    }
    return await update(obj, url, id, token)
}

export const updateDistribuitors = async(id, distribuitorCountry, distribuitorState, distribuitorAddress, distribuitorEmail, distribuitorPhone, distribuitorNotes, token) =>{
    const obj = {
        distribuitorCountry, distribuitorState, distribuitorAddress, 
        distribuitorEmail, distribuitorPhone, distribuitorNotes
    }
    return await update(obj, url, id, token)
}

export const updateUserImage = async(id, picture, token) =>{
    const obj = { picture }
    return await update(obj, url, id, token)
}

export const validateDataUser = (password, email) =>{
    if(!password){
        return {flag:false, message:'Parece que algo anda mal con la contraseña.'}
    }
    if(password.length <= 7){
        return {flag:false, message:'Las contraseñas tienen que tener mas de 7 caracteres.'}
    }
    if(!email.match(emailformat)){
        return {flag:false, message:'Parece que algo anda mal con el correo.'}
    }
    return {flag:true, message:''}
}

export const validateInfoShipping = (name, lastName, phone, address, numExt, state, municipality, area, postalCode) =>{
    if(!name){ return {flag:false, message:'Es necesarsio que agregues el nombre'} }
    if(!lastName){ return {flag:false, message:'Es necesarsio que agregues el apellido'} }
    if(!phone){ return {flag:false, message:'Es necesarsio que agregues el teléfono'} }
    if(!address){ return {flag:false, message:'Es necesarsio que agregues la dirreción'} }
    if(!numExt){ return {flag:false, message:'Es necesarsio que agregues el numero exterior'} }
    if(!state){ return {flag:false, message:'Es necesarsio que agregues el estado'} }
    if(!municipality){ return {flag:false, message:'Es necesarsio que agregues el municipio'} }
    if(!area){ return {flag:false, message:'Es necesarsio que agregues la colonia'} }
    if(!postalCode){ return {flag:false, message:'Es necesarsio que agregues el cdigo postal'} }

    return {flag:true, message:''}
}