import { Fragment } from "react";

import Subscribe from "../global/Subscribe";
import Cards from "./Cards";
import Video from "../global/Video";

const Use = () => {
  return (
    <Fragment>
      <Cards />
      <Video viewText={false} />
      <Subscribe />
    </Fragment>
  );
};

export default Use;
