
const DealersInfo = ({name, address, state, phone, notes, email}) => {
    return (
        <div style={{marginBottom:30}}>
            <p style={{textTransform: 'uppercase', fontSize:20, fontWeight:600, margin:0, fontFamily:'m_regular'}}>{name}</p>
            <p style={{margin:0, fontFamily:'av_book'}}>{address}</p>
            <p style={{margin:0, fontFamily:'av_book'}}>{state}</p>
            <p style={{margin:0, fontFamily:'av_book'}}>{phone}</p>
            <p style={{margin:0, fontFamily:'av_book'}}>{email}</p>
            <p style={{margin:0, fontFamily:'av_book'}}>{notes}</p>
        </div>
    );
};

export default DealersInfo;