import { makeStyles, Container } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import Button from "components/global/Button2";

import { Background_events, colors } from "values";
import { formatDate3 } from "utils";

const useStyles = makeStyles((theme) => ({
  background_image: {
    width: "100%",
    height: 500,
    backgroundImage: `url(${Background_events})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  title: {
    color: "#fff",
    fontSize: 80,
    fontWeight: 400,
    margin: 0,
    [theme.breakpoints.down("md")]: {
      fontSize: 60,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 70,
    },
  },
  info: {
    color: "#fff",
    margin: 0,
    paddingBottom: 8,
  },
  carousel: {
    display: "flex",
    width: "100%",
    overflowY: "auto",
    padding: "30px 0",
    marginTop: -180,
    scrollbarWidth: "thin",
    scrollbarColor: "rgba(205, 205, 205, 0.5) #f0f0f0",
    "&::-webkit-scrollbar": {
      width: 6,
      background: "#f0f0f0",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#cdcdcd",
    },
  },
  contianer_image: {
    minWidth: 450,
    boxShadow: "0px 0px 12px 0px rgba(0,0,0,0.2)",
    borderRadius: 20,
    position: "relative",
    overflow: "hidden",
    margin: "0 30px",
    [theme.breakpoints.down("xs")]: {
      minWidth: 350,
    },
  },
}));

const Card = ({ data }) => {
  const classes = useStyles();
  return (
    <div className={classes.contianer_image}>
      <img alt="" src={data.picture} style={{ width: "100%", height: 320, objectFit: "cover" }} draggable="false" />
      <Container>
        <p style={{ marin: 0, color: colors.purple, fontFamily: "m_semi_bold" }}>{data.name}</p>
        <div style={{ display: "flex" }}>
          <p style={{ margin: 0, paddingRight: 10 }}>{data.flag}</p>
          <p style={{ margin: 0, color: colors.purple, fontFamily: "m_regular" }}>{data.location}</p>
        </div>
        <p style={{ color: colors.purple, fontFamily: "m_semi_bold", marginLeft: 30 }}>{formatDate3(data.date)}</p>
      </Container>
    </div>
  );
};

const Banner = ({ events }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div>
      <div style={{ height: 500, position: "relative" }}>
        <img
          alt=""
          src={Background_events}
          style={{ height: "100%", width: "100%", position: "absolute", top: 0, left: 0 }}
        />

        <div style={{ position: "relative", height: "80%", display: "flex", alignItems: "center" }}>
          <Container>
            <h2 className={classes.title} style={{ fontFamily: "walfords" }}>
              {"Eventos"}
            </h2>
            <p className={classes.info} style={{ fontFamily: "m_medium" }}>
              {"Explora nuestro calendario de eventos, dónde podrás encontrar a Upee."}
            </p>
            <p className={classes.info} style={{ fontFamily: "m_medium", marginBottom: 10 }}>
              {"¿Tienes un evento y quieres que Upee te acompañe"}
            </p>
            <Button text="Contáctanos" onAction={() => history.push("/contacto")} />
          </Container>
        </div>
      </div>
      <div className={classes.carousel}>{events && events.map((i) => <Card data={i} />)}</div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  events: state.Events.events,
});

export default connect(mapStateToProps, {})(Banner);
