import { makeStyles } from '@material-ui/core/styles';

import { Ic_change_option } from 'values';

export const useStyles = makeStyles(theme => ({
    container_table:{
        marginTop:50,
        marginBottom:50,
        padding:'0 100px',
        [theme.breakpoints.down('lg')]:{
            padding:'0 40px',
        },
        [theme.breakpoints.down('md')]:{
            padding:0
        },
    },
    table_desktop:{
        [theme.breakpoints.down('md')]:{
            display: 'none'
        },
    },
    table_mobile:{
        display: 'none',
        [theme.breakpoints.down('md')]:{
            width: '100%',
            display: 'block',
        },
    },
    container_payments:{
        display:'flex', 
        marginBottom:25,
        [theme.breakpoints.down('md')]:{
            flexWrap:'wrap',
        },
    },
    container_method_payments1:{
        // borderBottom:'1px solid #c0c0c0',
        // borderTop:'1px solid #c0c0c0',
        backgroundColor:'#f5f5f5',
        width:'36vw', height:500, display:'flex', justifyContent:'flex-end',
        [theme.breakpoints.down('md')]:{
            width:'50vw'
        },
        [theme.breakpoints.down('sm')]:{
            width:'100vw'
        },
        [theme.breakpoints.down('xs')]:{
            width:'100vw',
            height:200,
            justifyContent:'center'
        },
    },
    container_method_payments2:{
        width:'28vw', height:500, 
        border:'10px solid #fff',
        borderTop:0,
        backgroundColor:'#f5f5f5',
        display:'flex', alignItems:'center', position:'relative',
        [theme.breakpoints.down('md')]:{
            width:'calc(50vw - 5px)',
            borderRight:'none',
        },
        [theme.breakpoints.down('sm')]:{
            width:'100vw',
            border:0,
        },
        [theme.breakpoints.down('xs')]:{
            width:'100vw',
            border:'none',
            marginTop:5,
            height:470,
        },
    },
    container_method_payments3:{
        backgroundColor:'#c0c0c0', 
        borderBottom:'1px solid #c0c0c0',
        borderTop:'1px solid #c0c0c0',
        width:'36vw', height:500, 
        display:'flex', alignItems:'center',
        [theme.breakpoints.down('md')]:{
            marginTop:5,
            width:'100vw',
            height:300
        },
        [theme.breakpoints.down('sm')]:{
            marginTop:5,
            width:'100vw',
            height:300
        },
    },
    options_payments_text:{
        fontSize:32, padding:'10px 0', margin:0, userSelect:'none', fontWeight:700,
        [theme.breakpoints.down('xs')]:{
            fontSize:20,
        },
    },
    title_payments:{
        color:'#fff', fontWeight:700, fontSize:55,
        [theme.breakpoints.down('xs')]:{ fontSize:40}
    },
    title_payment:{
        maxWidth:300, fontWeight:700, fontSize:45, paddingTop:100, marginRight:40, textTransform:'uppercase',
        [theme.breakpoints.down('xs')]:{
            paddingTop:0, 
            margin:0
        },
    },
    input:{
        width:'calc(100% - 10px)',
        fontSize:16,
        backgroundColor:'#f7f7f7',
        border:'none',
        borderRadius:5,
        paddingLeft:10,
        marginTop:10,
        marginBottom:10,
        height:50,
    },
    container_payments2:{
        display:'flex', 
        marginBottom:25,
        [theme.breakpoints.down('md')]:{
            flexWrap:'wrap',
        },
    },
    container_method_payments4:{
        backgroundColor:'#c0c0c0', width:'28vw', height:'auto', 
        borderRight:'10px solid #fff',
        display:'flex', alignItems:'center', 
        justifyContent:'flex-end',
        [theme.breakpoints.down('sm')]:{
            marginTop:5,
            width:'100vw',
            height:300,
            borderRight:0,
        },
    },
    container_method_payments6:{
        // borderBottom:'1px solid #c0c0c0',
        // borderTop:'1px solid #c0c0c0',
        backgroundColor:'#f5f5f5',
        width:'80%', minHeight:500, 
        display:'flex', alignItems:'center', 
        [theme.breakpoints.down('sm')]:{
            marginTop:5,
            width:'100vw',
            height:'100%',
            justifyContent:'center',
        },
    },
    content_method_form:{
        width:'70%',
        [theme.breakpoints.down('sm')]:{
            width:'100%',
        },
    },
    margin_input:{
        marginLeft:10,
        [theme.breakpoints.down('xs')]:{ marginLeft:0 },
    },
    input_select:{
        width:'100%', fontSize:16,
        background: `url(${Ic_change_option}) 97.5% / 10px no-repeat #fff`,
        appearance:'none', border:'none',
        borderRadius:5, paddingLeft:8,
        marginTop:'10px 0', height:50,
    },
    container_method_payments7:{
        width:'80%', minHeight:500, 
        display:'flex', alignItems:'center', 
        justifyContent:'flex-end', 
        // borderBottom:'1px solid #c0c0c0',
        // borderTop:'1px solid #c0c0c0',
        borderRight:'10px solid #fff',
        backgroundColor:'#f5f5f5',
        [theme.breakpoints.down('md')]:{
            marginTop:5,
            height:'auto',
            justifyContent:'flex-start', 
            width:'100%',
        },
        [theme.breakpoints.down('sm')]:{
            borderRight:0
        },
    },
    content_method_form2:{
        width:'70%',
        [theme.breakpoints.down('md')]:{
            width:'100%',
        },
    },
    container_method_payments8:{
        backgroundColor:'#c0c0c0', 
        width:'28vw', height:'auto', 
        display:'flex', alignItems:'center', 
        justifyContent:'flex-end',
        [theme.breakpoints.down('md')]:{
            marginTop:5,
            width:'100vw',
            height:300,
        },
    },
}));