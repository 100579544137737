import { Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Form from "./Forms";

import { colors } from "values";

// Styles
export const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    alignItems: "center",
    height: 400,
    marginTop: 100,
    background: colors.back,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 30,
    },
  },
  title: {
    fontSize: 65,
    color: "#fff",
    margin: 0,
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
      fontSize: 50,
    },
  },
}));

const Banner = () => {
  const classes = useStyles();
  return (
    <div style={{ padding: "70px 0" }}>
      <Container maxWidth="lg">
        <Grid container>
          <Grid item xs={12} sm={12} md={6}>
            <div className={classes.card}>
              <Container>
                <div style={{ width: "100%", marginTop: -50 }}>
                  <h2 className={classes.title} style={{ fontFamily: "walfords" }}>
                    {"Platiquemos..."}
                  </h2>
                  <p style={{ margin: 0, color: "#fff", marginTop: 23, fontSize: 20, fontFamily: "m_regular" }}>
                    {"Con gusto responderemos a tus preguntas y te ayudaremos a encontrar el producto que necesitas."}
                  </p>
                </div>
              </Container>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div
              style={{
                width: "100%",
                background: "#fff",
                position: "relative",
                border: `1px solid ${colors.light_gray}`,
              }}
            >
              <Form />
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Banner;
