import { Fragment, useState } from "react";
import { Drawer } from "@material-ui/core";
import { connect } from "react-redux";

// Values
import { Ic_menu, Ic_cart, Logo, colors } from "values";

// Styles
import { useStyles } from "style";

const Title = ({ path, newPath, text, change }) => {
  return (
    <div style={{ width: 200, backgroundColor: path === newPath ? colors.primary : "#fff" }}>
      <p
        onClick={() => change(newPath)}
        style={{
          marginLeft: 20,
          cursor: "pointer",
          color: path === newPath ? "#fff" : "#000",
          fontSize: 20,
          userSelect: "none",
        }}
      >
        {text}
      </p>
    </div>
  );
};

const MenuDrawer = ({ store, menuChange, path, login }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const change = (path) => {
    menuChange(path);
    setOpen(false);
  };
  const openLoginModal = () => {
    login();
    setOpen(false);
  };
  return (
    <Fragment>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <img alt="" src={Ic_menu} style={{ cursor: "pointer", width: 100 }} onClick={() => setOpen(true)} />

        <img alt="Upee logo" src={Logo} style={{ maxWidth: 100 }} />

        <div style={{ display: "flex", position: "relative", left: -20 }}>
          <div className={classes.button_card} onClick={() => change("carrito")}>
            <img alt="" loading="lazy" draggable="false" src={Ic_cart} style={{ width: 25 }} />
          </div>
          {store.length !== 0 && (
            <div
              style={{
                background: "#2ed37c",
                position: "absolute",
                height: 28,
                width: 28,
                color: "#fff",
                fontSize: 14,
                fontWeight: 600,
                bottom: -10,
                right: 35,
                borderRadius: 50,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <label>{store.length}</label>
            </div>
          )}
        </div>
      </div>
      <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
        <Title path={path} newPath="" text="Home" change={change} />
        <Title path={path} newPath="que-es" text="Qué es" change={change} />
        <Title path={path} newPath="compra" text="Compra" change={change} />
        <Title path={path} newPath="distribuidoras" text="Distribuidoras" change={change} />
        <Title path={path} newPath="canales" text="Canales Upee" change={change} />
        <Title path={path} newPath="eventos" text="Eventos" change={change} />
        <Title path={path} newPath="instructivo" text="Instructivo" change={change} />
        <Title path={path} newPath="contacto" text="Contacto" change={change} />

        <div style={{ width: 200 }}>
          <p
            onClick={() => openLoginModal()}
            style={{ marginLeft: 20, cursor: "pointer", fontSize: 20, userSelect: "none" }}
          >
            {"Login"}
          </p>
        </div>
      </Drawer>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  store: state.Store.data,
});

export default connect(mapStateToProps, {})(MenuDrawer);
