import { Fragment, useState } from 'react';
import { Container, Modal, Grid, makeStyles, TablePagination } from '@material-ui/core';
import { Rating, Pagination } from '@material-ui/lab';
import { connect } from 'react-redux';
import uniqid from 'uniqid';
import ReactLoading from "react-loading";
import { toast } from 'react-toastify';

import { getReviews } from 'redux/actions/Reviews';

import Input from 'components/global/Input';
import Login from 'components/global/Login';

import { getData, postData } from 'utils/reviews';
import { formatDate3 as formatDate } from 'utils';

import { colors } from 'values';

const useStyles = makeStyles(theme => ({
    add_button: {
        background: colors.primary, border: 'none',
        color: '#fff',
        width: 40, height: 25,
        borderRadius: 10, fontSize: 20,
        cursor: 'pointer',
        position: 'relative',
        '&:active': {
            transform: 'scale(1.1)',
        },
    },
    button: {
        background: colors.front, border: 'none',
        color: '#fff',
        width: 150, height: 40,
        borderRadius: 10, fontSize: 20,
        cursor: 'pointer',
        position: 'relative',
        '&:active': {
            transform: 'scale(1.1)',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    container_footer: {
        display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 30,
        [theme.breakpoints.down('xs')]: {
            flexWrap: 'wrap',
        },
    },
    container_rate: {
        paddingTop: 50, display: 'flex', justifyContent: 'flex-end',
        [theme.breakpoints.down('xs')]: {
            padding: '15px 0',
            justifyContent: 'flex-start'
        },
    },
    title_modal: {
        fontSize: 50, margin: 0, color: colors.purple,
        [theme.breakpoints.down('xs')]: {
            fontSize: 35
        },
    }
}));

const AddButton = ({ onAction }) => {
    const classes = useStyles()
    return (
        <button className={classes.add_button} onClick={() => onAction(true)}>
            <span style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>{'+'}</span>
        </button>
    )
}

const Button = ({ onAction }) => {
    const classes = useStyles()
    return (
        <button className={classes.button} onClick={onAction}>
            {'Enviar'}
        </button>
    )
}

const Review = ({ image, title, review, starts, date, name, verified }) => {
    return (
        <Fragment>
            <div style={{ marginTop: 30 }}>
                <Container>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ width: 70, height: 70, background: '#c0c0c0', borderRadius: 100 }}>
                            <img alt='' src={image} style={{ width: 70, borderRadius: 100 }} />
                        </div>
                        <div style={{ marginLeft: 20 }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <p style={{ margin: 0, paddingLeft: 5, color: colors.purple, fontFamily: 'm_medium' }}>{name}</p>
                                {
                                    verified && <label style={{ color: '#c0c0c0', marginLeft: 10, fontFamily: 'm_regular' }}>{'Verified Buyer'}</label>
                                }
                            </div>
                            <Rating value={starts} style={{ color: colors.red }} readOnly />
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                        <p style={{ margin: 0, fontWeight: 600, color: colors.purple, fontFamily: 'm_medium' }}>{title}</p>
                        <p style={{ margin: 0, fontWeight: 600, color: colors.purple, fontFamily: 'm_medium' }}>{formatDate(date)}</p>
                    </div>
                    <p style={{ margin: 0, color: colors.purple, fontFamily: 'm_regular' }}>{review}</p>
                </Container>
            </div>
            <div style={{ borderBottom: `1px solid #c0c0c0`, margin: 30 }} />
        </Fragment>
    )
}

const Reviews = ({ id, token, data, records, page, getReviews }) => {
    const classes = useStyles()

    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    const [score, setScore] = useState(5)
    const [name, setName] = useState('')
    const [title, setTitle] = useState('')
    const [message, setMessage] = useState('')

    const cleanData = () => {
        setScore(5)
        setName('')
        setTitle('')
        setMessage('')
    }

    const addData = async () => {
        const res = await postData(name, title, id, score, message, token)
        if (res) {
            setOpen(false)
            setPage()
            cleanData()
            toast.success('Gracias por compartir tu experiencia')
        } else {
            toast.warn('Al parecer sucedio un error, vuelve a intentarlo.')
        }
    }

    const closeModal = () => {
        setOpen(false)
        cleanData()
    }

    const setPage = async (event, newPage = page) => {
        setLoading(true)
        console.log('newPage: ', newPage);
        const extra = `?page=${newPage}`
        const res = await getData(token, extra)
        try {
            const newData = res.data
            getReviews(newData.data, newData.records, newData.page)
        } catch (error) { }
        setLoading(false)
    }
    return (
        <div style={{ margin: '70px 0' }}>
            {
                token ?
                    <Modal open={open} onClose={closeModal} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Container maxWidth="md">
                            <div style={{ display: 'flex', background: '#fff' }}>
                                <Container>
                                    <div style={{ textAlign: 'center', marginTop: 30, marginBottom: 20 }}>
                                        <p className={classes.title_modal} style={{ fontFamily: 'walfords' }}>{'Experiencia Upee'}</p>
                                    </div>
                                    <Grid container alignItems='center' >
                                        <Grid item xs={12} sm={6}>
                                            <Input title='Nombre' placeholder='Tu nombre' value={name} setValue={setName} />
                                        </Grid>
                                        <Grid item xs={12} sm={6}></Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Input title='Título' placeholder='Tu título' value={title} setValue={setTitle} />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <div className={classes.container_rate}>
                                                <Rating value={score} onChange={(e, newValue) => setScore(newValue)} style={{ color: colors.red }} />
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Input value={message} setValue={setMessage}
                                        title='Reseña'
                                        placeholder='Cuentanos sobre tu experienciia con Upee'
                                        type='area' areaH={100} />
                                    <div className={classes.container_footer}>
                                        <p style={{ margin: 0, padding: '5px 0' }}>
                                            {'Al hacer clic en “Enviar”, acepto la '}
                                            <span style={{ fontWeight: 600 }}>{'Política de privacidad'}</span>
                                            {' de Upee.'}
                                        </p>
                                        <Button onAction={addData} />
                                    </div>
                                </Container>
                            </div>
                        </Container>
                    </Modal>
                    :
                    <Login open={open} setOpen={setOpen} redirect={false} />
            }

            <Container>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Rating value={5} style={{ color: colors.red }} readOnly />
                        <p style={{ color: colors.primary, borderBottom: `1px solid ${colors.primary}`, marginLeft: 10 }}>{`${records} reviews`}</p>
                    </div>
                    <AddButton onAction={setOpen} />
                </div>
                {/* <Rating value={score} onChange={(e, newValue) => setScore(newValue)} style={{ color: colors.red }} readOnly /> */}
            </Container>
            <div style={{ borderBottom: `2px solid ${colors.red}`, margin: 10 }} />

            {
                !loading ?
                    data &&
                    data.map(i => (
                        <Review key={uniqid()}
                            image={i.picture} title={i.title}
                            review={i.review} starts={i.starts}
                            date={i.date} name={i.name} verified={i.verified} />
                    ))
                    :
                    <div style={{ position: 'relative', margin: '50px 0' }}>
                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                            <ReactLoading type={"bubbles"} color={"white"} style={{ fill: colors.red, height: 50, width: 50 }} />
                        </div>
                    </div>
            }

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Pagination count={Math.ceil(records / 5)} page={page} onChange={setPage} />
            </div>

        </div>
    );
};

const mapStateToProps = state => ({
    id: state.Panel.data.id,
    token: state.Panel.token,
    data: state.Reviews.data,
    records: state.Reviews.records,
    page: state.Reviews.page
})

export default connect(mapStateToProps, { getReviews })(Reviews)

