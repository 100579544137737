import { get, urlApi, urlPath } from '../api';

const url = urlApi + urlPath.shipment

export const getData = async(token='', extra='') =>{
    return await get(url, token, extra)
}

export const checkShipmet = (rol, store, shipments, shippingType) =>{
    let totalProducts = 0
    let shipmentList = {}
    let shipment = 0
    let flag = false

    store.forEach(i => {
        totalProducts += i.total
    });

    shipments.forEach(i=>{
        if(rol==='Distribuitor'){
            if(i.type==='Distribuitor'){
                shipmentList = i
            }
        } else{
            if(i.type==='Others'){
                shipmentList = i
            }
        }
    })

    shipmentList.limit.forEach(i=>{
        if(totalProducts >= i.min && totalProducts <= i.max){
            if(i.speed===shippingType){
                flag = true
                shipment = i.shipment
            }
        }
    })

    return {shipment, sell:flag}
}